
$sides: (
    all: all,
    top: top,
    right: right,
    bottom: bottom,
    left: left
);

$available-sizes: 5, 10, 15, 20, 25, 30, 35, 40;


@mixin margin {
    @each $size in $available-sizes {
        @each $key, $side in $sides {
            .margin-#{$side}-#{$size} {
                @if $side == 'all' {
                    margin: #{$size}px;
                } @else {
                    margin-#{$side}: #{$size}px;
                }
            }
        }
    }
}

@mixin padding {
    @each $size in $available-sizes {
        @each $key, $side in $sides {
            .padding-#{$side}-#{$size} {
                @if $side == 'all' {
                    padding: #{$size}px;
                } @else {
                    padding-#{$side}: #{$size}px;
                }
            }
        }
    }
}
