.nav-action-buttons-container,
.user-small-panel {
  float: right;
  display: block;
  min-width: 170px;
  margin-top: 18px;
}
.nav-action-buttons-container {
    &.not-authenticated {
        @media #{$screen-lt-md} {
            display: none;
            position: relative;
            top: 58px;
            min-width: 120px;
            margin-top: 0;
            padding: 10px 4px;
            background-color: $white;
            border-radius: 0 0 4px 4px;
            &.visible-action-buttons {
                display: block;
            }
        }
    }
  .button {
    display: inline-block;
    margin: 4px;
    padding: 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
    outline: none;
    cursor: pointer;
      @media #{$screen-lt-md} {
          font-size: 10px;
          padding: 6px 8px;
      }
    &.signin-button {
      border: 1px solid $light-blue !important;
      color: $light-blue !important;
    }
    &.signup-button {
      border: 1px solid $scared-light-blue !important;
      background-color: $scared-light-blue !important;
      color: $light-blue !important;
      font-weight: bold !important;
    }
  }
}
.marketplace-header-logo {
  width: 90px;
  margin: 6px 16px;
  float: left;
  cursor: pointer;
  outline: none;
    @media #{$screen-lt-md} {
        width: 36px;
        margin: 11px auto 0;
        float: none;
    }
  img {
    width: 100%;
    height: auto;
  }
}
.marketplace-header-items-container {
  display: inline-block;
  margin: 12px 10px 0;
  .marketplace-menu-item {
    display: inline-block;
    margin: 10px 16px;
    padding: 6px 11px;
    max-width: 150px;
  }
  .marketplace-menu-item-inner {
    display: flex;
    p {
      display: inline;
      margin: 0;
      color: $medium-gray;
      font-size: 14px;
      line-height: 14px;
      &:hover {
          color: $light-blue;
      }
    }
  }
  .wrapped-menu-item {
    &.menu-item-dropdown {
      margin: 0;
      mat-form-field {
        max-width: 120px;
      }
    }
    .dropdown-arrow-wrapper {
      width: 10px;
      margin-left: 6px;
      .dropdown-arrow {
        top: 7px;
        &:before,
        &:after {
          width: 6px;
          height: 1px;
        }
        &:before {
           left: 4px;
         }
      }
    }
  }
}

.dropdown-arrow-wrapper {
    &.signin-signup-dropdown {
        display: none;
        @media #{$screen-lt-md} {
            display: block;
            position: absolute;
            right: 10px;
            float: right;
            margin-top: 20px;
        }
    }
}

.landing-top-products-header {
  h4 {
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    color: $light-blue;
  }
}

.user-small-panel {
  margin-top: 0;
    &.marketplace-small-panel {
        @media #{$screen-lt-md} {
            margin-top: 7px;
            .nav-action-buttons-container {
                .signin-button {
                    padding: 12px;
                    font-size: 14px;
                }
            }
            .username-wrapper.small-panel {
                display: none;
            }
        }
        @media #{$screen-lt-sm} {
            margin-top: 11px;
            .nav-action-buttons-container {
                .signin-button {
                    padding: 8px 6px;
                    font-size: 12px;
                }
            }
        }
    }
  a {
    display: inline-block;
  }
}
.search-main-container {
  position: relative;
  min-height: 400px;
  background: url($marketplace-upper-background) no-repeat;
  background-size: cover;
  background-position: center center;
  .search {
    min-height: 200px;
      .mat-form-field {
          border: none;
      }
    }
}
.search-black-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  opacity: .4;
}
.searching-wrapper {
  position: relative;
}
.search-field-wrapper {
  position: relative;
  border-bottom: 3px solid $light-blue;
  mat-form-field {
    @media #{$screen-lt-md} {
      margin-bottom: 0;
    }
  }
}
.marketplace-search-button {
    display: inline-block;
  position: absolute;
  right: 0;
    padding: 7px 16px 0 0;
  border-radius: 8px;
  background-color: transparent;
    color: $white;
    cursor: pointer;
  i.material-icons.search-icon {
    line-height: 24px;
  }
}
i.material-icons {
  &.search-icon {
    line-height: 34px;
  }
}
/*.name-wrapper {
  overflow: hidden;
  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}*/
.statistic-item-container {
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 8px;
  padding: 20px 40px;
  @media #{$screen-lt-md} {
    padding: 20px 10px;
    text-align: center;
  }
  .number {
    font-weight: bold;
    color: $light-blue;
    font-size: 30px;
    @media #{$screen-lt-md} {
      font-size: 36px;
      text-align: left;
    }
    sup {
      font-size: .5em;
    }
  }
  .text {
    color: white;
    text-transform: lowercase;
    font-weight: bold;
    @media #{$screen-lt-md} {
      font-size: 12px;
      text-align: left;
    }
  }
}
.statistic-item-inner {
  display: inline-block;
}
.product-container {
  .product {
    @media #{$screen-xs} {
      max-width: 300px !important;
    }
  }
  .info-container {
    display: block !important;
  }
  .image-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }
}
.product-info-currency-buttons {
  .currency-button {
      padding-right: 14px;
      padding-left: 14px;
      font-weight: bold;
    &.bitcoin-button:hover,
    &.ethereum-button:hover {
        opacity: .6;
    }
  }
}
header.header-navigation {
  @media #{$screen-lt-md} {
    .marketplace-header-items-container {
      &.navigation-menu-tablet-mode {
        display: block;
        position: absolute;
        background-color: $white;
        left: -192px;
        top: 58px;
        margin: 0;
        max-width: 182px;
        transition: left .2s;
        .marketplace-menu-item {
          display: block;
        }
      }
      &.visible-navigation {
          left: 0;
      }
    }
  }
}
