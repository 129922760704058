.settings-page {
    .pre-title {
        color: $light-blue !important;
        font-size: 17px;
        font-weight: bold;
    }

    app-upload {
        .upload-button {
            padding: 9px 20px;
        }
    }
}

.grey-line {
    height: 1px;
    padding: 10px 0;
    border-top: 2px solid #f9f9f9;
}

.setting-profile {
    color: $dark-blue;

    label {
        font-size: 13px;
        color: $light-gray;
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }

    margin-bottom: 15px;
}

.support-info {
    margin-bottom: 20px;
}

.subscription-button {
    &:disabled {
        opacity: 0.3;
    }

    @media #{$screen-lt-sm} {
        font-size: 9px !important;
    }
}

.read-contract-text {
    .link {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.avatar-image-container {
    label {
        font-weight: bold;
    }
}

.settings-info-page {
    margin-bottom: 45px!important;
}


.success-txt {
    color: #35d8b9;
    text-align: center;
}

.client-vat-makes-sense {
    margin-left: 40px;
}
