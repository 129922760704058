.your-market-table {
    &.admin-watch-products {
        .product-name {
            color: $light-blue;
        }
    }
}
.action-buttons-wrapper {
    padding-right: 5px !important;
    padding-left: 5px !important;
    button {
        width: 100%;
        padding: 16px 0;
    }
}
.product-list-image {
    width: 100px;
    height: auto;
}

.catalog-table-wrapper {
  width: 100%;
  padding: 20px;
  background-color: white;
}
.dashboard-content {
  margin-left: 267px;
  @media #{$screen-lt-md} {
    margin-left: 0;
  }
}

.users-catalog-row {
  position: relative;
}

mat-expansion-panel-header.orders-panel {
  padding: 0;
  span {
    font-weight: bold;
    @media only screen and (max-width: 559px) {
      font-weight: normal;
    }
  }
  .mat-expansion-indicator {
    position: absolute;
    left: 2%;
    top:12px;
  }
  .mat-expansion-indicator::after {
    transform: rotate(-45deg);
  }
  &.mat-expanded {
    .mat-expansion-indicator::after {
      transform: rotate(225deg) !important;
    }
  }
}

.send-activation-mt {
  margin-top: 80px !important;
}

//New CSS start

.orders{
    .column-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .column{
            width: 18%;
            flex-direction: column !important;
            margin: 0 5px;
            @media #{$screen-lt-sm} {
                width: 45%;
            }
            @media #{$screen-lte-349px} {
                width: 100%;
            }

            .ng-star-inserted {
               max-width: 100%;

                mat-form-field, mat-form-field.mat-form-field.common-datepicker{
                   // background-color: #ededed !important;
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}

.selection-tab-header{
    color: #3b6593;
}
