/* Hopscotch Tour */

div.hopscotch-bubble {
  border: 1px solid white;
  border-radius: 2px;
  background: #36987e;
  color: #FFFFFF !important;
  font-family: "Roboto",Helvetica Neue,sans-serif !important;
  .hopscotch-title,
  .hopscotch-content,
  .hopscotch-nav-button {
    font-family: "Roboto",Helvetica Neue,sans-serif !important;
  }
  .hopscotch-bubble-number {
    box-sizing: border-box;
    height: 32px;
    width: 32px;
    padding: 0;
    border: 1px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    background: transparent;
    font-weight: 500;
    line-height: 32px;
  }
  .hopscotch-title {
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: .1px;
  }
  .hopscotch-content {
    margin: -5px 0 16px;
  }
  .hopscotch-bubble-close {
    /*background: url($hopscotch-bubble-close);*/
    background-size: 8px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:active {
      outline: 0;
    }
  }
  .hopscotch-bubble-arrow-container {
    /* up arrow  */
    &.up {
      top: -17px;
      .hopscotch-bubble-arrow {
        border-bottom: $bubble-arrow-border;
        top: -16px;
      }
      .hopscotch-bubble-arrow-border {
        border-bottom: $bubble-arrow-border;
      }
    }
    /* right arrow */
    &.right {
      right: -34px;
      .hopscotch-bubble-arrow {
        border-left: $bubble-arrow-border;
        left: -1px;
      }
      .hopscotch-bubble-arrow-border {
        border-left: $bubble-arrow-border;
      }
    }
    /* bottom arrow */
    &.down {
      bottom: -34px;
      .hopscotch-bubble-arrow {
        border-top: $bubble-arrow-border;
        top: -18px;
      }
      .hopscotch-bubble-arrow-border {
        border-top: $bubble-arrow-border;
      }
    }
    /* left Arrow */
    &.left {
      left: -17px;
      .hopscotch-bubble-arrow {
        left: 1px;
        border-right: $bubble-arrow-border;
      }
      .hopscotch-bubble-arrow-border {
        border-right: $bubble-arrow-border;
      }
    }
  }
  .hopscotch-nav-button {
    border-radius: 2px;
    font-weight: normal;
    text-shadow: none !important;
    padding: 0 18px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    background: transparent !important;
    border: 1px solid #ffffff !important;
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
    &.prev,
    &.next,
    &.prev:hover,
    &.next:hover {
      transition: all .2s ease-in-out;
      color: #ffffff !important;
    }
    &.prev:hover,
    &.next:hover {
      background-color: #29725f !important;
    }
  }
}
