$light-blue: #37bced;
$medium-blue: #1d93c1;
$white-blue: #42c5fa;
$inkBlue: #1c92be;
$night-blue: #3b6593;
$dark-blue: #254974;
$blue-gray: #8c95a2;
$medium-gray: #a5a5a5;
$dark-gray: #3e3e3e;
$input-gray: #464641;
$light-gray: #cccccc;
$background-gray: #fbfbfb;
$date-btn-gray: #ededed;
$pacific-gray: #d5d5d5;
$scared-light-blue: #ccf4fe;
$dark-green: #35d8b9;
$sallad-green: #7ac9b2;
$normal-green: #59b734;
$checked-green: #36987e;
$scared-red: #ff8181;
$error-red: #f44336;
$white: #ffffff;
$black: #000000;
$main-back-gray: #f7f7f7;
$border-label-gray: #e0e0e0;
$slightly-gray: #f6f6f6;
$table-scroll-thumb-gray: #cacaca;
$perfect-orange: #ffa800;
$june-apricot: #eac51b;
$carbon-black: #404040;
$currencies-black: #1f2532;
$vote-icon-back: #eaedff;
/*#5f5f5f - gray*/

/* Image paths */

$marketplace-upper-background: '../../images/marketplace.jpg';
$signin-background: '../../images/signin-background.jpg';
$vote-background: '../../images/marketplace.jpg';
$no-image-background: '../../images/no_images/image.png';

