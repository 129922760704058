.wallet-container {
  position: relative;
  min-height: 312px;
  margin: 26px 0;
  padding: 0 37px 20px;
}
.my-wallets-intro-video-wrapper {
    .my-wallets-intro-video {
        width: auto;
        height: 500px;
        @media #{$screen-md} {
            height: 400px;
        }
        @media #{$screen-sm} {
            height: 250px;
        }
        @media #{$screen-lt-sm} {
            height: 200px;
        }
    }
}
.currency-container {
  position: absolute;
  top: 95px;
  left: -10px;
  width: 108px;
  height: 136px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: $white;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .currency-logo-wrapper {
    margin: 30px auto 0;
    text-align: center;
  }
  .crypto-currency-logo {
    position: relative;
    &:before {
      color: $light-blue;
      font-size: 74px;
    }
  }
  .currency-wrapper-pleat {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 5px solid $light-gray;
    border-left: 10px solid transparent;
  }
}

.wallet-header-container {
  position: relative;
  padding-left: 100px;
}

.wallet-name-container {
  float: right;
  padding: 15px 10px 15px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: $slightly-gray;
  h4 {
    margin-bottom: 0;
    padding-bottom: 0;
    color: $light-blue;
    font-size: 20px;
  }
  .primary-wallet{
    font-size: 22px;
    color: #eac51b;
    padding: 0 10px;
    cursor: pointer;
    &.full{
      color: #eac51b;
    }
  }
}
.wallet-active-balance-container {
  padding-top: 20px;
  padding-bottom: 60px;
  h4 {
    margin-bottom: 0;
    padding-bottom: 0;
    color: $night-blue;
    font-size: 20px;
  }
  .edit-wallet-name{
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 18px;
  }
}
.wallet-monthly-balance-container {
  position: relative;
  height: 190px;
  margin-left: 100px;
  background-color: $slightly-gray;
  border-radius: 8px;
  @media #{$screen-md} {
    height: 210px;
  }
}
.wallet-chart-wrapper {
  position: relative;
  height: 190px;
  @media #{$screen-md} {
    height: 210px;
  }
}
.wallet-chart-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .chartjs-render-monitor {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
.wallet-balance-wrapper {
  font-weight: bold;
  text-align: right;
  .wallet-balance {
    color: $inkBlue;
    font-size: 40px;
    @media #{$screen-btw-960px-1625px} {
      font-size: 23px;
    }
    @media #{$screen-lt-md} {
      font-size: 23px;
    }
  }
  .wallet-balance-changing {
    &.balance-increase {
      color: $dark-green;
    }
    &.balance-decrease {
      color: $scared-red;
    }
    font-size: 25px;
    @media #{$screen-btw-960px-1625px} {
      font-size: 20px;
    }
    @media #{$screen-lt-md} {
      font-size: 20px;
    }
  }
  p {
    color: $medium-gray;
    font-size: 20px;
    @media #{$screen-btw-960px-1625px} {
      font-size: 13px;
    }
    @media #{$screen-lt-md} {
      font-size: 13px;
    }
  }
}
.wallet-actions-container {
  margin-left: 100px;
  padding-top: 20px;
  @media #{$screen-btw-1280px-1625px} {
    margin-left: 0;
  }
  @media #{$screen-lt-sm} {
    margin-left: 0;
  }
  .wallet-action {
    margin-right: 6px;
    font-size: 18px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
    @media #{$screen-xs} {
      font-size: 13px;
    }
  }
  .wallet-action-icon {
    font-size: 18px;
    @media #{$screen-xs} {
      font-size: 15px;
    }
    &.icon-edit,
    &.icon-delete {
      font-size: 16px;
      @media #{$screen-xs} {
        font-size: 13px;
      }
    }
  }
  .wallet-action-item {
    &.receive-item,
    &.send-item {
      color: $light-blue;
    }
    &.edit-item {
      color: $sallad-green;
    }
    &.delete-item {
      color: $scared-red;
    }
  }
}

.add-wallet-wrapper {
  text-align: center;
  width: 100%;
}

.mat-dialog-title{
  border-bottom: 1px solid #f7f7f7;
}

.wallet-title{
  color: $light-blue;
}

.wallet-fileds{
  label{
    color: $medium-gray;
  }
  mat-form-field{
    input, select{
      color: $dark-blue !important;
    }
  }
}

.wallet-buttons{
  &.subscribe-buttons {
      margin: 0 22px;
  }
}

.wallet-icon-button{
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50px;
  /* border: 1px solid #000000; */
  font-size: 11px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -13px;
  right: -15px;
  line-height: 24px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  color: $light-blue;
}

.mat-dialog-container {
  overflow: inherit !important;
}

.help-text-wrapper{
  p{
    color: $light-gray !important;
  }
}

.wallets-table-tfoot {
  padding-top: 28px;
  overflow: hidden;
  width: 100%;
  .mat-paginator-page-size{
    color: $dark-blue;
  }
  .mat-paginator-range-actions{
    .mat-paginator-navigation-previous{
        margin-right: 5px !important;
    }
  }
  button{
    background-color: $sallad-green !important;
    border-radius: 8px;
    cursor: pointer;
    span{
      div{
        border-color: #ffffff !important;
      }
    }
  }
}

.incoming-transaction{
  width: 10px;
  height: 10px;
  top: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: $sallad-green;
}

.outgoing-transaction{
  width: 10px;
  height: 10px;
  top: 15px;
  border-radius: 15px;
  position: absolute;
  background-color: $scared-red;
}
