app-payment-layout {
    min-height: 100%;
}
.payment-page {
    margin-top: 60px;
    .BTC-payment-button{
        background-color: #ffffff !important;
        border: 2px solid #ffa800 !important;
        color: #ffa800 !important;
        margin-bottom: 10px !important;
        &:hover{
            background-color: #ffa800 !important;
            color: #ffffff !important;
        }
        &:hover i{
            color: #ffffff !important;
        }
        i {
            color: #ffa800 !important;
        }
    }
    .ETH-payment-button{
        border: 2px solid #404040 !important;
        margin-bottom: 10px !important;
    }

    .qrcode-info{
        margin-top: 20px;
        .qr-code{
            text-align: center;
        }
    }

    .payment_info_block{
        margin-top: 10px;
        .total_text{
            color: $light-gray;
        }
        .total_amount{
            font-weight: bold;
            color: #ffa800;
            font-size: 25px;
            margin-bottom: 15px;
        }
        .addr_text{
            color: $light-gray;
        }
        .address{
            margin-top: 15px;
            margin-bottom: 30px;
            cursor: pointer;
            @media #{$screen-lt-md} {
                text-align: center;
            }
            span:not(.copy-text){
                padding-left: 10px;
                border-radius: 3px;
                background-color: #e1e1e1;
                color: $dark-gray;
                word-wrap: break-word;
                @media #{$screen-lt-md} {
                    width: 100%;
                    display: block;
                    text-align: center;
                }
            }
            .copy-wrapper {
                @media #{$screen-lt-md} {
                    display: flex;
                    flex: 1 1 100%;
                    max-width: 100%;
                }
            }
            .copy-payment-address {
                span {
                    background-color: $white;
                }
            }
            color: $light-blue;
        }
        .timetable{
            color: #ffa800;
            float: left;
            line-height: 13px;
            @media #{$screen-lt-md} {
                text-align: center;
            }
            i{
                margin-right: 10px;
                float: left;
            }
        }
    }

    .copyright{
        color: $light-gray;
        font-size: 12px;
        text-align: center;
        width: 100%;
        margin-bottom: 60px;
    }
}

.payment-bg {
  padding-top: 117px;
  margin-top: -102px;
  width: 100%;
  min-height: 100vh;
  background: #9ecff7;
}
.payment-container-wrapper {
  position: relative;
  max-width: 1140px;
  margin: 13px auto 0;
  padding-bottom: 20px;
  @media #{$screen-lte-1200px} {
    width: 95%;
  }
}
.payment-container {
  position: relative;
  padding: 60px 95px 75px 98px;
  background: #fff;
  line-height: 1.2;
  color: #000;
  font-size: 16px;
  @include box-shadow(0 0 10px 0 rgba(0,0,0,.26));
  @media #{$screen-lte-1200px} {
    padding: 20px 40px 70px;
  }
  @media #{$screen-lte-850px} {
    padding: 20px 4.5% 70px;
  }
  @media #{$screen-lte-767px} {
    padding: 55px 4.5% 30px;
  }
  *{
    @include box-sizing(border-box);
  }
  p, h4 {
    margin: 0;
    padding: 0;
  }
  .payment-logo {
    display: block;
    float: right;
    width: 225px;
    height: 58px;
    margin-top: 5px;
    /*background: url($payment-logo) no-repeat center;*/
    background-size: contain;
    @media #{$screen-lte-767px} {
      float: none;
      width: 200px;
      height: 50px;
      margin: 0 auto;
    }
  }
  .payment-heading {
    width: calc(100% - 250px);
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    @media #{$screen-lte-767px} {
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
    h4 {
      padding: 10px 0 5px;
      font-weight: 700;
      font-size: 40px;
      @media #{$screen-lte-767px} {
        padding: 20px 0 0;
        font-size: 30px;
      }
    }
  }
  .payment-tabs {
    padding-top: 80px;
    @media #{$screen-lte-767px} {
      padding-top: 30px;
    }
    .payment-tabs-content {
      margin-top: -1px;
      padding: 26px 27px 26px 20px;
      border: 1px solid #cad3d9;
      background-color: #f5f5f5;
      @media #{$screen-lte-767px} {
        padding: 20px 15px 20px;
      }
      .loading-spinner {
        margin: 0 auto;
      }
      .status-text-wrapper {
        display: table;
        width: 100%;
        & + .payment-separator {
          height: 20px;
        }
        .status-texts {
          display: table-cell;
          width: 100%;
          height: 155px;
          text-align: center;
          vertical-align: middle;
          p {
            font-size: 20px;
            font-weight: 500;
          }
          .status-heading {
            padding-bottom: 20px;
            font-size: 40px;
            font-weight: 400;
            @media #{$screen-lte-767px} {
              font-size: 25px;
            }
          }
        }
      }
      .payment-tab {
        .order-title {
          float: left;
          width: 42.7%;
          @media #{$screen-lte-767px} {
            float: none;
            width: 100%;
          }
          .order-title-text {
            font-size: 24px;
            font-weight: 300;
            @media #{$screen-lte-767px} {
              font-size: 20px;
            }
          }
          .order-title-date {
            padding-top: 2px;
            color: #6f7a83;
            font-size: 14px;
            font-weight: 300;
            @media #{$screen-lte-767px} {
              font-size: 12px;
            }
          }
        }
        .icon-success {
          display: inline-block;
          position: relative;
          background: #36987e;
          @include border-radius(50%);
          &:before {
            content: "";
            position: absolute;
            @include transform(rotate(-45deg));
          }
          &.success-one {
            width: 20px;
            height: 20px;
            margin: 0 0 7px 1px;
            vertical-align: middle;
            &:before {
              top: 6px;
              left: 4px;
              width: 10px;
              height: 4px;
              border-bottom: 2px solid #fff;
              border-left: 2px solid #fff;
            }
          }
          &.success-two {
            width: 39px;
            height: 39px;
            margin: 3px 0 0 1px;
            vertical-align: top;
            &:before {
              top: 11px;
              left: 6px;
              width: 21px;
              height: 9px;
              border-left: 5px solid #fff;
              border-bottom: 5px solid #fff;
              @media #{$screen-lte-767px} {
                width: 14px;
                height: 6px;
                border-bottom: 3px solid #fff;
                border-left: 3px solid #fff;
                left: 4px;
                top: 7px;
              }
            }
            @media #{$screen-lte-767px} {
              width: 25px;
              height: 25px;
            }
          }
        }
        .order-price {
          float: right;
          width: 53.8%;
          @media #{$screen-lte-767px} {
            float: none;
            width: 100%;
            padding-top: 23px;
            clear: both;
            font-size: 0;
          }
          .order-price-icon {
            float: right;
            width: 57px;
            text-align: right;
            @media #{$screen-lte-767px} {
              display: inline-block;
              float: none;
              width: 30px;
              text-align: left;
              vertical-align: top;
            }
            i {
              display: inline-block;
              width: 49px;
              height: 49px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              @media #{$screen-lte-767px} {
                width: 26px;
                height: 26px;
              }
            }
          }
          .order-price-sum {
            float: left;
            width: calc(100% - 57px);
            text-align: right;
            @media #{$screen-lte-767px} {
              display: inline-block;
              float: none;
              width: calc(100% - 35px);
              text-align: left;
              vertical-align: top;
            }
            .price-main-currency {
              font-size: 30px;
              line-height: 56px;
              font-weight: 700;
              color: #000;
              @media #{$screen-lte-767px} {
                font-size: 20px;
                line-height: 29px;
                font-weight: 500;
              }
            }
            .price-USD-currency {
              font-size: 24px;
              line-height: 26px;
              font-weight: 400;
              color: #6f7a83;
              @media #{$screen-lte-767px} {
                font-size: 14px;
                line-height: 16px;
              }
            }
            .order-price__rate {
              padding-top: 8px;
              font-size: 14px;
              line-height: 16px;
              font-weight: 300;
              color: #6f7a83;
              @media #{$screen-lte-767px} {
                padding-top: 2px;
                font-size: 12px;
              }
            }
          }
        }
        .payment-order-purchases {
          clear: both;
          margin-bottom: 38px;
          padding-top: 24px;
          padding-bottom: 15px;
          border-bottom: 1px solid #cad3d9;
          @media #{$screen-lte-767px} {
            margin-bottom: 15px;
          }
          strong {
            display: block;
            float: left;
            margin-right: 5px;
          }
          .purchases-list {
            width: calc(100% - 98px);
            max-width: 570px;
            float: left;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #000;
            @media #{$screen-lte-767px} {
              width: 100%;
            }
            .purchase-item {
              display: inline-block;
              &:after {
                content: ",";
              }
              &:last-child {
                &:after {
                  content: "";
                }
              }
            }
          }
        }
        .payment-info {
          float: left;
          width: 58.3%;
          @media #{$screen-lte-767px} {
            float: none;
            width: 100%;
          }
          .payment-about {
            padding-bottom: 10px;
            font-size: 0;
            .payment-about-title {
              display: inline-block;
              vertical-align: top;
              width: 90px;
              padding-top: 8px;
              font-size: 16px;
              font-weight: 700;
              @media #{$screen-lte-767px} {
                display: block;
                padding-bottom: 8px;
              }
            }
            .payment-about-text {
              display: inline-block;
              padding: 6px 8px;
              font-size: 20px;
              font-weight: 300;
              background: #d3d3d3;
              strong {
                font-weight: 500;
              }
              @media #{$screen-lte-767px} {
                font-size: 15px;
              }
            }
            .payment-about-code {
              max-width: calc(100% - 120px);
              word-break: break-all;
              @media #{$screen-lte-767px} {
                max-width: calc(100% - 30px);
              }
            }
            .payment-copy-icon {
              position: relative;
              display: inline-block;
              width: 30px;
              vertical-align: top;
              height: 36px;
              cursor: pointer;
              &:before,
              &:after {
                content: "";
                display: block;
                position: absolute;
                @include box-sizing(border-box);
                width: 12px;
                height: 15px;
                border: 2px solid #6f7a83;
                background: #f5f5f5;
              }
              &:before {
                left: 11px;
                top: 8px;
              }
              &:after {
                left: 15px;
                top: 12px;
              }
            }
            .payment-about-note {
              font-size: 14px;
              font-weight: 500;
              padding: 7px 0 0 90px;
              @media #{$screen-lte-767px} {
                padding: 7px 0 0;
              }
            }
            .payment-status {
              display: inline-block;
              vertical-align: top;
              padding-top: 8px;
              font-size: 16px;
              font-weight: 700;
              @media #{$screen-lte-767px} {
                padding-top: 0;
              }
            }
          }
        }
        .payment-qr {
          text-align: center;
          width: 185px;
          float: right;
          p {
            padding-top: 15px;
            font-weight: 500;
          }
          img {
            max-width: 100%;
          }
          @media #{$screen-lte-767px} {
            float: none;
            clear: both;
            width: 100%;
            padding-top: 25px;
          }
        }
        .payment-separator {
          display: block;
          overflow: hidden;
          clear: both;
          width: 100%;
          height: 28px;
          margin: 0 0 20px;
          border: none;
          border-bottom: 1px solid #cad3d9;
          background: none;
          @media #{$screen-lte-767px} {
            height: 78px;
          }
        }
        .back-btn {
          display: inline-block;
          position: relative;
          min-height: 36px;
          margin: 0;
          padding: 10px 15px 0 35px;
          background: #36987e;
          color: #fff;
          text-decoration: none;
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
          @include border-radius(5px);
          @include transition(background .2s ease-in-out);
          &:hover {
            background: #176a54;
            color: #fff;
          }
          i {
            position: absolute;
            left: 10px;
            top: 10px;
          }
        }
      }
    }
  }
  .payment-security {
    float: left;
    clear: both;
    padding-top: 38px;
    @media #{$screen-lte-1200px} {
      padding-top: 36px;
    }
  }
  .payment-lang {
    position: relative;
    float: right;
    width: 75px;
    height: 23px;
    margin-top: 34px;
    @media #{$screen-lte-767px} {
      position: absolute;
      top: 15px;
      right: 4.5%;
      margin-top: 0;
    }
    .payment-lang-list {
      position: absolute;
      width: 100%;
      max-height: 23px;
      min-height: 23px;
      overflow: hidden;
      border: 1px solid #cad3d9;
      @include border-radius(5px);
      background: #f5f5f5;
      cursor: pointer;
      @include transition(max-height .3s ease-in-out);
      &:hover{
        max-height: 120px;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 6px;
        top: 8px;
        border: 7px solid transparent;
        border-top-color: #000;
        border-bottom: none;
      }
      .payment-lang-item {
        display: block;
        position: relative;
        height: 21px;
        margin-bottom: 1px;
        padding-left: 15px;
        padding-top: 6px;
        font-size: 13px;
        font-weight: 700;
        line-height: 13px;
        &:hover {
          .lang-link {
            display: block;
          }
        }
        .lang-link {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          display: none;
        }
      }
    }
  }
  .howtopay-btn {
    position: relative;
    float: right;
    height: 23px;
    margin-top: 34px;
    margin-right: 22px;
    padding: 2px 15px;
    border: 1px solid #cad3d9;
    overflow: hidden;
    color: #000;
    background: #f5f5f5;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    line-height:20px;
    @include border-radius(5px);
    cursor: pointer;
    &:hover{
      background: #cad3d9;
    }
    @media #{$screen-lte-767px} {
      position: absolute;
      top: 15px;
      left: 4.5%;
      margin-top: 0;
    }
  }
}
.text-green {
  color: #36987e;
}
.text-red {
  color: #ff0036;
}
.icon-back {
  /*background: url($payment-back-icon) no-repeat;*/
  width: 15px;
  height: 15px;
}
.icon-update {
  /*background: url($payment-update-icon) no-repeat;*/
  width: 25px;
  height: 18px;
  background-size: contain;
  position: absolute;
  left: 11px;
  top: 9px;
}
.icon-arrow_down {
  /*background: url($payment-arrow-down) no-repeat;*/
  width: 17px;
  height: 18px;
}
.pp-icon-warning {
  /*background: url($payment-worning-icon) no-repeat;*/
  width: 45px;
  height: 40px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}
.icon-BTC {
  /*background: url($payment-BTC-icon) no-repeat;*/
}
.icon-x12 {
  /*background: url($payment-X12-icon) no-repeat;*/
}
.icon-eETH {
  /*background: url($payment-ETH-icon) no-repeat;*/
}
.clear:after {
  content: "";
  display: block;
  height: 1px;
  overflow: hidden;
  clear: both;
}
