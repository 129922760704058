/* Cryptozone variables */
@import 'variables';
/* Stylesheets for mixins */
@import 'mixins';
/* Flex layouts library */
@import 'flexology';
/* Angular material styles rewriting */
@import 'ng-mat-rewrites';
/* common stylesheets for material */
@import 'material';
/* Stylesheets for cryptozone header */
@import 'header-menu';
/* Stylesheets for cryptozone my-wallets page */
@import 'my-wallets';
/* Stylesheets for cryptozone icons */
@import 'icons';
/* Stylesheets for cryptozone buttons */
@import 'buttons';
/* Stylesheets for cryptozone buttons */
@import 'containers';
/* common stylesheets for cryptozone */
@import 'common';
/* common stylesheets for material */
@import 'sessions';
/* Stylesheets for cryptozone reports pages */
@import 'reports';
/* Stylesheets for cryptozone marketing pages */
@import 'marketing';
/* Stylesheets for cryptozone settings page */
@import 'settings';
/* Stylesheets for cryptozone merchant product page */
@import 'product';
/* Stylesheets for cryptozone single product page */
@import 'single_product';
/* Stylesheets for cryptozone order product page */
@import 'order';
/* Stylesheets for cryptozone payment page */
@import 'mat-dialog';
/* Stylesheets for cryptozone marketplace page */
@import 'marketplace';
/* Stylesheets for cryptozone affiliate page */
@import 'affiliate-product';
/* Stylesheets for user's views */
@import 'user-views';
/* Stylesheets for cryptozone partners program */
@import 'partners_program';
/* Stylesheets for subscribe page */
@import 'subscribe';
/* Stylesheets for dashboard page */
@import 'dashboard';
/* Stylesheets for dashboard page */
@import 'uploader';
/* Stylesheets for votes pages */
@import 'votes';
/* Stylesheets for upsells pages */
@import 'upsells';
/* Stylesheets for video links pages */
@import 'video-links';
/* Stylesheets for sales pages */
@import 'sales';
/* Stylesheets for "session" views */
@import 'session';
/* Stylesheets for "admin" views*/
@import 'admin';
/* Stylesheets for "merchant" views */
@import 'merchant';
/* Stylesheets for "payment" views */
@import 'payment';
/* Stylesheets for "wallet dialog" views */
@import 'wallets-dialogs';
/* Stylesheets for "wallet" views */
@import 'wallets-main';
/* Stylesheets for "hopscotch-tour" views */
@import 'hopscotch-tour';
/* Stylesheets for ico views */
@import 'ico';
/* Stylesheets for coupon views */
@import 'coupon';
