[class^="icon-"], [class*=" icon-"], [class^="path"] {
  font-family: cryptozone, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-get-app:before {
  content: "\e928";
}

.icon-instant-transaction:before {
  content: "\e929";
}

.icon-no-fees:before {
  content: "\e92a";
}

.icon-star-partial {
  position: relative;
  line-height: 22px;
}

.icon-star-partial:before {
  content: "\E901";
  position: absolute;
  left: 0;
  overflow: hidden;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.icon-star-partial:after {
  content: "\e90f";
}

.partial-10:before{
  width: 10%;
}
.partial-20:before{
  width: 20%;
}
.partial-30:before{
  width: 30%;
}
.partial-40:before{
  width: 40%;
}
.partial-50:before{
  width: 50%;
}
.partial-60:before{
  width: 60%;
}
.partial-70:before{
  width: 70%;
}
.partial-80:before{
  width: 80%;
}
.partial-90:before{
  width: 90%;
}
.partial-100:before{
  width: 100%;
}

.icon-settings:before {
  content: "\e92c";
}

.icon-logout:before {
  content: "\e900";
}

.icon-dashboard:before {
  content: "\e903";
}

.icon-clock_icon:before {
  content: "\e907";
}
.icon-get_app:before {
  content: "\e928";
}
.icon-instant_transaction:before {
  content: "\e929";
}
.icon-no_fees:before {
  content: "\e92a";
}
.icon-blog:before {
  content: "\e91f";
}
.icon-checked-2:before {
  content: "\e920";
}
.icon-close:before {
  content: "\e921";
}
.icon-data-protection:before {
  content: "\e922";
}
.icon-eye:before {
  content: "\e923";
}
.icon-imprint:before {
  content: "\e924";
}
.icon-play-2:before {
  content: "\e925";
}
.icon-plus-2:before {
  content: "\e926";
}
.icon-support-2:before {
  content: "\e927";
}
.icon-checked:before {
  content: "\e91d";
}
.icon-menu-button:before {
  content: "\e91e";
}
.icon-edit:before {
  content: "\e91b";
}
.icon-delete:before {
  content: "\e91c";
}
.icon-ETH-symbol:before {
  content: "\e916";
}
.icon-BTC-symbol:before {
  content: "\e917";
}
.icon-EUR-symbol:before {
  content: "\e918";
  font-weight: 100;
  font-size: 12px;
}
.icon-USD-symbol:before {
  content: "\e919";
}
.icon-left-arrow:before {
  content: "\e91a";
}
.icon-info:before {
  content: "\e915";
}
.icon-my-wallets:before {
  content: "\e914";
}
.icon-star-full:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e902";
}
.icon-BTC:before {
  content: "\e903";
}
.icon-discount:before {
  content: "\e904";
}
.icon-graphic-tool:before {
  content: "\e905";
}
.icon-laptop:before {
  content: "\e906";
}
.icon-like:before {
  content: "\e909";
}
.icon-networking:before {
  content: "\e908";
}
.icon-online-shop:before {
  content: "\e90a";
}
.icon-payment-method:before {
  content: "\e90b";
}
.icon-play-button:before {
  content: "\e90c";
}
.icon-profits:before {
  content: "\e90d";
}
.icon-right-arrow:before {
  content: "\e90e";
}
.icon-shopping-cart:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e90f";
}
.icon-technical-support:before {
  content: "\e913";
}
.icon-trash:before {
  content: "\e911";
}
.icon-user:before {
  content: "\e911";
}

.icon-enrollments:before {
  content: "\e924";
}

// todo this icon must be turned into single one
.icon-orders .path1:before {
  content: "\e912";
  color: #37bced;
}
.icon-orders .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path10:before {
  content: "\e935";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path11:before {
  content: "\e936";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path12:before {
  content: "\e937";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path13:before {
  content: "\e938";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path14:before {
  content: "\e939";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path15:before {
  content: "\e93a";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path16:before {
  content: "\e93b";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path17:before {
  content: "\e93c";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path18:before {
  content: "\e93d";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path19:before {
  content: "\e93e";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path20:before {
  content: "\e93f";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path21:before {
  content: "\e940";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path22:before {
  content: "\e941";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path23:before {
  content: "\e942";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path24:before {
  content: "\e943";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path25:before {
  content: "\e944";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path26:before {
  content: "\e945";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path27:before {
  content: "\e946";
  margin-left: -1em;
  color: #37bced;
}
.icon-orders .path28:before {
  content: "\e947";
  margin-left: -1em;
  color: #37bced;
}

.nav-item:hover .icon-orders span:before{
  color: #ffffff !important;
}

.nav-item.active-nav-item .icon-orders span:before{
  color: #ffffff !important;
}

