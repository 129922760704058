$fonts-source-path: '../../fonts'; // source path variable for font files

/* Mixin for font-face */
@mixin font-face($font-name, $font-dir, $font-file, $source-path, $font-weight, $font-style, $svg) {
  @font-face {
    font-family: #{$font-name};
    font-weight: $font-weight;
    font-style: $font-style;
    src: url('#{$source-path}/#{$font-dir}/#{$font-file}.eot'),
    url('#{$source-path}/#{$font-dir}/#{$font-file}.eot?#iefix') format('embedded-opentype'),
    url('#{$source-path}/#{$font-dir}/#{$font-file}.woff') format('woff'),
    url('#{$source-path}/#{$font-dir}/#{$font-file}.ttf') format('truetype');
    @if ($svg) {
      src: url('#{$source-path}/#{$font-dir}/#{$font-file}.svg') format('svg');
    }
  }
}

/*
    background color attribution and hover behavior,
    $color       - background-color value,
    $brightness  - can accept [lighten, darken] values
    $percent     - percentage value from 0 to 100.
*/
@mixin background-mixin ($color, $brightness, $percent) {
    background-color: $color !important;
    &:hover {
        @if $brightness == 'lighten' {
            background-color: lighten($color, $percent) !important;
        }
        @if $brightness == 'darken' {
            background-color: darken($color, $percent) !important;
        }
    }
}

/* Mixin for box-shadow property */
@mixin box-shadow ($box-shadow-model) {
  -webkit-box-shadow: $box-shadow-model;
  -moz-box-shadow: $box-shadow-model;
  box-shadow: $box-shadow-model;
}

/* Mixin for box-sizing property */
@mixin box-sizing ($box-sizing-model) {
  -webkit-box-sizing: $box-sizing-model;
  -moz-box-sizing: $box-sizing-model;
  box-sizing: $box-sizing-model;
}

/* Mixin for border-radius property */
@mixin border-radius ($border-radius-value) {
  -webkit-border-radius: $border-radius-value;
  -moz-border-radius: $border-radius-value;
  border-radius: $border-radius-value;
}

/* Mixin for transition property */
@mixin transition ($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

/* Mixin for transform property */
@mixin transform ($transform-model) {
  -webkit-transform: $transform-model;
  -moz-transform: $transform-model;
  transform: $transform-model;
}
