@include margin;
@include padding;

body {
  background-color: $main-back-gray !important;
  height: 100%;
  font-family: AvenirRoman, sans-serif !important;
}

h4 {
  padding: 0 !important;
}

/*
.main_content{
  padding-top: 120px;
}

#main{
  display: flex;
  flex-direction: column;
}*/

app-main-layout {
    max-width: 1920px;
    margin: 0 auto;
}

app-main-layout,
app-marketplace-layout,
app-order-layout {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
}

app-footer {
  display: block !important;
  flex: none !important;
  margin-top: auto !important;
}

app-marketplace {
  flex: 1 0 100%;
  flex-direction: row;
}

app-landing {
  margin-top: 82px;
}

.content-main-holder {
  max-width: 1400px;
  margin: 120px 30px 0 280px;
  &.guest {
    margin: 120px 90px 0 120px;
    @media #{$screen-md} {
      margin: 120px 30px 0 30px;
    }
    @media #{$screen-lt-md} {
      margin: 120px 30px 0 30px;
    }
  }
  @media #{$screen-md} {
    margin: 120px 30px 0 250px;
  }
  @media #{$screen-lt-md} {
    margin: 120px 10px 0 10px;
  }
  &.merchant-content-main-holder {
    max-width: none;
  }
}

.layout-holder {
  &.merchant-layout-holder {
    margin-right: 0;
  }
}

.container-fixer {
  padding-right: 18px;
  @media #{$screen-lt-md} {
    padding-right: 0;
  }
}

.header-container {
  h4 {
    vertical-align: middle;
    font-size: 19px;
    font-weight: bold;
    color: $night-blue;
  }
}

h4 {
  font-family: AvenirBlack, sans-serif !important;
  color: $night-blue;
  font-size: 14px;
  margin-bottom: 0;
  padding: 15px 0;
  font-weight: bold;
  line-height: 25px;
}

.relative-container {
    position: relative !important;
}

.text-center {
  text-align: center;
}

.block-container {
  margin-bottom: 0;
  .title {
    margin-bottom: 5px;
  }
  .text-center {
    padding: 50px 0 0 0;
  }
}

.pace .pace-progress {
  background: $light-blue;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.currencies {
  padding: 15px;
  .total {
    color: $sallad-green;
    font-size: 22px;
  }
  .table {
    background-color: $background-gray;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 5px 10px;
  }
  .value {
    text-align: right;
  }
  .currency-row {
    margin-bottom: 6px;
    &.dashboard-balance {
      .value {
        .icon-BTC-symbol {
          font-size: 12px;
        }
      }
    }
  }
}

.recent-activity {
  padding: 15px;
  .table {
    padding: 6px 10px;
    background-color: #ffffff;
   /* &:nth-child(odd) {
      background-color: $background-gray;
    }*/
    .status {
      color: $light-gray;
      font-size: 10px;
    }
    > div {
      &:last-child {
        text-align: right;
      }
      .positive {
        color: $dark-green;
      }
      .negative {
        color: $scared-red;
      }
    }
  }
}

.completion {
  b {
    color: $medium-gray;
    font-size: 12px;
    i {
      color: $sallad-green;
    }
  }
}

.no-label-chart,
.label-chart {
  padding: 15px;
  position: relative;
  .total {
    color: $light-blue;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    position: absolute;
    top: -5px;
    padding: 5px 15px;
    border-radius: 7px;
    font-weight: bold;
  }
}

.label-chart {
    padding: 25px 15px;
    .total { top: -13px; }
}

.tab-container {
  text-align: right;
  padding: 15px 0;
  @media #{$screen-lt-md} {
    text-align: left;
  }
  .add-product-button {
    @media #{$screen-lt-md} {
      float: right;
    }
  }
  .tab {
    display: inline-flex;
    li {
      &:focus {
        outline: 0;
      }
      background-color: #ffffff;
      display: inline-block;
      color: $night-blue;
      padding: 3px 10px;
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: $light-blue;
        color: #ffffff;
      }
      &.active {
        background-color: $light-blue;
        color: #ffffff;
        &:after {
          background-color: $light-blue;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: -19px;
        left: 0px;
        width: .5px;
        height: 14px;
        margin: 25px 0;
        background-color: #cccccc;
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
        &:after {
          width: 0px;
        }
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

.tab-container-big {
  text-align: left;
  padding: 20px 4px;
  @media #{$screen-lt-md} {
    text-align: right;
  }
  .add-product-button {
    @media #{$screen-lt-md} {
      float: right;
    }
  }
  .tab {
    display: inline-flex;
    li {
      &:focus {
        outline: 0;
      }
      background-color: #ffffff;
      display: inline-block;
      color: $night-blue;
      padding: 8px 13px;
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: $light-blue;
        color: #ffffff;
      }
      &.active {
        background-color: $light-blue;
        color: #ffffff;
        &:after {
          background-color: $light-blue;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: -19px;
        left: 0px;
        width: .5px;
        height: 25px;

        margin: 25px 0;
        background-color: #cccccc;
      }
      &:first-child {
        border-radius: 6px 0 0 6px;
        &:after {
          width: 0px;
        }
      }
      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }
}

.product-search-bar {
  margin-top: 30px;
  @media #{$screen-lt-md} {
    margin-top: 0;
  }
}

.product {
    transition: all .3s ease-in-out;
  h2 {
    font-size: 16px;
    color: $light-blue;
    margin-bottom: 0;
    font-weight: bold;
  }
  .short-description {
    color: $night-blue;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .line {
    height: 1px;
    border-bottom: 1px solid $light-gray;
  }
  .info-container {
    padding: 20px;
  }
  .button {
    text-align: right;
  }
  .rating-button {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.products-page {
  .product {
    .price {
      position: absolute;
      background-color: #ffffff;
      padding: 8px 13px;
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      right: 26px;
      margin-top: -25px;
      font-size: 17px;
      border-radius: 10px;
      color: $light-blue;
      font-weight: bold;
    }
  }
  .info-container {
    /*height: 130px;*/
    h2 {
      padding: 5px 0;
    }
    .description-wrapper {
      height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      .short-description {
        color: $light-gray;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
    .edit {
      color: $sallad-green;
    }
    .review {
      color: $light-blue;
    }
    .duplicate {
      color: $june-apricot;
    }
    .remove {
      color: $scared-red;
    }
    i {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}

.corner-ribbon {
    min-width: 200px;
    position: absolute;
    text-align: center;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1px;
    color: $white;
    p {
        display: inline-block;
        margin: 0;
    }
    &.top-left {
        top: 25px;
        left: -60px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    &.approved {background-color: $sallad-green;}
    &.pending {background-color: $june-apricot;}
    &.rejected {background-color: $scared-red;}
    &.drafted {background-color: $light-blue}
}

.product {
  margin-bottom: 30px;
    .image-wrapper {
        min-height: 280px;
    }
  .image {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.heading-common-title {
    .common-title {
        padding: 0;
        font-size: 24px;
        line-height: 24px;
        color: $light-blue;
    }
}

.header-image {
  background-image: url($marketplace-upper-background);
  height: 420px;
  width: 100%;
  background-size: cover;
  margin-top: -38px;
  position: relative;
  .black-layer {
    background-color: #000000c9;
    position: absolute;
    width: 100%;
    height: 100%;
    .search {
      padding: 170px 0px 60px 0px;
    }
    .search-bar {
      height: 100%;
      .search-button {
        border-radius: 0 8px 8px 0;
      }
    }
    .search-container-marketplace {
      text-align: center;
      border-radius: 8px;
      .search-input-marketplace {
        width: 100%;
        padding: 7px 17px;
        border: 0;
        border-radius: 8px 0 0 8px;
        line-height: 21px;
        color: $light-gray;
        &:focus {
          outline: none;
        }
      }
    }
    .statistics {
      text-align: center;
      .number {
        font-size: 40px;
        color: $light-blue;
        font-weight: bold;
        sup {
          font-size: 18px;
          margin-left: 5px;
          font-weight: bold;
        }
      }
      .text {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}

/*.header-image-marketplace {
  background-image: url("./../../images/header.png");
  height: 420px;
  width: 100%;
  background-size: cover;
  margin-top: -38px;
  position: relative;
  .black-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    .search {
      padding: 170px 0px 155px 0px;
    }
    .search-bar {
      height: 100%;
      .search-button {
        border-radius: 0 8px 8px 0;
      }
    }
    .search-container-marketplace {
      text-align: center;
      background-color: #ffffff;
      border-radius: 8px;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      background-color: #ffffff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      .search-input-marketplace {
        width: 100%;
        padding: 7px 17px;
        border: 0px;
        border-radius: 8px;
        line-height: 21px;
        color: $light-gray;
        &:focus {
          outline: none;
        }
      }
    }

    .marketplace-statistics {
      text-align: center;
      .number {
        font-size: 25px;
        color: $light-blue;
        font-weight: bold;
        @media #{$screen-lt-md}{
          font-size: 20px;
        }
        b {
          color: #000000;
          font-size: 14px;
          font-weight: 100;
          @media #{$screen-lt-md}{
            font-size: 12px;
          }
        }
      }
    }
  }
}*/

.short-description {
  color: $light-gray;
  font-size: 12px;
  padding: 10px 0;
}

.rating-bar {
  position: absolute;
  background-color: #ffffff;
  padding: 6px 13px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  right: 26px;
  margin-top: -25px;
  font-size: 17px;
  border-radius: 10px;
  color: #37bced;
  font-weight: bold;
}

.marketing-page {
  img {
    display: block;
    border-radius: 8px;
  }
}

.marketing-page-info {
  background-color: #ffffff;
  padding: 14px;
  border-radius: 8px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 70px;
  left: -20%;
  width: 260px;
  @media #{$screen-lt-md} {
    left: -15px;
  }
  h6 {
    font-size: 22px;
    color: $light-blue;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    color: $light-gray;
  }
}

.main-bg {
  background-color: $light-gray;
  background-image: url($signin-background);
  background-size: cover;
}

.formprofile-title {
  margin-top: 0.2em;
  margin-bottom: 1.3em;
  font-size: 1.6em;
  text-align: left;
  text-transform: inherit;
  color: #35d8b9 !important;
  font-weight: bold;
}

.formprofile {
  width: 25em;
  padding: 1.9em 2.6em 0.7em;
    .common-title {
        &.left-title {
            text-align: left;
        }
    }
}

.login-buttons {
  margin-top: 35px;
  .login {
    border: 2px solid #35d8b9 !important;
    background-color: #35d8b9 !important;
    color: #ffffff !important;
    padding: 7px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover {
      opacity: 0.8 !important;
    }
  }

  .register {
    border: 2px solid $light-blue !important;
    color: $light-blue !important;
    background-color: #FFFFFF !important;
    padding: 7px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover {
      background-color: $light-blue !important;
      color: #ffffff !important;
    }
  }
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, .0), 0 0px 0px 0 rgba(0, 0, 0, .0), 0 0px 0px 0 rgba(0, 0, 0, .0) !important;
}

.formprofile-input {
  label {
    font-size: 13px;
    color: $light-gray;
  }
  input {
    border: 0 solid red !important;
    border-radius: 8px;
    background-color: white;
  }
}

chart {
  background-color: #fbfbfb;
  display: block;
}

.header-background-full {
  margin-left: -280px;
  margin-right: -30px;
  @media #{$screen-lt-md} {
    margin-left: -30px;
    margin-right: -30px;
  }
}
.video-tutorials-wrapper {
  margin-bottom: 10px;
  @media #{$screen-lt-md} {
    margin-bottom: 0;
  }
}
.video-tutorials {
  margin-bottom: 10px;
  @media #{$screen-lt-md} {
    margin-bottom: 40px;
  }
  img {
    width: 100%;
    max-height: 320px;
  }
}

.info-field-container {
  .info {
    height: 100%;
    font-size: 16px;
    @media #{$screen-lt-md} {
      font-size: 14px !important;
    }
  }
  i {
    font-size: 13px;
  }
  .need-response {
    color: $scared-red;
  }
  .in-process {
    color: $light-blue;
  }
  .resolved {
    color: $sallad-green;
  }
  .info-number {
    text-align: right;
  }
}

.feedback-field-container {
  .feedback {
    @media #{$screen-lt-md} {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .negative-feedback {
    color: $scared-red;
  }
  .neutral-feedback {
    color: $light-blue;
  }
  .positive-feedback {
    color: $sallad-green;
  }
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 6px;
  &.red {
    background-color: $scared-red;
  }
  &.blue {
    background-color: $light-blue;
  }
  &.green {
    background-color: $sallad-green;
  }
}

.circle-info {
  div:first-child {
    font-size: 29px;
    font-weight: bold;
    @media #{$screen-lt-sm} {
      font-size: 16px;
    }
  }
  div:last-child {
    color: $light-gray;
  }
  &.red {
    color: $scared-red;
  }
  &.green {
    color: $sallad-green;
  }
  &.blue {
    color: $light-blue;
  }
}

footer {
  flex: 0 0 auto;
}

.dropdown-arrow-wrapper {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  .dropdown-arrow {
    position: relative;
    top: 10px;
    &:before,
    &:after {
      content: '';
      width: 12px;
      height: 3px;
      border-radius: 5px;
      background-color: $dark-green;
      display: inline-block;
      position: absolute;
      @media #{$screen-lt-md} {
        width: 7px;
        height: 2px;
      }
    }
    &:before {
      transform: rotate(-45deg);
      left: 7px;
      @media #{$screen-lt-md} {
        left: 4px;
      }
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}

/* Datepicker styles */

.mat-form-field-wrapper {
  width: 100%;
  min-height: 46px !important;
  padding: 0 16px !important;
  border-radius: 8px !important;
    @media #{$screen-lt-lg} {
        height: 40px !important;
    }
}
.mat-form-field-appearance-legacy {
    .mat-form-field-infix {
        @media #{$screen-lt-lg} {
            padding: .1em 0 !important;
        }
    }
}
.mat-form-field-label-wrapper {
    @media #{$screen-lt-lg} {
        top: -1em;
        .mat-form-field-label {
            font-size: .875em;
        }
    }
}

.tags-form-field {
    .mat-form-field-wrapper {
        height: auto !important;
    }
}

.percent-form-field {
    height:36px !important;
    font-size: 16px !important;
    @media #{$screen-lt-sm} {
        height: 30px !important;
        margin-bottom: 4px !important;
        font-size: 12px !important;
    }
    @media #{$screen-lt-xxs} {
        max-width: 80% !important;
    }
    .mat-form-field-wrapper {
        height: auto !important;
    }
    .mat-form-field-infix {
        border-top: 2px solid transparent !important;
        .mat-form-field-label {
            top: 1.5em;
            font-size: 1em;
        }
    }
    .mat-input-element {
        margin-top: 4px !important;
    }
}

.mat-form-field:not(.description-form-field,.percent-form-field) {
  height: 46px !important;
}
.mat-form-field {
  border-radius: 6px;
  background-color: $white !important;
}

.mat-form-field-underline {
  background-color: transparent !important;
  width: 0 !important;
}

.mat-form-field-placeholder-wrapper {
  font-size: 14px !important;
  line-height: 14px !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-lines {
    white-space: pre-line;
}

.settings-page {
  padding: 15px 35px;
}

.fee_field {
  color: $white !important;
  background-color: $light-blue !important;
}

.payout_select {
  padding: 0.83em 1em !important;
  color: $dark-blue;
  border: 1px solid #d1d1d1 !important;
}

.currencies-background-container {
    height: 100vh;
    background-color: $currencies-black;
}

.currencies-main-container {
    background-color: $main-back-gray;
    width: 1150px;
    @media #{$screen-lt-lg} {
        width: 100% !important;
    }
    .inner-wrapper {
        position: relative;
        min-height: 600px;
    }
    .currencies-wrapper {
        width: 690px;
        @media #{$screen-sm} {
            width: 410px !important;
        }
        @media #{$screen-lt-sm} {
            width: 270px !important;
        }
    }
}
.common-container-currency {
    padding-bottom: 15px;
    margin: 20px;
    font-family: AvenirRoman, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #2ba5c8;
    text-transform: uppercase;
}

.currency {
    //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    h2 {
        font-size: 12px;
        color: $light-gray;
        margin-bottom: 0px;
        font-weight: normal;
        text-align: center;
    }
    .overflow-hidden {
        overflow: hidden;
    }
    .line {
        height: 1px;
        border-bottom: 1px solid $light-gray;
    }
    .info-container {
        padding: 5px;
    }
    .button {
        text-align: right;
    }
    @media #{$screen-md} {
        font-size: .9em;
    }
    @media #{$screen-xs} {
        font-size: .1em;
    }
}

.image {
    img {
        width: 100%;
        height: auto;
    }
}

/* Style the list */
ul.breadcrumb {
  list-style: none;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  color: $dark-blue;
  content: "\003e";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: $dark-blue;
  text-decoration: none;
}

/* Add a color to all links inside the list */
ul.breadcrumb li:last-child a {
  color: $light-blue;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: $dark-blue;
  text-decoration: underline;
}

.straight-line-between {
  width: 100%;
  height: 1px;
  &.light-gray-line {
    border-bottom: 1px solid $border-label-gray;
  }
}

app-info {
  app-upload {
    margin-top: 10px;
  }
}
// todo delete all commented components
/*app-merchant-dashboard,
app-product-list,
app-products,
app-merchant-settings,
app-admin-settings,
app-advanced,
app-search,
app-my-wallets,
app-marketplace,
app-customer-support,
app-use-your-page,
app-affiliates,
app-marketing,
app-landing,
app-info,
app-user-dashboard,
app-reports,
app-sales-by-affiliate,
app-sales-by-date,
app-sales-by-product,
app-refunded-products,
app-subscriptions,
app-payouts,
app-invoices,
app-become-merchant,
app-affiliate-statistics,
app-commission-by-date,
app-commission-by-level,
app-commission-by-merchant,
app-commission-by-product,
app-partner-statistics,
app-partner-sales-vs-referrals,
app-partner-commission-by-date,
app-partner-commission-by-level {
  width: 100%;
}*/

router-outlet + * {
    width: 100%;
}

.header-margin {
  margin-top: 82px;
}

.nice-shadow {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.07);
}

.horizontal-scroll-table {
    overflow-x: auto;
}
.horizontal-scroll-table-header,
.horizontal-scroll-table-body {
    min-width: 850px;
}

.order-details-table {
  flex-direction: column !important
}

.order-details {
  color: #ccc !important;
  a {
    color: #37bced;
    text-decoration: underline !important;
  }
}

.order-details-container {
  justify-content: flex-end;
}

.order-btn-container {
  flex-direction: column !important;
  .light-blue-btn {
    background-color: #1d93c1 !important;
  }
  .dark-blue-btn {
    background-color: #254974 !important;
  }
}

app-confirm-alert {
    .confirm-dialog-actions {
        display: block;
        text-align: center;
    }
}

.mobile-full-flex {
    @media #{$screen-lt-xxs} {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
    }
}

.no-line-height {
    line-height: unset;
}
.dialog-close-button-wrapper {
    position: absolute;
    right: -14px;
    top: -14px;
    width: 32px;
    height: 32px;
    padding: 8px;
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 20px;
    cursor: pointer;
    span.icon-close.remove-button {
        display: block;
        position: static;
        width: 16px;
        height: 16px;
        margin: 0 auto !important;
        &:hover { opacity: unset !important; }
    }
}
span.icon-close.remove-button {
    width: 13px;
    height: 13px;
    margin-left: 8px !important;
    color: $scared-red !important;
    opacity: 1 !important;
    position: absolute;
    right: 0;
    &:hover {
        opacity: .5 !important;
    }
}

.payment-type-row {
    pointer-events: none;
    opacity: 0.4;
}

/* Payouts page */

.payout-status {
    &.pending {color: $june-apricot !important;}
    &.fulfilled {color: $sallad-green !important;}
}
