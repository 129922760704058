/* Main wrapper */
#main {
  background-size: 126% 126%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 40;
}

/* Header */
.page-header {
  position: fixed;
  width: 100%;
  height: 102px;
  margin: 0;
  padding: 20px 0;
  border: none;
  background-color: #152029;
  z-index: 50;
  @media #{$screen-lte-1099px} {
    height: auto;
    min-height: 102px;
  }
}

.logo,
.header-menu {
  display: inline-block;
  vertical-align: top;
  margin-right: 45px;
}

.logo {
  @media #{$screen-lte-1200px} {
    margin-right: 0;
  }
  @media #{$screen-lte-1099px} {
    margin-bottom: 10px;
  }
  .logo-link {
    display: block;
  }
}

.header-menu {
  padding-top: 12px;
  li {
    display: inline-block;
    line-height: 36px;
    text-transform: uppercase;
    font-size: 13px;
    font-family: "HelveticaBold", sans-serif;
    @media #{$screen-lt-md} {
      display: block;
      border-bottom: 1px solid #ffc600;
      &:last-child {
        border-bottom: none;
      }
    }
    a {
      display: block;
      padding: 0 24px;
      color: #fff;
      &:hover {
        color: #ffc600;
      }
    }
  }
  @media #{$screen-lt-md} {
    display: block;
    position: fixed;
    left: -220px;
    top: 0;
    width: 220px;
    min-height: 240px;
    padding-top: 56px;
    text-align: left;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all .3s ease;
    z-index: 51;
  }
  @media #{$screen-lte-767px} {
    padding-top: 46px;
  }
  &.show {
    left: 0;
    @media #{$screen-lte-767px} {
      width: 170px;
    }
    .nav-btn {
      right: 18px;
      transition: all .3s ease;
    }
  }
  // Sandwich button
  .nav-btn {
    display: none;
    position: absolute;
    top: 15px;
    right: -55px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    background: #ffc600;
    background: rgba(255, 198, 0, 0.8);
    transition: all .3s ease;
    @media #{$screen-lt-md} {
      display: block;
    }
    @media #{$screen-lte-767px} {
      right: -40px;
      width: 30px;
      height: 30px;
    }
    span {
      display: block;
      position: absolute;
      right: 5px;
      background: #000;
      transition: .3s;
      width: 30px;
      height: 3px;
      @media #{$screen-lte-767px} {
        top: 14px;
        width: 20px;
      }
      &::before,
      &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        content: '';
        background: #000;
        transition: .3s;
      }
      &::before {
        top: -10px;
        @media #{$screen-lte-767px} {
          top: -8px;
        }
      }
      &::after {
        top: 10px;
        @media #{$screen-lte-767px} {
          top: 8px;
        }
      }
    }
    &.toggle {
      span {
        background: transparent;
        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
        &::before {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.profile-menu {
  display: inline-block;
  padding-top: 17px;
  @media #{$screen-lte-1099px} {
    display: block;
    padding-top: 0;
  }
  &.logged-in {
    float: right;
    padding-top: 15px;
    @media #{$screen-lt-md} {
      float: none;
      padding-top: 0;
    }
  }
  .lang-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 5px;
    @media #{$screen-lte-383px} {
      display: block;
    }
    #langToggle {
      .mat-select-trigger {
        width: 80px;
        min-width: 80px;
      }
      &.lang-wide-wrapper {
        .mat-select-trigger {
          min-width: 100px;
        }
      }
    }
  }
  li {
    display: inline-block;
    line-height: 1.7;
    font-size: 13px;
    font-family: "HelveticaBold", sans-serif;
    text-transform: uppercase;
    &.login-button {
      margin-right: 4px;
      a {
        border: 1px solid rgba(255, 255, 255, 0.19);
        color: #FFF;
        &:hover {
          background-color: rgba(255, 255, 255, 0.19);
          border-color: rgba(255, 255, 255, 0.35);
        }
        &::after {
          width: 10px;
          height: 13px;
          /*background-image: url($header-menu-signin-user);*/
        }
      }
    }
    &.signup-button {
      a {
        background-color: #ffc600;
        border: 1px solid #ffc600;
        color: #000;
        padding-left: 35px;
        &:hover {
          background-color: #ffdd66;
        }
        &::after {
          width: 14px;
          /*background-image: url($header-menu-sign-up);*/
        }
      }
    }
    &.login-button,
    &.signup-button {
      a {
        padding: 1px 1em 1px 2.4em;
        transition-property: border-color, background-color;
        transition-duration: 240ms;
        transition-timing-function: cubic-bezier(0.51, 0.51, 0.23, 0.99);
        &:after {
          position: absolute;
          content: "";
          top: 4px;
          left: 11px;
          height: 14px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    &.username-button {
      a {
        padding-left: 2.2em;
        padding-right: 0.8em;
        border: 1px solid rgba(255, 255, 255, 0.19);
        color: #ffc600;
        font-family: "HelveticaBold", sans-serif;
        text-transform: none;
        &:after {
          position: absolute;
          top: 2px;
          left: 10px;
          width: 11px;
          height: 13px;
          content: "";
          /*background: url($header-menu-yellow-user) no-repeat center center;*/
        }
        &:hover {
          color: #ffdd66;
        }
      }
    }
    &.logout-button {
      span {
        position: relative;
        display: block;
        padding-left: 2.2em;
        padding-right: 0.8em;
        border: 1px solid rgba(255, 255, 255, 0.19);
        border-radius: 4px;
        color: #fff;
        white-space: nowrap;
        font-size: 13px;
        font-weight: bold;
        &:after {
          position: absolute;
          top: 4px;
          left: 10px;
          width: 14px;
          height: 14px;
          content: "";
          /*background: url($header-menu-logout) no-repeat center center;*/
        }
      }
    }
    &.username-button,
    &.logout-button {
      a, span {
        transition-property: border-color, background-color;
        transition-duration: 240ms;
        transition-timing-function: cubic-bezier(0.51, 0.51, 0.23, 0.99);
        &:hover {
          background-color: rgba(255, 255, 255, 0.19);
          border-color: rgba(255, 255, 255, 0.35);
        }
      }
    }
  }
  a {
    display: block;
    position: relative;
    padding: 0 1.4em;
    border-radius: 4px;
    white-space: nowrap;
  }
}

/* Footer */

.page-footer {
  min-height: 200px;
  height: auto;
  padding-top: 50px;
  position: relative;
  z-index: 48;
  @media #{$screen-lte-992px} {
    min-height: 0;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  @media #{$screen-lte-767px} {
    padding-top: 38px;
    padding-bottom: 40px;
  }
}

.footer-right {
  padding-top: 12px;
  text-align: right;
  @media #{$screen-lte-992px} {
    margin-bottom: 20px;
    padding-top: 0;
    text-align: center;
  }
  .footer-menu {
    line-height: 2;
    @media #{$screen-lte-992px} {
      display: block;
      float: none;
    }
    @media #{$screen-lte-767px} {
      ul {
        display: block;
      }
    }
    &.sessions-footer {
      li {
        a {
          padding: 0 .2em;
        }
      }
    }
    li {
      display: inline-block;
      font-size: 16px;
      text-transform: uppercase;
      @media #{$screen-lte-1200px} {
        padding: 0;
      }
      @media #{$screen-lte-992px} {
        line-height: 26px;
        text-align: center;
        padding: 0;
      }
      @media #{$screen-lte-767px} {
        display: block;
      }
      &::after {
        display: inline;
        content: '|';
        @media #{$screen-lte-767px} {
          display: none;
          content: none;
        }
      }
      &:last-child::after {
        content: none;
      }
      a {
        padding: 0 .8em;
        @media #{$screen-lte-992px} {
          padding: 0 .2em;
        }
      }
    }
  }
}

.footer-left {
  position: absolute;
  top: 0;
  width: 20em;
  margin: 0;
  text-align: left;
  @media #{$screen-lte-992px} {
    display: block;
    position: static;
    width: 100%;
    padding-top: 0;
    text-align: center;
  }
  @media #{$screen-lte-767px} {
    padding-bottom: 0;
  }
  .footer-copyright {
    margin-top: 20px;
    font-size: 16px;
    @media #{$screen-lte-1099px} {
      margin: 0 0 10px;
    }
  }
}
// Sector dashboard
.s-dashboard {
  padding-top: 152px;
  padding-bottom: 75px;
  background-color: #9ecff7;
}

.container-fix {
  max-width: 1158px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

.dashboard {
  position: relative;
  min-height: 723px;
  background-color: #dee9f1;
}

//.row,
//.wrapper {
//  letter-spacing: -0.3em;
//}

.row {
  margin-left: -12px;
  margin-right: -12px;
}

.dashboard-container {
  display: inline-block;
  width: calc(100% - 267px);
  margin-left: 267px;
  padding: 10px 11px 11px 10px;
  vertical-align: top;
  letter-spacing: 0;
  @media #{$screen-lt-md} {
    width: 100%;
    margin-left: 0;
  }
}

.dashboard-history {
  h5 {
    font-size: 25px;
  }
}
.dashboard-field-icon {
  position: absolute;
  right: 1px;
  bottom: 5px;
  cursor: pointer;
}

.deactivation-wrapper {
  padding-top: 22px;
}

header {
  @media #{$screen-lt-md} {
    .col {
      text-align: center;
    }
  }
}

.col_1-4,
.col_1-3,
.col {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px;
  letter-spacing: 0;
}

.col {
  width: calc(100% - 24px);
}

.col_1-4 {
  width: calc(100% / 4 - 24px);
}

.col_1-3 {
  width: calc(100% / 3 - 24px);
}

.footer-col {
  display: block;
  position: relative;
  @media #{$screen-gte-768px} {
    min-height: 100px;
  }
}

.maintenance-message-container {
  width:100%;
  position: absolute;
  margin-top: 16px;
}

.maintenance-message{
  margin: 0 auto;
  color: #000000;
  display: table;
  background-color: #ffc600;
  padding: 5px 15px;
  border-radius: 10px;
}
