.sales-main-container {
    position: relative;
    background-color: $white;
}
.sales-main-back-image {
    position: relative;
}
.name-coverage {
    position: absolute;
    top: 0;
    left: 0;
    width: 12%;
    height: .5%;
    background-color: $white;
    z-index: 10;
}
.enroll-button-container {
    position: absolute;
    width: 100%;
    height: .5%;
    justify-content: center;
    background-color: transparent;
    z-index: 10;
    .enroll-button-wrapper {
        position: relative;
        width: 16%;
        height: 100%;
        padding: 0 !important;
        text-align: left;
        &:hover {
            cursor: auto !important;
        }
    }
    button.main {
        position: relative;
        width: 95%;
        height: 100%;
        background-color: transparent !important;
        opacity: .4;
        &:hover {
            background-color: $white !important;
            opacity: .2;
        }
    }
    &.top-container {
        top: 5.82%;
        button.main {
            left: 1.4%;
            width: 92%;
            height: 98%;
        }
    }
    &.middle-container {
        top: 83.56%;
        .enroll-button-wrapper {
            width: 14%;
        }
        button.main {
            left: 8%;
            width: 84%;
        }
    }
    &.bottom-container {
        bottom: 0.92%;
        button.main {
            left: 1.4%;
            width: 92%;
        }
    }
}
