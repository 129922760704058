/* writer in _common.scss
 p { margin-bottom: 0; }
 .image-responsive {
    width: 100%;
    height: auto;
 */


.header-navigation,
.footer-navigation {
  height: 82px;
  width: 100%;
  background-color: $white;
  -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.08);
  z-index: 42;
  @media #{$screen-lt-md} {
    height: auto;
  }
    @media #{$screen-lt-sm} {
        height: unset;
    }
  .nav-wrapper {
    overflow: hidden;
    background-color: white;
  }
}

.header-navigation,
.footer-navigation {
    .nav-item {
        height: 82px;
        @media #{$screen-lt-md} {
            height: auto;
        }
        .nav-link {
            display: inline-block;
        }
        &:first-child:before {
            content: none;
            width: 0!important;
        }
        &:before {
            content: "" !important;
            position: absolute;
            top: 0;
            width: 1px;
            height: 42px;
            margin: 25px 0;
            background-color: $light-gray;
            @media #{$screen-lt-md} {
                width: 0 !important;
                content: none !important;
            }
        }
        &:hover {
            &:before {
                width: 0;
            }
        }
        &:last-of-type {
            &:after {
                content: none;
                position: absolute;
                top: 0;
                width: 1px;
                height: 42px;
                margin: 25px 0;
                background-color: $light-gray;
                @media #{$screen-lt-md} {
                    content: none;
                }
            }
        }
    }
    .nav-wrapper {
        margin-right: 26px;
    }
}

.header-navigation {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;

  .nav-outer-wrapper {
    margin-right: 26px;
    @media #{$screen-lt-md} {
        display: inline-block;
        position: absolute;
        top: 58px;
        left: -210px;
        float: left;
        width: 210px;
        overflow: hidden;
        transition: all .2s;
      &.toggle-nav-wrapper {
        left: 0;
      }
    }
  }
  div.nav-wrapper {
    margin-right: 0;
    @media #{$screen-lt-md} {
      float: none;
      position: relative;
      top: 0;
      left: 0;
      margin-right: 0;
      transition: all .3s ease-in-out;
      &.navbar-tablet-mode {
        display: block;
      }
    }

      .nav-item {
      @media #{$screen-lt-md} {
        float: none;
        width: 220px;
        padding: 5px 10px;
        &:before {
            width: 0;
          content: none;
        }
        .nav-item-title {
          padding: 0;
          h6 {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .nav-item-icon {
          height: 24px !important;
          padding: 0;
          vertical-align: bottom;
          .nav-icon {
            margin-right: 10px;
          }
        }
        .nav-item-icon,
        .nav-item-title {
          @media #{$screen-lt-md} {
            display: inline-block;
            height: 0;
          }
        }
      }
    }
  }
}
.footer-navigation {
  text-align: center;
  -webkit-box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.08);
    .content {
        @media #{$screen-lt-md} {
            padding: 15px;
        }
    }
    .lang-wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-left: 26px;
        margin-top: 8px;
        @media #{$screen-lt-md} {
            margin-left: unset;
            justify-content: center;
        }
        .mat-form-field {
            @media #{$screen-lt-md} {
                margin-bottom: unset;
            }
        }
    }
    .nav-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        @media #{$screen-lt-sm} {
            flex: 1 1 100%;
            max-width: 100%;
            flex-direction: column;
            padding: 15px 0;
        }
        @media #{$screen-lt-md} {
            flex: 1 1 100%;
            margin-right: unset;
            justify-content: center;
        }
    }
    .nav-item {
        position: relative;
        @media #{$screen-lt-md} {
            height: 82px;
        }
        @media #{$screen-lt-sm} {
            height: 40px;
        }

        &:first-child:before{
            width: 0;
            content: none !important;
        }
        &:before{
            position: absolute;
            content: "" !important;
            left:0;
            display: inline-block;
            width: 1px;
            height: 42px;
            background-color: #cccccc;
        }
        .nav-link {
            position: relative;
            height: 100%;

        }
        .nav-item-title {
            display: flex;
            height: 100%;
            padding: 0 10px;
            h6{
                font-size: 14px!important;
            }
        }
        &:hover {
            background-color: unset;
        }
    }
    @media #{$screen-lt-sm} {
        div.nav-wrapper {
            margin: 0 auto;
        }
        .nav-item .nav-item-title h6 {
            font-size: 10px;
            line-height: 24px;
        }
    }
  @media #{$screen-lt-xxs} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      height: auto;
    div.nav-wrapper {
        //width: 60%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
    }
    .nav-item {
      //width: 34%;
      &:nth-of-type(even):before {
        content: "";
        position: absolute;
        top: 0;
        left: -2px;
        width: 1px;
        height: 15px;
        margin: 3px 0;
        background-color: $light-gray;
      }
      &:last-of-type {
        &:before {
          content: none;
        }
      }
      &:hover {
        &:before,
        & + .nav-item:before {
          width: 0;
        }
      }
        .nav-item-title {
            height: auto;
            min-height: 32px;
            h6 {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
  }
}

.nav-hamburger-button {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  width: 24px;
  margin: 15px 12px 0;
  z-index: 43;
  cursor: pointer;
  div {
    height: 4px;
    background-color: #37bced;
    margin: 5px 0;
    transition: all .3s ease-in-out;
    &:nth-of-type(1) {
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 75%;
    }
    &:nth-of-type(3) {
      width: 50%;
    }
  }
  @media #{$screen-lt-md} {
    display: block;
  }
  &:hover {
    div {
      background-color: $medium-blue;
    }
  }
}

app-user-header {
    .nav-item  {
      @media #{$screen-md} {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
    .nav-become-item {
      @media #{$screen-lt-md} {
          float: unset;
          height: unset;
          padding-top: unset;
          &:hover {
              .nav-become-to-button.center-button {
                  background-color: $light-blue;
                  p { color: $white; }
              }
          }
          .nav-become-to-button.center-button {
              margin: unset;
              max-width: 220px;
              text-align: left;
              padding: 5px 10px;
              p {
                  font-size: 14px;
                  line-height: 14px;
                  color: #37bced;
              }
          }
      &.guest-item {
          width: 260px;
      }
    }
  }
}

.nav-item {
  position: relative;
  float: left;
  &.active-nav-item,
  &:hover {
    @media #{$screen-lt-md} {
      background-color: $light-blue;
    }
    .nav-item-icon {
      color: $white;
      background-color: $light-blue;
    }
    .nav-item-title {
      background-color: $medium-blue;
      h6 {
        color: $white;
      }
    }
    &:before,
    & + .nav-item:before,
    &:last-of-type:after {
      width: 0;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 1px;
    height: 15px;
    margin: 3px 0;
    background-color: $light-gray;
    @media #{$screen-lt-md} {
      content: none;
    }
  }
  &:after {
    right: 0;
  }
  &:before {
    left: 0;
  }
  div {
    text-align: center;
  }
  .nav-item-icon {
    display: flex;
    height: 52px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $light-blue;
    a {display: block;}
    img {
      width: 36px;
      height: auto;
    }
  }
  .nav-item-title {
    display: flex;
    height: 30px;
    padding: 4px 10px;
    flex-direction: row;
    align-items: center;
    h6 {
      margin: 0;
      color: $blue-gray;
      @media #{$screen-gt-md} {
        font-size: 14px;
        line-height: 14px;
      }
      @media #{$screen-btw-1280px-1625px} {
        font-size: 14px;
        line-height: 14px;
      }
      @media #{$screen-lt-lg} {
        font-size: 9px;
        line-height: 24px;
      }
      @media #{$screen-lt-sm} {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

.cancel_before:before {
  display: none;
}

.nav-become-item {
  float: right;
  height: 82px;
  padding-top: 27px;
    text-align: center;
  @media #{$screen-btw-1280px-1625px} {

  }
  @media #{$screen-lt-md} {
  }
  .nav-become-to-button {
    display: inline-block;
    padding: 5px 17px;
    border-radius: 20px;
    color: $white;
    cursor: pointer;
    outline: none;
    &:hover {
      opacity: .8;
    }
    transition: opacity .2s ease;
    @media #{$screen-lt-md} {
      padding: 5px 0 5px 10px;
      display: inline-block;
      border-radius: 0;
      color: $white;
    }
    &.left-button {
      position: relative;
      padding-right: 40px;
      background-color: $light-blue;
      @media #{$screen-lt-md} {
        position: static;
        padding-right: 0;
        background-color: $white;
      }
    }
  &.center-button {
      margin: 0 10px;
      background-color: $light-blue;
      text-align: center;
      @media #{$screen-lt-md} {
          position: static;
          padding-right: 0;
          background-color: $white;
      }
  }
    &.right-button {
      position: absolute;
      margin-left: -30px;
      background-color: $medium-blue;
      @media #{$screen-lt-md} {
        position: static;
        margin-left: 0;
        background-color: $white;
      }
    }
    p {
      margin: 0;
      font-size: 15px;
      cursor: pointer;
      @media #{$screen-lt-lg} {
        font-size: 12px;
      }
      @media #{$screen-lt-md} {
        font-size: 19px;
        line-height: 19px;
        color: $blue-gray;
      }
    }
  }
}
.nav-icon {
  width: auto;
  font-size: 28px;
  @media #{$screen-lt-md} {
    font-size: 22px;
  }
}
.guest-version-header-logo{
  width: 90px;
  margin: 8px 16px;
  @media #{$screen-lt-md} {
    display: none;
  }
}
.mobile-version-header-logo {
  display: none;
  width: 90px;
  margin: 8px auto;
  cursor: pointer;
  outline: none;
  @media #{$screen-lt-md} {
    display: block;
    width: 36px;
    margin: 11px auto 0;
    height: auto;
    text-align: center;
  }
  .user-panel-logo {
    width: 100%;
    height: auto;
  }
  .user-panel-logo-simple {
    width: 42px;
    height: auto;
  }
}
app-user-panel {
    @media #{$screen-lt-md} {
        width: 100%;
        position: absolute;
    }
}
.user-panel {
  display: inline-block;
  width: 210px;
  margin-left: 26px;
  border: solid 1px $pacific-gray;
  background-color: $white;
  border-radius: 8px;
  @media #{$screen-md} {
    width: 180px;
  }
  @media #{$screen-lt-md} {
    position: relative;
    width: auto;
    height: auto;
    border: none;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 0;
    float: right;
    z-index: 1;
  }
}


.logo-wrapper {
  margin: 12px 20px 0;
  height: 86px;
  text-align: center;
  cursor: pointer;
  outline: none;
  img{
    height: 86px;
    width: auto;
  }
  &.mobile-version {
    display: none !important;
    @media #{$screen-lt-md} {
      display: block;
    }
  }
  @media #{$screen-lt-md} {
    display: none;
  }
}
.user-panel-logo {
  width: 100%;
  height: auto;
}
.user-panel-avatar-container {
  width: auto;
  .dropdown-arrow-wrapper {
    display: none;
    @media #{$screen-lt-md}{
      display: block;
      position: absolute;
      top: 5px;
      left: 6px;
    }
  }
  &.small-panel {
    position: relative;
    width: 96px;
    height: auto;
    margin: 0 auto;
    @media #{$screen-lt-md} {
      width: 70px;
      height: auto;
    }
    .dropdown-arrow-wrapper {
      display: block;
      position: absolute;
      top: 12px;
      right: 5px;
    }
    .user-avatar-picture {
      width: 36px;
      height: auto;
      margin: 3px;
    }
  }
  @media #{$screen-lt-md} {
    position: relative;
    width: 60px;
    height: auto;
  }
}
.user-avatar-wrapper {
  position: relative;
  width: 81px;
  height: 81px;
  margin: 13px auto 13px;
  &.small-panel {
    width: 40px;
    height: 40px;
    margin: 4px auto 4px;
  }
  @media #{$screen-lt-md} {
    width: 29px;
    height: 29px;
    margin: 4px 4px 4px 20px;
  }
}
.user-avatar {
  overflow: hidden;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}
.user-avatar-circle {
  position: relative;
  width: 81px;
  height: 81px;
  &.small-panel {
    width: 40px;
    height: 40px;
  }
  @media #{$screen-lt-md} {
    width: 29px;
    height: 29px;
  }
}

.user-avatar-picture {
  position: relative;
  width: 100%;
  height: auto;
  &.no-image-big-size {
    width: 80%;
    top: 10px;
    left: 9px;
    @media #{$screen-lt-md} {
      top: 7px;
      left: 5px;
    }
  }
  &.no-image-small-size {
    width: 80%;
    // bottom: -4px;
    // left: 4px;
  }
}
.language-flag-wrapper {
  position: absolute;
  left: 0;
  bottom: -7px;
  height: 26px;
  width: 26px;
  border-radius: 25px;
  &.small-panel {
    display: none;
  }
  @media #{$screen-lt-md} {
    display: none;
  }
}
.language-flag {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 3px solid $white;
}
.username-wrapper {
  margin: 6px auto 0;
  padding: 0 10px;
  text-align: center;
  &.small-panel {
    margin: 0 auto;
    p {
      margin-bottom: 0;
      font-size: 11px;
      line-height: 11px;
    }
    h6 {
      font-size: 14px;
      line-height: 14px;
    }
  }
  @media #{$screen-lt-md} {
    display: none;
    margin: 0 auto;
  }
  h6 {
    margin-bottom: 4px;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    @media #{$screen-lt-md} {
      font-size: 14px;
      line-height: 14px;
    }
  }
  p {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 16px;
    color: $medium-gray;
    @media #{$screen-lt-md} {
      margin-bottom: 0;
      font-size: 11px;
      line-height: 11px;
    }
  }
}
.footer-field {
  margin: 18px auto;
  text-align: center;
  &.small-panel {
    display: none;
    &.visible-actions-panel {
      display: block;
    }
  }
  @media #{$screen-lt-md} {
    margin: 18px auto 0;
    display: none;
    &.visible-actions-panel {
      display: block;
    }
  }
  .user-panel-icon {
    margin: 0 4px;
    font-size: 20px;
    color: $light-blue;
    cursor: pointer;
  }
}
