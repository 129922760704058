/* Cryptozone icons fonts */
@include font-face('cryptozone', 'cryptozone', 'cryptozone_icons', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaMedium', 'HelveticaMedium', 'HelveticaMedium', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaBold', 'HelveticaBold', 'HelveticaBold', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaLight', 'HelveticaLight', 'HelveticaLight', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaRegular', 'HelveticaRegular', 'HelveticaRegular', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaBlack', 'HelveticaBlack', 'HelveticaBlack', $fonts-source-path, normal, normal, false);
@include font-face('HelveticaHeavy', 'HelveticaHeavy', 'HelveticaHeavy', $fonts-source-path, normal, normal, false);
@include font-face('AileronRegular', 'AileronRegular', 'AileronRegular', $fonts-source-path, normal, normal, false);
@include font-face('AileronSemiBold', 'AileronSemiBold', 'AileronSemiBold', $fonts-source-path, normal, normal, false);
@include font-face('AileronBlack', 'AileronBlack', 'AileronBlack', $fonts-source-path, normal, normal, false);
@include font-face('helveticaneue', 'HelveticaNeuecyr', 'helveticaneuecyr-light', $fonts-source-path, 300, normal, true);
@include font-face('helveticaneue', 'HelveticaNeuecyr', 'helveticaneuecyr-medium', $fonts-source-path, 500, normal, true);
@include font-face('helveticaneue', 'HelveticaNeuecyr', 'helveticaneuecyr-roman', $fonts-source-path, normal, normal, true);
@include font-face('helveticaneue', 'HelveticaNeuecyr', 'helveticaneuecyr-bold', $fonts-source-path, 700, normal, true);
@include font-face('helveticaneue', 'HelveticaNeuecyr', 'helveticaneuecyr-bold', $fonts-source-path, 700, normal, true);

@include font-face('AvenirBlack', 'AvenirBlack', 'AvenirLTStdBlack', $fonts-source-path, 700, normal, false);
@include font-face('AvenirBook', 'AvenirBook', 'AvenirLTStdBook', $fonts-source-path, 700, normal, false);
@include font-face('AvenirRoman', 'AvenirRoman', 'AvenirLTStdRoman', $fonts-source-path, 400, normal, false);
