app-affiliate-marketplace {
    width: 100%;
}

.partners-program{
    .title{
        width: 100%;
        padding: 10px 0;
        font-size: 23px;
        border-bottom: 1px #cccccc solid;
        margin-bottom: 30px;
    }
    .formprofile-input{
        position: relative;
        button{
            position: absolute;
            right: -5px;
        }

        input{
            padding-right: 40px;
        }
    }


}
