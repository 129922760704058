/* Dialogs common styles */

.receive-dialog-mark {
  margin-top: 1px;
  margin-right: 10px;
  width: 39px;
  height: 19px;
  padding-top: 2px;
  text-align: center;
  font-size: 16px;
  background-color: #ffc600;
}
// dialog title, close and info icons
.mat-dialog-title {
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid black;
  .icon-button {
    width: 19px;
    height: 19px;
    cursor: pointer;
    background-repeat: no-repeat;
  }
  .info-button {
    width: 20px;
    height: 20px;
    /*background: white url($dialog-info-button);*/
  }
  .close-button {
    /*background-image: url($dialog-close-button);*/
  }
}
// action buttons
.btn-currency {
  &.dialog-button {
    padding: 0 47px;
    font-size: 16px;
    @media #{$screen-lte-767px} {
      display: block;
      width: 256px;
      margin: 0 auto 18px;
      padding: 0 15px;
    }
  }
  &.small-dialog-button {
    padding: 0 20px;
    @media #{$screen-lt-sm} {
      padding: 0;
    }
  }
  &.no-padding {
    padding: 0;
  }
}

.btn-gray {
  background-color: #6f7a83;
  &:hover {
    background-color: #586067;
  }
}

// info button link
a {
  &.send-link {
    color: rgb(40, 60, 90);
    cursor: pointer;
    padding-left:10px;
    &:hover {
      color: rgb(40, 60, 90) !important;
    }
    &:focus {
      outline: none;
    }
  }
}
// header text and info link
.dialog-header-text {
  margin: 0 15px 0 0;
  font-size: 24px;
  font-weight: bold;
  font-family: "HelveticaBold", sans-serif;
  @media #{$screen-xs} {
    font-size: 16px;
  }
  a {
    &.send-link {
      height: 0;
      .info-button {
        vertical-align: middle;
      }
    }
  }
}
// helper text
.help-text-wrapper {
  width: 100%;
  p {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: "HelveticaMedium", sans-serif;
    color: #6f7a83;
  }
}
// non-resizable textarea
textarea {
  &.mat-input-element {
    &.non-resize-and-scroll {
      resize:none;
      overflow-y: hidden;
    }
    &.non-resizable-but-scroll {
      resize:none;
    }
  }
}
// actions field spinner
.mat-dialog-actions {
  .button-spinner {
    width: 20px;
    height: 20px;
    @media #{$screen-lt-sm} {
      width: 12px;
      height: 12px;
    }
  }
}

.mat-dialog-container {
  position: relative;
}

.sendLoading{
  display: flex;
  max-height: 100%;
  place-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  margin-left: -24px;
  margin-top: -16px;
  background-color: #cccccc40;
}

/* Receive dialog styles */
// Receive dialog mode buttons,labels
.receive-mode-wrapper {
  position: relative;
  padding-right: 195px;
  span {
    font-size: 16px;
    font-family: "HelveticaBold", sans-serif;
  }
  @media #{$screen-lt-sm} {
    padding-right: 0;
  }
  .receive-mode {
    span,
    label{
      font-size: 16px;
      font-family: "HelveticaMedium", sans-serif;
      @media #{$screen-lte-349px} {
        font-size: 11px;
      }
      &.label-left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.label-right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
.receive-mode {
  width: 232px;
  height: 22px;
  margin-top: 3px;
  margin-bottom: 15px;
  font-size: 0;
  @media #{$screen-lte-349px} {
    width: 100%;
  }
}
// radio button and it's sibling label
.radio {
  width: 17px;
  height: 17px;
  margin: 0 3px 0 0;
  vertical-align: top;
  + label {
    cursor: pointer;
  }
  &:not(checked) {
    position: absolute;
    opacity: 0;
  }
  &:not(checked) + label {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 22px;
    border: 1px solid #cad3d9;
    line-height: 22px;
    color: #6f7a83;
    text-align: center;
  }
  &:checked + label {
    color: #fff;
    background-color: #36987e;
    border-color: #36987e;
  }
}
// small text for action buttons
.btn-currency {
  &.small-text {
    @media #{$screen-lt-sm} {
      font-size: 12px;
    }
  }
}

/* Send confirmation */
.confirm-sending-data,
.confirm-sending-data span {
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.54);
  @media #{$screen-lt-sm} {
    font-size: .6rem;
  }
}

.confirm-sending-text {
  font-size: .8rem;
  font-family: "HelveticaMedium", sans-serif;
  color: #6f7a83;
}

/* QR component */
qr-code img {
  object-fit: contain;
}

