.subscribe-info{
    margin: 18px 22px 5px 22px;
    >div{
        padding: 10px;
        font-size: 16px;
    }
    >div:nth-child(odd) {
        background-color: #f7f7f7;
        border-radius: 8px;
    }
}
