.merchant-documents-uploading {
    .product-uploaded-images-list-container,
    .product-download-image-wrapper {
        justify-content: flex-start;
    }
}
button {
    .become-merchant-button {
        padding: 8px 24px;
        border-radius: 4px;
    }
}

.lang-selector-box {
    .trigger-box {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .circle-flag {
        width:25px;
        height:25px;
        object-fit: cover;
    }
    .title-name {
        margin-left: 8px;
        font-size: 16px;
        line-height: 16px;
        color: #a5a5a5;
    }
    .mat-form-field {
        border-radius: 28px;
        border: 1px solid #c9c9c982;
        &.mat-focused.mat-primary {
            .mat-select-arrow {
                color: #a5a5a5 !important;
            }
        }
    }
    .mat-form-field-wrapper {
        width: auto;
        height: 37px !important;
        padding: 0 5px !important;
        display: flex;
        align-items: center;
    }
    .mat-form-field-appearance-legacy  {
        .mat-form-field-infix {
            display: flex;
            flex-flow: row nowrap;
            padding: 0 .4em 0 .1em;
            border-top: 0;
            justify-content: space-between;
            align-items: center;
        }
    }
    .mat-select-arrow-wrapper {
        .mat-select-arrow {
            color: #a5a5a5;
        }
    }
    .mat-select {
        // width: auto;
    }
}

.langs-select-panel {
    .circle-flag {
        width:25px;
        height:25px;
        object-fit: cover;
    }
    .text-name {
        margin-left: 8px;
    }
}
