/* Material button style */

$button-colors: (
    scared-red: $scared-red,
    ink-blue: $inkBlue,
    medium-blue: $medium-blue,
    light-blue: $light-blue,
    dark-green: $dark-green,
    sallad-green: $sallad-green,
    white-blue: $white-blue
);

button, .button
// [class~="button"] // all elements with class-name containing "button"
{
    display: inline-block !important;
    border: none !important;
    color: $white !important;
    outline: none !important;
    cursor: pointer !important;
    text-align: center !important;
    &.main {
        padding: 16px 24px !important;
        font-size: 14px !important;
        border-radius: 6px;
        line-height: 1em;
        transition: all .3s ease;
        &.small-padding {
            padding: 16px 10px !important;
        }
        &.small-button {
            padding: 6px 10px !important;
            font-size: 12px !important;
        }
        &.full-size {
            width: 100%;
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: center;

            &.coupon-table-btn {
                width: auto;
                padding-left: 35px!important;
                padding-right: 35px!important;
            }
        }
        @media #{$screen-lt-lg} {
            font-size: 12px !important;
            padding: 14px 22px !important;
        }
        &:disabled,
        &[disabled] {
            opacity: .4;
        }
    }
    &.tablet-full {
        @media #{$screen-lt-sm} {
            width: 100% !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: center;
        }
    }
    &.mobile-full {
        @media #{$screen-lt-xxs} {
            width: 100% !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: center;
        }
    }
    &.full-sized {
        width: 100%;
        padding: 16px 0;
    }
    /*
        class-name for border radius which gets
        values in range from 1 to 20 (ex. class="border-radius-4")
    */
    @for $i from 1 through 20 {
        &.border-radius-#{i} {
            font-size: #{i}px !important;
        }
    }

    /*
        class-name for button color which gets
        values from $button-colors map (ex. class="button-dark-green")
    */
    @each $colorname, $color in $button-colors {
        &.button-#{$colorname} {
            @include background-mixin($color, darken, 10%);
        }
    }
    &.order-list-action {
        padding-right: 6px !important;
        padding-left: 6px !important;
    }
}

.button-go {
    font-weight: bold;
    text-transform: uppercase;
}

[class^="button-"], [class*=" button-"]{
  color: $white;
  display: inline-block;
  padding: 7px 16px;
  cursor: pointer;
  text-align: center;
  &:hover{ opacity: 0.8; }
  &.full-width{ width: 100%; }
  &.shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  //@media only screen and (max-width: 1380px){
  //  font-size: 11px;
  //}
    @media #{$screen-lte-1324px} {
        font-size: 11px;
    }
}

.video-tutorial-button {
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.quiz-button {
  display: inline-block;
  margin-bottom: 10px;
  padding: 7px 14px;
  font-size: 14px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 14px;
  font-weight: 600;
    &.quiz-second-btn {
        padding-left: 4px;
        padding-right: 4px;
    }

}

.icon-close {
    color: $blue-gray;
}

.upload-btn {
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.btn-green {
    opacity: 1!important;
}

.product-btn {
    max-width: 100px;
    button {
        padding: 16px 12px !important;
    }
}


