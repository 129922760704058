/* Material dialog style rewriting start */

.market-main-theme {
  .mat-dialog-container {
    padding: 20px !important;
    border-radius: 8px !important;
  }
  .cdk-overlay-pane {
    //width: auto !important;
    height: auto !important;
    /*@media #{$screen-lt-md} {
      width: 80% !important;
    }*/
  }
    mat-progress-bar {
        border-radius: 2px;
        .mat-progress-bar-fill::after {
            background-color: #37bced;
        }
        .mat-progress-bar-buffer {
            background: #ccf4fe;
        }
    }
}
.mat-dialog-title-wrapper {
  height: 40px;
  .mat-dialog-title {
    margin: 0;
    padding-bottom: 18px;
    border-bottom: 1px solid $light-gray;
    font-size: 20px;
    line-height: 20px;
    color: $light-blue;
  }
}
.dialog-actions-container {
  height: 50px;
}

/* Material dialog style rewriting end */

mat-form-field {
  mat-select.mat-select {
    cursor: pointer !important;
  }
  input.mat-input-element,
  textarea.mat-input-element {
    color: #8c95a2 !important;
    caret-color: #37bced !important;
  }
  .mat-select-trigger {
    height: auto !important;
  }
  &.search-field {
    background-color: $main-back-gray !important;
  }
  &.select-field {
    border: 1px solid $border-label-gray;
  }
  .mat-focused.mat-primary {
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $black;
      }
    }
  }
  .mat-select-arrow-wrapper {
    .mat-select-arrow {
      color: $black;
    }
  }
  &.mat-form-field-can-float {
    &.mat-form-field-should-float {
      .mat-form-field-label {
        display: none;
      }
    }
  }
  &.mat-focused {
    .mat-form-field-label {
      display: block; // changed display none value

        .mat-placeholder-required{
            color: $error-red;
        }
    }
  }
  .mat-chip-list-wrapper {
      margin: 0 0 1.2em 0 !important;
  }

    .mat-select {
      span.mat-select-placeholder {
        font-family: AvenirRoman, sans-serif !important;
        font-size: 10px !important;
        font-weight: bold;
        line-height: 10px !important;
      }
    }

  // themes for mat-form input-fields
  &.light-gray-form-field {
    label.mat-form-field-label {
      color: #8c95a2 !important;
    }
    /*input.mat-input-element {
      color: $light-gray !important;
      caret-color: $light-gray !important;
    }*/
    .mat-focused {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $light-gray !important;
        }
      }
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $light-gray !important;
      }
    }
    .mat-select-placeholder {
      color: transparent !important;
    }
  }

  &.light-white-form-field {
    background-color: transparent !important;
    label.mat-form-field-label {
      color: $white !important;
    }
    /*input.mat-input-element {
      color: $light-gray !important;
      caret-color: $light-gray !important;
    }*/
    .mat-focused {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $white !important;
        }
      }
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $white !important;
      }
    }
    .mat-select-placeholder {
      color: transparent !important;
    }
    input.mat-input-element,
    textarea.mat-input-element {
      color: $white !important;
      caret-color: $white !important;
    }
  }

  &.light-blue-form-field {
    &.white-background {
      transition: unset;
      background-color: $white !important;
        .mat-select-value {
            span {
                color: $light-blue;
            }
        }
        .mat-select-arrow-wrapper {
            .mat-select-arrow {
                color: $light-blue !important;
            }
        }
        input.mat-input-element {
            color: #8c95a2 !important;
            caret-color: #8c95a2 !important;
        }
      &:hover {
        background-color: $white !important;
        label.mat-form-field-label {
          //color: $white !important;
        }
        /*input.mat-input-element {
             color: $light-gray !important;
             caret-color: $light-gray !important;
         }*/
        .mat-focused {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: $light-blue !important;
            }
          }
        }
        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $light-blue !important;
          }
        }
        .mat-select-value {
          span {
            color: $light-blue !important;
          }
        }
      }
    }
    &.blue-background {
      background-color: $light-blue !important;
      &:hover {
          background-color: $inkBlue !important;
          label.mat-form-field-label {
              color: $white !important;
          }
          .mat-focused {
              .mat-select-arrow-wrapper {
                  .mat-select-arrow {
                      color: $white !important;
                  }
              }
          }
          .mat-select-arrow-wrapper {
              .mat-select-arrow {
                  color: $white !important;
              }
          }
          .mat-select-value {
              span {
                  color: $white;
              }
          }
      }
        label.mat-form-field-label {
            color: $white !important;
        }
        input.mat-input-element {
            color: $white !important;
            caret-color: $white !important;
        }
        .mat-focused {
            .mat-select-arrow-wrapper {
                .mat-select-arrow {
                    color: $light-blue !important;
                }
            }
        }
    }
    label.mat-form-field-label {
      color: $light-blue !important;
    }
    input.mat-input-element {
      color: $light-blue !important;
      caret-color: $light-blue !important;
    }
    .mat-focused {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $white !important;
        }
      }
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $white !important;
      }
    }
    .mat-select-value {
      span {
        color: $white;
      }
    }
    .mat-select-placeholder {
      color: transparent !important;
    }
  }

  &.ink-blue-form-field {
    &.white-background {
      &.product-currency-dropdown {
        border: 1px solid $inkBlue !important;
      }
      background-color: $white !important;
      transition: border .4s ease-in-out;
      /*&:hover {
        border: 1px solid $inkBlue;
      }*/
        label.mat-form-field-label {
          color: $inkBlue !important;
        }
        /*input.mat-input-element {
          color: $white !important;
          caret-color: $white !important;
        }*/
        .mat-focused {
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: $inkBlue !important;
            }
          }
        }
        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $inkBlue !important;
          }
        }
        .mat-select-value {
          span {
            color: $inkBlue;
          }
        }
    }
    label.mat-form-field-label {
      color: $inkBlue !important;
    }
    input.mat-input-element {
      color: $inkBlue !important;
      caret-color: $inkBlue !important;
    }
    .mat-focused {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $inkBlue !important;
        }
      }
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $inkBlue !important;
      }
    }
    .mat-select-value {
      span {
        color: $inkBlue;
      }
    }
    .mat-select-placeholder {
      color: transparent !important;
    }
  }

  &.night-blue-form-field {
    label.mat-form-field-label {
      color: $night-blue !important;
    }
    input.mat-input-element {
      color: $night-blue !important;
      caret-color: $night-blue !important;
    }
    .mat-focused {
      .mat-select-arrow-wrapper {
        .mat-select-arrow {
          color: $night-blue !important;
        }
      }
    }
    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        color: $night-blue !important;
      }
    }
    .mat-select-placeholder {
      color: transparent !important;
    }
  }

  /* themes for mat-form date-picker-fields */

  mat-datepicker-toggle.mat-datepicker-toggle {
    .mat-datepicker-toggle-default-icon {
      color: #a1a1a1 !important;
    }
      .mat-icon-button.cdk-focused {
        .mat-button-focus-overlay {
          background-color: transparent !important;
        }
      }
  }

  // light-blue theme for mat-datepicker

  &.light-blue-toggle-datepicker {
    mat-datepicker-toggle.mat-datepicker-toggle {
      .mat-icon.material-icons {
        color: $light-blue !important;
      }
    }
  }

  // dark-green theme for mat-datepicker

  &.dark-green-toggle-datepicker {
    mat-datepicker-toggle.mat-datepicker-toggle {
      .mat-icon.material-icons {
        color: $light-blue !important;
      }
    }
  }


  &.ink-background-datepicker {
    mat-datepicker-toggle.mat-datepicker-toggle {
      .mat-datepicker-toggle-default-icon {
        color: $white !important;
      }
    }
    /*&:hover {
      mat-datepicker-toggle.mat-datepicker-toggle {
        .mat-datepicker-toggle-default-icon {
          color: $inkBlue !important;
        }
      }
    }*/
  }

  /* material chip list, list-items and input */

  mat-chip-list {
    mat-chip {
      &.mat-chip,
      &.mat-chip:not(.mat-basic-chip) {
        display: inline-flex;
        margin-bottom: 8px !important;
        margin-left: 8px !important;
        padding: 8px 16px;
        border-radius: 4px !important;
        font-size: 13px;
        line-height: 16px;
        background-color: white !important;
        color: $inkBlue !important;
        @include box-shadow(0 6px 8px 0 rgba(0, 0, 0, 0.08));
        /*mat-icon.mat-icon {
          .mat-chip-remove {
            color: white;
            font-size: 21px !important;
            &.mat-icon {

            }
          }
        }*/
        span.mat-chip-close-icon.mat-chip-remove {
            width: 13px;
            height: 13px;
          margin-left: 8px !important;
          color: $scared-red !important;
          opacity: 1 !important;
          &:hover {
            opacity: .5 !important;
          }
        }
      }
    }
    input.mat-chip-input {
      flex: 1 0 5px;
      min-width: 30px;
      margin: 8px 3px;
    }
  }

}
/*
.selection-tabs-dropdown-container {
  @media #{$screen-lt-md} {
    position: absolute;
    top: 0;
    left: 30px;
    width: 100%;
  }
}*/

.mat-accent.mat-radio-checked{
  .mat-radio-outer-circle{
    border-color: $white-blue !important;
  }

  .mat-radio-inner-circle{
    background-color: $white-blue !important;
  }

}

.mat-dialog-content {
  margin:0 !important;
}
/* Angular-2-multiselect chiplist start */


.multiselect-dropdown {
  .c-token{
    display: inline-flex !important;
    margin: 4px 0 0 8px;
    padding: 8px 25px 8px 8px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    line-height: 16px !important;
    background-color: $white !important;
    color: $inkBlue !important;
    -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.08) !important;
  }
  .c-btn{
    display: inline-block !important;
    position: relative !important;
    height: auto;
    min-height: 46px !important;
    padding: 12px 15px !important;
    border: none !important;
    cursor: pointer !important;
    box-shadow: 0 0 0 #959595 !important;
  }
  .c-remove {
    position: absolute !important;
    top: 45% !important;
    width: 13px;
    height: 13px;
    margin-left: 8px !important;
    color: $scared-red !important;
    opacity: 1 !important;
      &:hover {
          opacity: .5 !important;
      }
    &:before {
      content: "\e921";
      font-family: cryptozone, sans-serif !important;
    }
    svg{
        display: none !important;
    }
  }
  .c-angle-down,
  .c-angle-up {
    width: 0 !important;
    height: 0 !important;
    margin: 0 4px !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    color: $black !important;
    svg {
      display: none !important;
    }
  }
  .c-angle-down {
    border-top: 5px solid !important;
  }
  .c-angle-up {
    border-bottom: 5px solid !important;
  }
  .pure-checkbox {
    label:before {
        width: 20px !important;
        height: 20px !important;
        border: none !important;
        border-radius: 2px !important;
        background: $light-gray !important;
    }
    input:checked + {
        label:before {
            background: $checked-green !important;
        }
    }
  }

  // inkBlue theme start
  &.ink-blue-background-select {
    .c-btn {
      background-color: $date-btn-gray !important;
    }
    .c-angle-down,
    .c-angle-up {
        color: $inkBlue !important;
    }
    .ng-star-inserted {
      &.c-label .ng-star-inserted {
        color: $black !important;
      }
    }
      .ng-star-inserted {
          color: $inkBlue !important;
      }
      .dropdown-list {
          label {
              color: $inkBlue !important;
          }
      }
  }
  // inkBlue theme end
}

/* Angular-2-multiselect chiplist end */


.mat-form-field {
    &.disable-infix-width {
        .mat-form-field-infix {
            width: unset !important;
        }
    }
    .mat-select {
        span.mat-select-placeholder {
            line-height: 14px !important ;
        }
    }
}

.mat-form-field-placeholder{
    height: 20px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: .2em 0;
}

.mat-button {
    background: unset !important;
    min-width: unset !important;

    &:hover, & {
        .mat-button-focus-overlay {
            display: none !important;
        }
    }
}

.mat-paginator-icon {
    fill: rgba(0, 0, 0, 0.54) !important;
}

mat-form-field.ng-touched{
    .mat-placeholder-required{
        color: $error-red;
    }
}
.mat-radio-group {
    &.rectangle-radio-group {
        .mat-radio-button {
            margin: 0 !important;
            border-radius: 0;
            border-top: 1px solid #c9c9c982;
            border-right: 1px solid #c9c9c982;
            border-bottom: 1px solid #c9c9c982;
            border-left: none;
            .mat-radio-container {
                display: none;
            }
            .mat-radio-label-content {
                padding: 10px 14px;
                line-height: 1;
                text-transform: uppercase;
            }
            &.mat-radio-checked,
            &.sdk-focused,
            &.sdk-mouse-focused {
                background-color: #1c92be;
                color: #ffffff;
            }
            &:first-child {
                -webkit-border-radius: 4px 0 0 4px;
                -moz-border-radius: 4px 0 0 4px;
                border-radius: 4px 0 0 4px;
                border-left: 1px solid #c9c9c982 !important;
                .mat-radio-label-content {
                }
            }
            &:last-of-type {
                -webkit-border-radius: 0 4px 4px 0;
                -moz-border-radius: 0 4px 4px 0;
                border-radius: 0 4px 4px 0;
                border-right: 1px solid #c9c9c982;
                .mat-radio-label-content {
                }
            }
        }
    }
}
