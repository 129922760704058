/*   Correct styles on different browser.   */

/* 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS. */
html {
  top: 0 !important;
  line-height: 1.15;              /* 1 */
  -ms-text-size-adjust: 100%;     /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Add the correct display in IE 9-. */
main,
footer,
header,
nav,
section {
  display: block;
}

/* 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE. */
hr {
  box-sizing: content-box;  /* 1 */
  height: 0;                /* 1 */
  overflow: visible;        /* 2 */
}

/* 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+. */
a {
  color: inherit;
  outline: none;
  background-color: transparent;          /* 1 */
  -webkit-text-decoration-skip: objects;  /* 2 */
}

/* Prevent the duplicate application of `bolder` by the next rule in Safari 6. */
strong {
  font-weight: inherit;
}

/* Add the correct font weight in Chrome, Edge, and Safari. */
strong {
  font-weight: bolder;
}

/* Add the correct background and color in IE 9-. */
mark {
  background-color: #ff0;
  color: #000;
}

/* Add the correct font size in all browsers. */
small {
  font-size: 80%;
}

/* Remove the border on images inside links in IE 10-. */
img {
  border-style: none;
}

/* 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari. */
button,
input,
textarea {
  margin: 0;                /* 2 */
  line-height: 1.15;        /* 1 */
  font-family: sans-serif;  /* 1 */
  font-size: 100%;          /* 1 */
}

/* Show the overflow in IE and Edge. */
button,
input {
  overflow: visible;
}

/* Remove the inheritance of text transform in Edge, Firefox, IE and Firefox. */
button,
select {
  text-transform: none;
}

/* Correct the inability to style clickable types in iOS and Safari. */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/* Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Remove the default vertical scrollbar in IE. */
textarea {
  overflow: auto;
}

/* 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;  /* 1 */
  padding: 0;              /* 2 */
}

/* Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/* 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield;  /* 1 */
  outline-offset: -2px;           /* 2 */
}

/* Remove the inner padding and cancel buttons in Chrome and Safari on macOS. */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button;   /* 1 */
  font: inherit;                /* 2 */
}

/* Add the correct display in IE 9-. */
canvas {
  display: inline-block;
}

/* Add the correct display in IE 10-. */
[hidden] {
  display: none;
}
