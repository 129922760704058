.votes-top-background {
    .background-wrapper {
        position: relative;
        height: 250px;
        background-image: linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9) ), url($vote-background);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .heading-vote-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: $white-blue;
        h4 {
            font-size: 90px !important;
            line-height: 64px;
            color: $white-blue;
        }
        span {color: $white;}
    }
}

.votes-purpose-background {
    .background-wrapper {
        min-height: 250px;
        background-color: $white;
    }
    .heading-purpose-title {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: $black;
    }
    .heading-purpose-text {
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        padding-top: 10px;
        color: $light-gray;
    }
    .image-background {
        border-radius: 50em;
        color: #9ecff7;
        position: relative;
        width: 60px;
        height: 60px;
    }
    .image-container {
        img {
            width: 100%;
            height: auto;
        }
        
    }
    
    .vote-icon-wrapper {
        position: relative;
        margin: 13px auto;
    }
    .vote-icon {
        width: auto;
        overflow: hidden;
        text-align: center;
        border-radius: 40px;
        background-color: $vote-icon-back;
    }
    .vote-icon-circle {
        position: relative;
        width: 60px;
        height: 60px;
        line-height: 80px;
    }
    
    .vote-icon-image {
        position: relative;
        width: 50%;
        height: auto;
        background: #eaedff;
    }
    
}

.vote-currency-container {
    max-width: 680px !important;
    .vote-how-to {
        padding: 70px 0 70px 0 !important;
        .title {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            color: $black;
        }
        .text-center {
            text-align: center;
            padding-top: 12px;
            font-size: 10px;
            font-weight: bold;
            color: $light-gray;
        }
    }
    .table-header {
        & > div {
            padding: 12px 0;
            font-size: 10px;
            font-weight: bold;
            color: $white-blue;
            word-wrap: break-word;
            /*@media #{$screen-lt-lg} {
                font-size: 11px;
            }*/
        }
    }
    .table-body{
        & > div {
            div:nth-child(2){
                border-radius: 8px 0 0 8px;
            }
            > div {
                padding: 8px 0;
                font-size: 10px;
                color: $black;
                font-weight: bold;
                word-wrap: break-word;
                /*@media #{only screen and (max-width 1490px)} {
                    font-size: 11px !important;
                }*/
            }
            &:nth-child(odd){
                > div{
                    background-color: $main-back-gray;
                }
                > div:last-child{
                    border-radius: 0 8px 8px 0;
                }
            }
            .vertical-header{
                color: $light-blue;
                background-color: $white !important;
            }
        }
    }
    .voted {
        text-align: center;
        color: $dark-green;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        margin: 8px 0;
    }
}

.vote-button {
    font-size: 10px !important;
    padding: 10px 0 !important;
}


.currencies-row {
    .arrow-button-wrapper {
        position: static;
        @media #{$screen-lt-sm} {
            position: fixed;
            top: calc(50% - 32px);
            &.left {left: 5px;}
            &.right {right: 5px;}
        }
    }
    .arrow-button {
        @include background-mixin($dark-green, darken, 10%);
        @media #{$screen-lt-sm} {
            margin: unset;
        }
    }
    .arrow-button.left-arrow {
        margin-right: 40px;
    }
    .arrow-button.right-arrow {
        margin-left: 40px;
    }
}

