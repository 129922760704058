.mat-dialog-container-info{
  .text{
    text-align: center;
    font-size: 17px;
    margin-bottom: 15px;
  }

  .buttons{
    text-align: center;
  }
}

.trans-history-header{
  >div{
    text-align: right;
  }
  >div:first-child{
    text-align: left;
  }
}

.trans-history-row{
  >div{
    font-size: 12px !important;
    text-align: right;
  }
  >div:first-child{
    text-align: left;
    padding-left: 10px !important;
  }
  >div:last-child{
    padding-right: 10px !important;
  }
}

/* Imgae uploader suctom styling start */

.image-upload-dialog-container,
.common-dialog-container {
  width: 100%;
  height: 100%;
  min-height: 300px !important;
    &.no-min-height {
        min-height: unset !important;
    }
}
.add-image-dialog-wrapper,
.common-dialog-wrapper {
  .dialog-actions-container {
    button.button {
      padding: 14px 28px;
    }
  }
}
.uploaded-images-list-container {
  min-height: 100px;
  .uploaded-image-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 10px;
    border-radius: 8px;
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
  }
  .uploaded-image-remove-button {
    position: absolute;
    right: -8px;
    top: -8px;
    height: 26px;
    width: 26px;
    border-radius: 18px;
    background-color: $white;
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
    span.icon-close {
      color: $scared-red;
      font-size: 10px;
    }
  }
  .uploaded-image-wrapper {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  .uploaded-image {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
}
/* Imgae uploader suctom styling end */

.cdk-overlay-pane {
    transition: all .3s ease;
    /* uploader dialog */
    &.uploader-panel {
        @media #{$screen-lt-sm} {
            width: 80% !important;
        }
        @media #{$screen-lt-xxs} {
            width: 90% !important;
            .dialog-actions-container {
                height: auto;
            }
            .uploader-action-container {
                margin-bottom: 10px;
            }
        }
    }
    &.signin-panel {
        width: 320px;
        @media #{$screen-lt-xxs} {
            width: 96%;
            max-width: 96% !important;
        }
    }
    &.product-feedback-panel,
    &.product-dispute-panel {
        width: 740px;
        @media #{$screen-md} {
            width: 740px;
            max-width: 740px !important;
        }
        @media #{$screen-sm} {
            width: 340px;
            max-width: 340px !important;
        }
        @media #{$screen-xs} {
            width: 70%;
            max-width: 70% !important;
        }
    }

    &.upsell-details-panel {
        position: static;
        width: 600px !important;
        height: auto !important;
        pointer-events: auto;
    }
}
