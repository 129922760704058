.breadcrumb{
  li {
    a {
      font-weight: bold;
    }
  }
}

/* Add a or symbol (|) before/behind each list item */
ul.single-product-breadcrumb li + li:before {
    margin: 0 10px;
    content: "|";
}
ul.single-product-breadcrumb li {
    display: inline-block;
    a {
        font-weight: bold;
    }
}

.product-page {
  margin-top: 25px;
  margin-bottom: 25px;
}

.image-container{
  margin-top: -15px;
  margin-left: -15px;
  overflow: hidden;
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
    &.product-selected-image {
        min-height: 360px;
    }
  @media #{$screen-lt-md} {
    width: 90%;
    margin: 0 auto;
  }
  img{
    display: block;

      @media #{$screen-lt-md} {
          margin: 0 auto;
      }
  }
    &.img-box {
        margin-top: 0;
        margin-left: 0;
    }
}

.image-selector{
  margin-top: 20px;
  margin-bottom: 10px;
  position: relative;
  @media #{$screen-lt-md} {
    text-align: center;
  }
  .active{
    opacity: 0.6;
  }
  >div{
    div{
      cursor: pointer;
      text-align: center;
    }
  }
    .arrows {
        top: -57px;
        left: 39%;
    }
}

.arrows{
    position: absolute;
    top: -40px;
    left: 20px;
    @media #{$screen-lt-md} {
        position: inherit;
        margin: 0 auto;
        left: 0;
    }
}

.arrow-button{
    display: inline-block;
    height: 32px;
    padding: 6px 11px;
    background-color: $sallad-green;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    &.left-arrow {
        margin-right: 5px;
    }
    .material-icons{
        display: block !important;
    }
    &:hover{
        background-color: #68c0a6 !important;
        opacity: 1;
    }
}

.brand {
  margin-top:20px;
  color: $medium-gray;
}

.product-info{
  @media #{$screen-lt-md} {
    padding: 0 15px 20px 15px;
  }
  .title-rating{
    padding: 25px 0 15px 0;
    border-bottom: #f3f3f3 2px solid;
  }
  .description {
    color: $medium-gray;
    margin-top: 20px;
    padding: 0 15px 15px 0;
  }
  h1 {
    color: $light-blue;
  }
    .rating-wrapper {
        @media #{$screen-xs} {
            margin-top: 10px;
        }
    }
}

.product-order{
  min-height: 600px;
  &.order-currency-container {
      .price-label {
          margin-bottom: 15px;
      }
      min-height: auto;
  }
  @media #{$screen-lt-md} {
    min-height: 0;
  }
  .price {
    width: 80%;
    margin: 0 auto;
    margin-top: -20px;
    @media #{$screen-lt-md} {
      width: 100%;
      margin: 30px 0 0 0;
        padding: 0 15px;
    }
      &.product-price {
          margin-top: 30px;

          .price-label {
              margin-bottom: 5px;
          }
      }
  }
  .price-title{
    background-color: $light-blue;
    color: $white;
    text-align: center;
    font-size: 24px;
    padding: 4px 10px;
  }
  .price-label {
    padding: 12px 2px;
    border-radius: 8px;
    color: $white;
    background-color: $white-blue;
    text-align: center;
    font-size: 12px;
      @media #{$screen-lt-md} {
          padding: 8px 22px;
          margin-bottom: 6px;
      }
    &.order-currency-label {
      padding: 11px 2px;
      min-height: 40px;
    }
    &.long-value {
      font-size: 12px;
    }
    &.EUR { background-color: $medium-blue; }
    &.BTC { background-color: $dark-blue; }
    &.ETH { background-color: $night-blue; }
    &.USD { background-color: $inkBlue; }
  }
}
.action-tabs-container {
    margin-top: 50px;
}
.action-tab{
    background-color: $white;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
    position: relative;
    bottom: 50px;
    width: 300px;
    right: 25px;
    color:$medium-gray;
    @media #{$screen-lt-md} {
        position: static;
        bottom: unset;
        right: unset;
        margin: 30px auto;
        -webkit-box-shadow: unset;
        -moz-box-shadow: unset;
        box-shadow: unset;
    }
    @media #{$screen-lt-xxs} {
        width: 100%;
    }
    .quantity{
        padding: 20px 10px 10px 15px;
        select{
            &:focus {
                outline: none;
            }
            color: $medium-blue;
            padding: 5px 10px 5px 15px;
            font-size: 16px;
            border-radius: 9px;
            border: 1px solid $medium-gray;
            background-color: $background-gray;
        }
    }
    .one-click, .add-to-cart{
        padding: 10px 15px;
        .disabled{
            cursor: no-drop;
            &:hover{
                background-color: $light-blue !important;
            }
        }
        >div{
            padding: 10px 0;
        }
        &.btn-box {
            padding: 0;
        }
    }
    .one-click-text {
        text-align: left;
        font-size: 11px;
        padding: 0 15px;
    }
    .sell-on-market {
        text-align: left;
        font-size: 11px;
        padding: 0 0 0 15px;
    }
}

.product-review {
  @media #{$screen-lt-md} {
    margin-top: 15px;
  }
  margin-top: 15px;
  margin-bottom: 80px;
  h2{
    color: $night-blue;
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .review-container{
    margin-bottom: 40px;
    position: relative;
    .user-name{
      color: $inkBlue;
      font-size: 18px;
      padding: 28px 20px 0 0;
      @media #{$screen-lt-sm} {
        padding: 28px 20px 20px 20px;
      }
    }
    .text{
      padding: 0 20px 0 0;
      background-color: $white;
      color: $medium-gray;
      @media #{$screen-lt-sm} {
        padding: 20px;
      }
    }
    .review-image {
      width: 80px;
      height: 80px;
      margin: 30px;
      border-radius: 40px;
      background-color: $white;
      overflow: hidden;
      -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.08);
      img{
        display: block;
      }
    }
    .review-rating{
      position: absolute;
      right: 40px;
      top: -20px;
      padding: 8px 13px;
      background-color: $white;
      border-radius: 7px;
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    }
  }
}

.selection-arrow-wrapper {
    width: 12px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    .selector-arrow {
        position: relative;
        height: 20px;
        width: 0;
        &.left-arrow {
            transform: rotateY(180deg);
            right: -12px;
        }
        &:before,
        &:after {
            content: '';
            width: 12px;
            height: 3px;
            background-color: $white;
            display: inline-block;
            position: absolute;
        }
        &:before {
            transform: rotate(-47deg);
            bottom: 5px;
        }
        &:after {
            transform: rotate(47deg);
            top: 5px;
        }
    }
}
