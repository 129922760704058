app-products {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

app-product-list {
    display: flex;
    flex: 1 0 100%;
    flex-direction: row;
}

.add-product-page-button,
.product-details-save-button,
.settings-send-email-button {
    padding: 8px 24px !important;
    border-radius: 4px;
}

.settings-send-email-button {
    @media #{$screen-lt-sm} {
        width: 100%;
        max-width: 100%;
    }
}

.product-payment-info {
    color: $medium-gray !important;
}

.product-payment-code {
    color: $inkBlue;
    font-weight: bold;
}

.product-currencies {
    color: $light-blue;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &.diabled {
        opacity: 0.4;
        cursor: no-drop;
        background-color: #bdd1d9 !important;
    }
    &:hover {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07) !important;
    }
    &.active {
        i {
            color: $white;
        }
        background-color: $light-blue;
        color: $dark-blue;
    }
    .icon {
        margin-top: 25px;
        i {
            font-size: 50px;
        }
    }
    .name {
        margin-top: 10px;
        color: $night-blue;
        font-weight: bold;
    }
    .symbol {
        padding-bottom: 15px;
    }
    text-align: center;
}

.currencies-list {
    > div {
        transition: all .3s ease-in-out;
        &:hover {
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07) !important;
        }
    }
}

.currencies-list {
    > div {
        margin-bottom: 15px;
        &:hover {
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07) !important;
        }
    }
    margin-top: 20px;
}

.order-forms-header {
    font-weight: bold;
}

.order-forms-list {
    margin-top: 15px;
    > div {
        cursor: pointer;
        position: relative;
        .checked-container {
            border: 3px solid $light-blue;
        }
        .image {
            border-radius: 8px;
            -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
            background-color: $white;
            -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
            overflow: hidden;
        }
        .checked {
            background-color: $light-blue;
            -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
            width: 25px;
            height: 25px;
            border-radius: 30px;
            position: absolute;
            left: -12.5px;
            top: -12.5px;
            z-index: 1000;
            i {
                color: #ffffff;
                display: block;
                line-height: 26px;
                text-align: center;
                font-size: 12px;
            }
        }
        a {
            display: block;
            text-align: center;
            padding: 5px;
            &:hover {
                text-decoration: underline;
            }
        }
        img {
            display: block;
            width: 100%;
        }
        .product-order-form-preview {
            font-size: 12px;
            line-height: 12px;
            color: $light-blue;
            font-weight: bold;
            text-decoration: none;
        }
    }
}

.product .common-container {
    transition: all .3s ease-in-out;
}

.product .common-container:hover {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07) !important;
}

.product-list-item-action {
    cursor: pointer;
    outline: none;
    &:hover {
        opacity: .4;
        transition: opacity .3s ease;
    }
}

code {
    display: block;
    width: 100%;
    background-color: $background-gray;
    border-radius: 7px;
    padding: 15px;
    margin-top: 20px;
}

.policy-list {
    > div {
        margin-bottom: 15px;
    }
    .active {
        .name {
            color: $white;
        }
        .text {
            color: $dark-blue;
            font-weight: bold;
        }
    }
    .name {
        text-align: left;
        font-weight: bold;
        padding: 0 15px;
        margin-bottom: 20px;
    }
    .text {
        text-align: left;
        padding: 0 15px 15px 15px;
        font-size: 11px;
        color: $light-gray;
    }
}

app-order-forms {
    width: 100%;
}

app-integrations {
    width: 100%;
}


.add-product-form-container,
.add-integration-form-container {
    padding: 0 60px;
    @media #{$screen-lt-md} {
        padding: 0 20px;
    }
}

.add-product-form-field-wrapper,
.form-field-wrapper {
    margin-bottom: 30px;
    @media #{$screen-lt-sm} {
        margin-bottom: 15px;
    }
    &.upsells-wrapper {
        min-width: 540px;
        min-height: 40vh;
        overflow-x: auto;
    }
    &:nth-of-type(1) {
        margin-top: 30px;
        @media #{$screen-lt-sm} {
            margin-top: 15px;
        }
    }
    &.with-no-padding {
        padding: 0 !important;
    }
    .input-field-label {
        h4 {
            color: $pacific-gray;
        }
    }
    h4, p {
        padding-bottom: 0;
        padding-top: 0;
        font-size: 14px;
        line-height: 14px;
        color: $light-blue;
        &.form-field-header {
            @media #{$screen-lt-md} {
                padding-bottom: 15px !important;
                text-align: left !important;
            }
        }
    }
    .mat-form-field {
        border-radius: 4px;
    }
    .currency-form-field {
        .mat-form-field-wrapper {
            padding: 0 10px !important;
        }
    }
    .tags-form-field,
    .linked-items-form-field {
        height: auto !important;
        min-height: 46px !important;
        .mat-form-field-infix {
            padding: 0;
            border-top: 1.2em solid transparent !important;
        }
        .mat-input-wrapper,
        .mat-form-field-wrapper {
            height: auto !important;
            min-height: 46px !important;
        }
    }
    .description-form-field {
        .mat-form-field-wrapper {
            height: auto !important;
            padding: 0 0 16px 16px !important;
        }
        .mat-input-wrapper {
            height: auto !important;
        }
    }
    .type-form-field {
        width: 140px;
        .mat-form-field-wrapper {
            padding: 0 10px !important;
        }
    }
    .available-qty-form-field {
        width: 140px;
    }
}

.enabling-fields-wrapper {
    margin-bottom: 15px;
}

.product-manip-caution-text {
    @media #{$screen-lt-sm} {
        text-align: left;
        font-size: 12px;
    }
}

/* Add product image uploading */

.product-uploaded-image-container {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid $light-blue;
    margin-top: 35px;
    margin-bottom: 20px;
}

.uploaded-image-action {
    position: absolute;
    top: -25px;
    height: 22px;
    width: 22px;
    border-radius: 18px;
    background-color: $scared-red;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow .3s ease;
    &:hover {
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.08);
    }
    .material-icons {
        font-size: 1.2em;
        font-weight: bold;
        line-height: unset;
    }
    &.remove {
        right: -8px;
        .material-icons {
            color: $white;
        }
    }
    &.primary {
        right: 28px;
        background-color: $light-blue;
        @media #{$screen-lt-md} {
            right: 24px;
        }
        .material-icons {
           // color: $dark-green;
        }
    }
}

.product-uploaded-image-wrapper {
    display: flex;
    padding: 10px;
    overflow: hidden;
    img.product-uploaded-image {
        border-radius: 8px;
    }
    img {
        width: 100%;
        height: auto;
    }
}

mat-form-field.search-field.product-currency-dropdown {
    border-radius: 4px !important;
    /*@media #{$screen-lt-md}{

    }*/
    margin-bottom: 0 !important;
}

.small-currency {
    font-size: 14px !important;
}

.description-dotted-string:first-of-type {
    margin-top: 15px;
}

.description-dotted-string {
    position: relative;
    margin-left: 20px;
}

.description-dotted-string:before,
.description-dotted-string::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 6px;
    width: 6px;
    height: 6px;
    background-color: $sallad-green;
    border-radius: 4px;
}

.description-line {
    position: relative;
    color: $medium-gray;
}

/* User small panel in header start */

.user-small-panel {
    &.waggon-wrapper {
        min-width: 0;
        margin-top: 20px;
        border: 2px solid $light-blue;
        /*border-bottom-left-radius: 22px;
        border-top-left-radius: 22px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;*/
        border-radius: 22px;
        vertical-align: middle;
        .user-panel-avatar-container {
            &.small-panel {
                display: inline-block;
                width: auto !important;
                height: auto;
                margin: 0;
                vertical-align: middle;
            }
        }
        .username-wrapper {
            &.small-panel {
                display: inline-block;
                vertical-align: middle;
            }
            h6 {
                margin-bottom: 0;
            }
        }
        .user-avatar {
            overflow: hidden;
            border: 2px solid white;
            border-radius: 40px;
            @media #{$screen-lt-sm} {
                border: 1px solid white;
            }
        }
        .user-avatar-wrapper {
            &.small-panel {
                margin: 0;
                @media #{$screen-lt-sm} {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        .user-avatar-circle {
            position: relative;
            background-color: $white;
            &.small-panel {
                width: 36px;
                height: 36px;
                @media #{$screen-lt-sm} {
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .user-avatar-picture {
            @media #{$screen-lt-sm} {
                width: 32px;
                height: auto;
                margin: 1px;
            }
        }
        .nav-action-buttons-container {
            float: right;
            display: block;
            min-width: auto;
            margin-top: 0;
            .button {
                margin: 0;
                border-radius: 19px;
                border: 1px solid transparent !important;
                background-color: $white;
                transition: all .3s ease-in-out;
                &:hover {
                    color: $white !important;
                    background-color: $light-blue !important;
                }
            }
        }
    }
}

.distribution-link {
    label {
        display: block;
        font-size: 20px;
        color: $night-blue;
        mat-icon {
            font-size: 18px;
            line-height: 20px !important;
        }
    }
    input {
        width: 100%;
        padding: 10px;
        font-size: 21px;
        border: 0 solid #ffffff;
        background-color: white;
    }
}

app-product {
    width: 100%;
}

.affiliate-links {
    a {
        display: block;
        background-color: #1d93c1;
        padding: 5px;
        text-align: center;
        border-radius: 3px;
        margin-bottom: 7px;
        color: #ffffff;
        &:hover {
            background-color: $light-blue;
        }
    }
}

.product-distribution {
    margin-bottom: 20px;
    margin-top: 25px;
}

/* User small panel in header end */

.payment-plans-info-wrapper {
    margin-left: 4px;
    .material-icons {
        line-height: 14px;
        font-size: 20px;
    }
}


//
.mat-radio-group{
    flex-wrap: wrap;

    .mat-radio-button{
        margin: 0 20px 10px 0 !important;
    }
}

.product-details {
    .selection-tab {
        h6 {
            color: $light-blue!important;
        }
    }
}
