.merchant-instruct-list-lp {
  padding-left: 17px;
}

.orders-header-text {
  font-weight: bold;
}

.get-csv-file {
  margin-bottom: 10px;
  font-weight: bold;
  transition: color .25s ease;
  &:hover {
    color: #ffc600;
    text-decoration: none;
  }
}

.include-zero-checker {
  margin-bottom: 10px;
}

.allowed-ip-label {
  color: rgba(0, 0, 0, 0.54);
}

.relative-row {
  position: relative;
}

.status-expired {
  color: red !important;
}

.refund-icon {
  font-size: 27px;
  color: rgba(0, 0, 0, 0.54);
}

.input-field-pt-1 {
  padding-top: 1rem;
  @media #{$screen-xs} {
    padding-top: 0;
  }
}

.input-field-pb-1 {
  padding-bottom: 1rem;
  @media #{$screen-xs} {
    padding-bottom: 0;
  }
}

.input-field-pb-1-xs {
  padding-bottom: 1rem;
}
