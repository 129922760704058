.upsells-main-container {
    overflow: auto;
}

mat-tree-node.upsells-tree-node {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-top: 10px;
}
.upsell-node-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.upsell-product-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    background-color: $white;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    .image-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 10px;
        padding: 5px 10px;
        &.no-image {
            width: 50px;
            height: 50px;
            background: url($no-image-background) no-repeat center;
            background-size: 215px auto;
        }
    }
    .product-image {
        display: flex;
        width: 50px;
        height: auto;
        border-radius: 6px;
        margin-right: 5px;
    }
    .product-details-container {
        padding: 5px;
        font-weight: bold;
        .info-column {
            width: 180px;
            font-size: 12px;
            div {
                white-space: nowrap;
                overflow: hidden;
                &.name-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &.type-info {
                    display: flex;
                    flex-direction: row;
                    .type-coloring-wrapper {
                        display: flex;
                        margin-right: 5px;
                        padding: 0 2px;
                        border-radius: 8px;
                        &.type-yes { background-color: $dark-green; }
                        &.type-no { background-color: $scared-red; }
                    }
                    p.branch {
                        font-size: 14px;
                        display: inline-block;
                        margin: 10px 0;
                        line-height: unset;
                        text-transform: capitalize;
                        font-weight: normal;
                        color: $light-blue;
                    }
                }
            }
        }
    }
}
.tree-toggle-button {
    display: none;
    color: $light-blue !important;
    &.show-button {
        display: block;
    }
}
.type-suggestion-container {
    display: none;
    margin: 0 10px;
    &.show-suggestions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}
.type-suggestion {
    border: 1px solid transparent;
    border-radius: 6px;
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 60px;
    min-height: 35px;
    padding-bottom: 5px;
    .type-button {
        display: inline-block;
        margin-right: 4px;
        border: 1px solid transparent;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        text-transform: capitalize;
        transition: all .3s ease;
        pointer-events: auto;
        opacity: 1;
        padding: 18px 24px;
        &.yes-button { background-color: $dark-green; }
        &.no-button { background-color: $perfect-orange; }
        &.cancel-button { background-color: $scared-red; }
        &.final-type {
            margin-right: unset;
        }
        &.disabled {
            pointer-events: none;
            opacity: .4;
        }
        p {
            margin: unset;
            line-height: 11px;
            font-size: 14px;
            font-weight: normal;
            color: white;
        }
    }
}
.branch-actions-container {
    display: inline-block;

    &.hide-actions {
        display: none;
    }
    button.mat-icon-button {
        &.add {
            mat-icon {
                color: $dark-green !important;
            }
        }
        &.remove-icon {
            mat-icon {
                color: $scared-red !important;
            }
        }
        &.edit-icon, &.details-icon {
            mat-icon {
                color: $light-blue !important;
            }
        }
        mat-icon {
            color: $black !important;
        }
    }
}
.suggested-product-container {
    display: flex;
    width: 100%;
    max-width: 100%;
}
.product-suggest-form {
    mat-form-field {
        padding: 2px 0;
        margin-right: 7px;
        border-radius: 6px !important;
        .mat-form-field-label-wrapper {
            top: -1em;
            .mat-form-field-label {
                color: $light-blue;
            }
        }
        .mat-form-field-infix {
            input { width: 90%; }
        }

    }
}
.mat-option-text {
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1 1 188px;
}
.option-product-image {
    display: inline-block;
    width: 30px;
    height: 30px;
}
.mat-autocomplete-panel {
    max-height: 192px !important;
}

.confirm-remove-upsell {
    width: 400px;
}

/* Upsell details dialog start */

.upsell-details-container {
    .heading-wrapper {
        margin-bottom: 15px;
        padding: 10px 0;
        border-bottom: 1px solid $light-gray;
    }
    .heading-title {
        font-size: 20px;
        line-height: 20px;
        color: $light-blue;
    }
    .headline-title {
        margin-bottom: 20px;
        color: #000000;
        font-size: 40px;
        line-height: 35px;
        font-weight: bold;
    }
    .headline-content-choose-box {
        mat-radio-button {
            margin-right: 20px;
            color: $blue-gray;
            font-size: 14px;
            line-height: 14px;
        }
    }
    .title-container,
    .headline-content-choose-box,
    .headline-content-container {
        margin-bottom: 20px;
    }
    .title-container .label,
    .headline-content-choose-box #content-chooser-label,
    .headline-content-container .label {
        color: $blue-gray;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
    }
    .label {
        margin-bottom: 10px;
    }
    .text-box {
        .mat-form-field-wrapper {
            height: auto !important;
        }
    }
    .text-box,
    .video-box {
        max-width: 400px;
        width: 100%;
    }
}

/* Upsell details dialog start */
