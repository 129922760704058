.main-bg{
  background-color: $light-gray;
  background-image: url($signin-background);
  background-size:cover;
    background-position: right top;
}

.formprofile-title {
  margin-top: 0.2em;
  margin-bottom: 1.3em;
  font-size: 1.6em;
    text-align: center;
  text-transform: inherit;
  color: #35d8b9 !important;
  font-weight: bold;
}

.formprofile {
    max-width: 90%;
    width: 25em;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
}

.login-buttons {
  margin-top: 35px;
  .login{
    border: 2px solid #35d8b9 !important;
    background-color: #35d8b9 !important;
    color: #ffffff !important;
    padding: 7px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover{
      opacity: 0.8 !important;
    }
  }

  .next {
    border: 2px solid #35d8b9 !important;
    background-color: #35d8b9 !important;
    color: #ffffff !important;
    padding: 0px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover{
      opacity: 0.8 !important;
    }
  }
  .skip{
    border: 2px solid #f1f0f0 !important;
    background-color: #f1f0f0 !important;
    color: $light-blue !important;
    padding: 0px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover{
      opacity: 0.8 !important;
    }
  }

  .register{
    border: 2px solid $light-blue !important;
    color: $light-blue !important;
    background-color: #FFFFFF !important;
    padding: 7px 5px !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    cursor: pointer !important;
    &:hover{
      background-color: $light-blue !important;
      color: #ffffff !important;
    }
  }
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0px 0px 0px rgba(0,0,0,.0), 0 0px 0px 0 rgba(0,0,0,.0), 0 0px 0px 0 rgba(0,0,0,.0) !important;
}

.register-block{
  &:focus {
    outline: none;
  }
}

.register{
  .mat-button-wrapper{
    display: block;
  }
}

.formprofile-input {
  label {
    font-size: 13px;
    color: #8c95a2;
  }
  input {
    &:focus {
      outline: none;
    }
      font-size: 14px;
      caret-color: #37bced;
    color: #8c95a2 !important;
    border: 1px solid #c9c9c982 !important;
    border-radius: 8px;
    background-color: white;
  }
    ::placeholder{
        color: #8c95a2 !important;
        font-size: 14px;
    }
  textarea {
    &:focus {
      outline: none;
    }
    // border: 0px solid red !important;
    border-radius: 8px;
    background-color: #f1f0f0;
  }
  select {
    &.currency{
      background-color: #37bced;
      color: #ffffff;
    }
    &:focus {
      outline: none;
    }
    text-transform: none;
    width: 100%;
    border-radius: 8px;
    background-color: #f1f0f0;
    padding: 0.98em 1em;
    border: 1px solid $background-gray;
    cursor: pointer;
  }
}

.login-buttons button[disabled]{
  opacity: 0.3 !important;
}

.login-buttons button[disabled]:hover{
  opacity: 0.3 !important;
}

.checkbox{
  margin-bottom: 15px;
  .checkbox-input{
    width: 30px;
    height: 30px;
    background-color: #f1f0f0;
    position: relative;
    cursor: pointer;
    &.active{
      &:before{
        font-family: cryptozone, sans-serif !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\E920";
        color: #35d8b9;
        position: absolute;
        right: 7px;
        top: 7px;
        margin-left: 9px;
      }
    }
  }
  .checkbox-text{
    text-align: left;
    color: $medium-gray;
  }
}
.session-links {
    font-size: 13px;
    color: $medium-gray;
    a {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: darken($medium-gray, 50%);
        }
    }
    .left-link {
        margin-right: 15px;
    }
}

.sign-up {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

  .sign-up-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    .sign-up-title-wrapper {
        margin-bottom: 50px;
    }
      // todo do we need these background images anymore?
    &.user-step-0 {
      background-image: url("./../../images/sign-up-user/img1.jpg");
    }
    &.user-step-1 {
      background-image: url("./../../images/sign-up-user/img2.jpg");
    }
    &.user-step-2 {
      background-image: url("./../../images/sign-up-user/img3.jpg");
    }
    &.user-step-3 {
      background-image: url("./../../images/sign-up-user/img4.jpg");
    }
    &.merchant-step-0 {
      background-image: url("./../../images/sign-up-merchant/img1.jpg");
    }
    &.merchant-step-1 {
      background-image: url("./../../images/sign-up-merchant/img2.jpg");
    }
    &.merchant-step-2 {
      background-image: url("./../../images/sign-up-merchant/img3.jpg");
    }
    &.merchant-step-3 {
      background-image: url("./../../images/sign-up-merchant/img4.jpg");
    }
  }
  .sign-up-form-container {
    .inner-container {
        margin: 50px 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
        flex-direction: column !important;
        max-width: 350px;
        padding: 20px;
        @media #{$screen-lt-xxs} {
            width: 90%;
        }
        .sugn-up-title {
            margin-top: 0.2em;
            margin-bottom: 1.3em;
            font-size: 1.6em;
            text-align: center;
            text-transform: inherit;
            color: #35d8b9 !important;
            font-weight: bold;
        }

      .pre-title{
        color: $light-blue;
        text-align: left;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .formprofile {
    width: 33em;
    padding: 1.9em 2.6em 0.7em;
    &.merchant{
      &.step-1{
        width: 33em !important;
      }
    }
    &.step-1{
      width: 55em;
      @media #{$screen-lt-md} {
        width: 33em;
      }
    }
  }
}

.signup-form {
    .re-captcha-wrapper {
        height: 0 !important;
        overflow: hidden;
        transition: height .2s;
        &.visible-re-captcha {
            height: 144px !important;
        }
    }
}

.session-input-container {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 16px;
    max-width: 100%;
    label {
        display: flex;
        width: 100%;
        max-width: 100%;
        font-size: 13px;
        color: $blue-gray;
    }
    mat-form-field {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        margin: 0 !important;
    }
    input,
    input.mat-input-element {
        font-size: 14px !important;
        color: $blue-gray !important;
    }
}
.error-message-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 1em;
    &.mismatch-wrapper {
        position: relative;
    }
}
.form-error-message {
    position: relative;
    top: 0;
    left: 0;
    color: $error-red;
    font-weight: bold;
}


.steps_panel {
  margin-top: -58px;
  .step {
    div{
      display: inline-block;
      width: 55px;
      height: 55px;
      border-radius: 30px;
      background-color: $main-back-gray;
      border: 4px solid #ffffff;
      line-height: 44px;
      color: $light-gray;
      i{
        color: #ffffff;
        line-height: 47px;
      }
      &.done{
        color: $sallad-green;
        position: relative;
        &:before{
          font-family: cryptozone, sans-serif !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e920";
          color: #ffffff;
          position: absolute;
          right: 16px;
          top: 14px;
          margin-left: 9px;
        }
        background-color: $sallad-green;
      }
      &.active{
        background-color: $light-blue;
        color: #ffffff;
        -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.signup-info{
  border-top: 2px solid $background-gray;
  border-bottom: 2px solid $background-gray;
  padding: 40px 0;
  .signup-info-circle-block{
    @media #{$screen-lt-md} {
      margin-bottom: 25px;
    }
  }
  .instant-transaction{
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: #e1f5fe;
    font-size: 70px;
    line-height: 200px;
    margin: 0 auto;
    i{
      color: $light-blue;
    }
  }
  .get-app{
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: #ede7f6;
    font-size: 70px;
    line-height: 200px;
    margin: 0 auto;
    i{
      color: #b39ddb;
    }
  }
  .no-fees{
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background-color: #fff8e1;
    font-size: 70px;
    line-height: 200px;
    margin: 0 auto;
    i{
      color: #ffd54f;
    }
  }
  .signup-info-circle-text{
    color:#757575;
    font-size: 16px;
    padding: 15px 0;
    font-weight: bold;
  }
}

.change-user-type{
  margin-top:10px;
  div{
    a{
      text-decoration: underline;
      font-size: 13px;
      &:hover {
        text-decoration: none;
      }
    }
    &:first-child{
      text-align: left;
    }
    &:last-child{
      text-align: right;
    }
  }
}

.marketing-text{
  text-align: left;
  margin: 10px 0 15px 0;
  font-size: 14px;
  color: #8c95a2;
  border-radius: 6px;
}


// signin container

.signin-main-container {
    display: flex;
    z-index: 10;
    background-color: $white;
    padding: 20px !important;
    margin: 150px auto 50px;
    border-radius: 8px;
    max-width: 100%;
    width: 320px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);;
    @media #{$screen-lt-xxs} {
        width: 96%;
        max-width: 96% !important;
    }
}

.signin-container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
}
.signin-form-container {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    font-size: 16px;
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: $white;
    &.active{
        border: 1px solid $sallad-green;
    }
}
.signin-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto 1.3em;
    font-size: 1.6em;
    color: $dark-green;
    font-weight: bold;
}
.login-notification {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.signin-form {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
}
.signin-input {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin-bottom: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    label {
        font-size: 13px;
        color: #8c95a2;
    }
    input {
        &:focus {
            outline: none;
        }
        caret-color: #37bced;
        color: #8c95a2 !important;
        border: 1px solid #c9c9c982 !important;
        border-radius: 8px;
        background-color: white;
        font-size: 14px;
        width: 100%;
        padding: 0.75em 1em;
    }
    ::placeholder{
        color: #8c95a2 !important;
        font-size: 14px;
    }
    textarea {
        &:focus {
            outline: none;
        }
        border: 0px solid red !important;
        border-radius: 8px;
        background-color: #f1f0f0;
    }
    select {
        &.currency{
            background-color: #37bced;
            color: #ffffff;
        }
        &:focus {
            outline: none;
        }
        text-transform: none;
        width: 100%;
        border-radius: 8px;
        background-color: #f1f0f0;
        padding: 0.98em 1em;
        border: 1px solid $background-gray;
        cursor: pointer;
    }
}

.mat-form-field{
    border-radius: 8px;
    border: 1px solid #c9c9c982;
    background-color: #f1f0f0;
}

