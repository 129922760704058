.common-container {
  border: 1px solid transparent;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: $white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  &.active{
    border: 1px solid $sallad-green;
  }
}

.common-container-header {
  padding-bottom: 15px;
  font-family: AvenirRoman, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #37bced;
}

.available-currency-container {
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    width: 130px;
    height: auto;
    overflow: hidden;
    img {
        width: 84%;
        height: auto;
    }
}

.currency-vote-wrapper {
    margin-bottom: 40px;
}

.top-raised-button-container {
  position: relative;
}

.margin-xs-top {
  margin-top: 10px;
}
.margin-xs-bottom {
  margin-bottom: 10px;
}
.margin-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-sm-top {
  margin-top: 15px;
}
.margin-sm-bottom {
  margin-bottom: 15px;
}
.margin-sm {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-md-top {
  margin-top: 20px;
}
.margin-md-bottom {
  margin-bottom: 20px;
}
.margin-md {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-lg-top {
  margin-top: 30px;
}
.margin-lg-bottom {
  margin-bottom: 30px;
}
.margin-lg {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-xl-top {
    margin-top: 40px;
}
.margin-xl-bottom {
    margin-bottom: 40px;
}
.margin-xl {
    margin-top: 40px;
    margin-bottom: 40px;
}

.relative{
  position: relative !important;
}

.merchant-charts{
  i{
    color: $sallad-green;
  }
}

.video-container,
.video-container-small {
  h6 {
    margin: 0 0 7px;
    color: #37bced;
    font-size: 11px;
    font-weight: bold;
  }
  p {
    margin: 0;
    color: #cccccc;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
  }
}

.video-container {
  position: relative;
  background-color: $black;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  img{
    width: 100%;
  }
  .info{
    position: absolute;
    top: 40px;
    left: -30px;
    width: 7rem;
    height: 6rem;
    padding: 12px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
      @media #{$screen-xs} {
          left: -24px;
      }
  }
  .layer {
    background-color: #00000094;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    &:hover .play {
      background-color: $night-blue;
    }
    .play {
        position: absolute;
        top: 20px;
        right: 20px;
        left: auto;
        width: 50px;
        height: 50px;
        margin-top: 0;
        margin-left: 0;
        border-radius: 25px;
        background-color: $light-blue;
        text-align: center;
        @media #{$screen-lt-sm} {
            top: 50%;
            left: 50%;
            width: 80px;
            height: 80px;
            margin-top: -40px;
            margin-left: -40px;
            border-radius: 40px;
      }
      i {
          margin-left: 5px;
          color: $white;
          line-height: 50px;
          font-size: 24px;
        @media #{$screen-lt-sm} {
            line-height: 80px;
            font-size: 30px;
        }
      }
    }
  }
  img{
    display: block;
  }
}

.video-container-small {
  background-color: $black;
  border-radius: 8px;
  overflow: hidden;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  .info {
    position: absolute;
    left: -30px;
    bottom: 11px;
    width: 110px;
    height: 100px;
    padding: 12px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    @media #{$screen-lt-lg} {
      width: 7rem;
      height: 6rem;
    }
    .info-wrapper {
      max-height: 60px;
      overflow: hidden;
    }
  }
  .layer {
    background-color: #00000094;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    cursor: pointer;
    &:hover .play{
      background-color: $night-blue;
    }
    .play{
      width: 50px;
      height: 50px;
      border-radius: 25px;
      background-color: $light-blue;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 20px;
      i{
        color: $white;
        line-height: 50px;
        font-size: 24px;
        margin-left: 5px;
      }
    }
  }
  img{
    display: block;
    width: 100%;
  }
}

.view-more-button {
  min-width: 200px;
  padding: 8px;
  border-radius: 4px;
  background-color: $light-blue;
  color: $white;
}

.your-market-table {
  .table-header {
    & > div {
      padding: 12px 0;
      font-family: AvenirRoman, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: $light-blue;
      word-wrap: break-word;
      @media #{$screen-lt-lg} {
        font-size: 11px;
      }
    }
      &.small-size-text > div {
          font-size: 14px;
          @media #{$screen-lt-lg} {
              font-size: 11px;
          }
      }
    /*& > div:first-child{
      padding-left: 20px;
      @media #{$screen-lt-lg} {
        padding-left: 8px;
      }
    }*/
  }
  .table-body {
    & > div,
    .table-row {
        border-radius:8px;
        &.selected {
            background-color: #eae9e9 !important;
        }
      & > div,
      &.table-cell {
        padding: 12px 0;
        font-size: 14px;
        color: #8c95a2;
        word-wrap: break-word;
          align-items: center;

        @media #{$screen-lt-lg} {
          font-size: 11px;
        }
      }
        .table-text {
            -ms-word-break: break-all;
            word-break: break-all;
            -ms-word-wrap: break-word;
            word-wrap: break-word;
            &.active {
                color: #36987e;
            }
            &.inactive {
                color: #f44336;
            }
        }
      &:nth-child(odd){
        background-color: $main-back-gray;
      }
      .button{
        div {
          width: 100%;
        }
      }
      /*>div:first-child{
        padding-left: 20px;
        @media #{$screen-lt-lg} {
          padding-left: 8px;
        }
      }*/
    }

  }
}

.your-market-table .table-body,
.horizontal-scroll-table,
.horizontal-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border-radius: 8px;
    background-color: $table-scroll-thumb-gray;
  }
  &::-webkit-scrollbar-track {
    background-color: $date-btn-gray;
    border-radius: 8px;
  }
}

.your-market-table-vertical-horizontal{
  .table-header{
    div:nth-child(2) {
      border-radius: 8px 0 0 8px;
    }
    > div {
      padding: 12px 0;
      font-size: 11px;
      color: $light-blue;
      word-wrap: break-word;
      font-weight: bold;
      //@media #{only screen and (max-width 1490px)} {
      //  font-size: 11px !important;
      //}
        @media #{$screen-lte-1366px} {
            font-size: 11px !important;
        }
    }
  }

  .table-body{
    & > div {
      div:nth-child(2){
        border-radius: 8px 0 0 8px;
      }
      > div {
        padding: 12px 0;
        font-size: 11px;
        color: $light-gray;
        font-weight: bold;
        word-wrap: break-word;
        //@media #{only screen and (max-width 1490px)} {
        //  font-size: 11px !important;
        //}
          @media #{$screen-lte-1366px} {
              font-size: 11px !important;
          }
      }
      &:nth-child(odd){
        > div{
          background-color: $main-back-gray;
        }
        > div:last-child{
          border-radius: 0 8px 8px 0;
        }
      }
      .vertical-header{
        color: $light-blue;
        background-color: $white !important;
      }
    }
  }
}

.your-market-table-vertical-horizontal{
  .vertical-header{
    //@media #{only screen and (max-width 1490px)} {
    //  font-size: 11px !important;
    //}
      @media #{$screen-lte-1366px} {
          font-size: 11px !important;
      }
  }
}

.page-selection-tabs-container {
    @media #{$screen-lt-md} {
        margin: 0 !important;
    }
    mat-form-field {
        @media #{$screen-lt-sm} {
            width: 212px !important;
            max-width: 212px !important;
        }
        @media #{$screen-lt-xxs} {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
    &.affiliate-selection-tabs-container{
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 20px;
        @media #{$screen-lt-md} {
            position: static;
            margin-bottom: 0;
        }
    }
}

.selection-tabs-container app-selection-tabs,
.mobile-size-form-field {
    @media #{$screen-xs} {
        width: 212px !important;
        max-width: 212px !important;
    }
    @media #{$screen-lt-xxs} {
        width: 100% !important;
        max-width: 100% !important;
    }
}
.selection-tabs-container app-selection-tabs {
    @media #{$screen-sm} {
        width: 212px !important;
        max-width: 212px !important;
    }
}
.no-result-container {
    &.table-no-result {
        min-width: 850px;
    }
    p {
        margin: 0;
        padding: 10px 0;
        min-height: 40px;
        color: $medium-gray;
        font-weight: bold;
        font-size: 18px !important;
        background-color: $main-back-gray;
        border-radius: 8px;
    }
}
