/* Navigation */
.nav-dashboard {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 267px;
  min-height: 723px;
  vertical-align: top;
  background-color: #2f475b;
  letter-spacing: 0;
  &.flexible-dashboard {
    position: static;
    width: 100%;
    min-width: 1px;
  }
  @media #{$screen-lt-md} {
    position: relative;
    width: 100%;
    min-height: 1px;
  }
  .nav-dashboard-mobile {
    display: none;
    @media #{$screen-lt-md} {
      display: block;
      padding: 0;
      font-size: 24px;
      font-weight: normal;
      span {
        font-family: "HelveticaBold", sans-serif;
        letter-spacing: 0.05em;
        @media #{$screen-lt-md} {
          font-size: 1em;
        }
      }
    }
    .mobile-trigger {
      position: relative;
      @media #{$screen-lt-md} {
        display: block;
        height: 75px;
        padding: 24px 30px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 35px;
        width: 19px;
        height: 11px;
        margin-top: -5px;
        /*background: url($navbar-mobile-trigger) no-repeat 0 0;*/
        @media #{$screen-lte-669px} {
          right: 27px;
        }
      }
      &.active:after {
        /*background-image: url($navbar-mobile-trigger-active);*/
      }
    }
  }
  ul {
    &.admin {
      li {
        padding-left: 25px;
      }
    }
    li {
      position: relative;
      height: 69px;
      padding-left: 67px;
      border-bottom: 1px solid #6689a6;
      line-height: 69px;
      &:hover,
      &:focus,
      &.active {
        background-color: #36987e;
        border-bottom-color: #53cbab;
      }
      &.active {
        span {
          color: #fff;
          pointer-events: none;
          font-family: "HelveticaBold", sans-serif;
        }
      }
      span {
        &.icon {
          position: absolute;
          left: 26px;
          font-size: 18px;
          font-family: "HelveticaLight", sans-serif;
        }
        &.restore-label {
          margin-left: 9px;
        }
      }
      // Admin navigation icons
      i {
        margin-right: 18px;
        vertical-align: middle;
      }
    }
    @media #{$screen-lt-md} {
      display: none;
      position: relative;
      top: 126px;
      left: 12px;
      right: 12px;
      z-index: 200;
      background-color: #2f475b;
      &.visible-dashboard {
        display: block;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
  span {
    display: block;
    color: #fff;
    font-size: 18px;
    &:focus {
      outline: none;
    }
  }
}
// Navigation dashboard icons
.icon-wallets {
  top: 24px;
  width: 21px;
  height: 19px;
  /*background-image: url($navbar-wallets-icon);*/
}
.icon-add-wallet {
  top: 24px;
  width: 19px;
  height: 19px;
  outline: none;
  /*background-image: url($navbar-add-wallet-icon);*/
}
.icon-restore {
  top: 24px;
  width: 19px;
  height: 19px;
  outline: none;
  /*background-image: url($navbar-restore-icon);*/
}
/*.icon-settings {
  top: 23px;
  width: 20px;
  height: 21px;
  background-image: url($navbar-settings-icon);
}*/
.icon-help {
  top: 24px;
  width: 20px;
  height: 20px;
  /*background-image: url($navbar-help-icon);*/
}
