$paymentStatusPageBg: '../../images/online-shopping.jpg';

.order-product-info {
    margin: 0px auto 0;
    padding: 60px 0 20px;

    .copyright {
        color: $light-gray;
        font-size: 12px;
        text-align: center;
        margin: 0;
    }

    .mat-form-field-infix{
        position: relative;

        >.mat-icon{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $blue-gray;
        }
    }
}

.product-description,
.ym-terms-box {
    margin-bottom: 20px;
    padding: 20px;

    .image {
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    h1 {
        color: $white-blue;
        font-size: 23px;
        font-weight: bold;
    }

    .description {
        white-space: pre-line;
        margin-top: 15px;
        color: $medium-gray;
        a{
            text-decoration: underline;
        }
    }

    .guarantee-icon {
        margin-left: 20px;
        width: 80px;
    }

    .coins {
        margin: 25px 0;

        .button {
            display: inline-block;
            text-align: center;
            font-size: 17px;
            border-radius: 8px;
            margin-right: 10px;
            padding: 7px 18px;
            color: #ffffff;
        }

        .EUR {
            background-color: #7488cf;
        }

        .ETH {
            background-color: #404040;
        }

        .BTC {
            background-color: #ffa800;
        }
    }
}

.input-icon {
    span {
        i {
            position: absolute;
            top: -4px;
            right: 5px;
            color: #a1a1a1;
        }
    }
}

.contact-information {
    margin-bottom: 20px;

    .contact-information-container {
        padding: 20px;

        h2 {
            color: $white-blue;
            font-size: 22px;
            font-weight: bold;
        }

        .contact-info-description {
            text-align: right;
            color: $medium-gray;
        }

        .fields {
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 20px;

            @media #{$screen-lt-md} {
                margin-top: 0;
                margin-bottom: 0;
                &:first-child {
                    margin-top: 20px!important;

                }
            }

            .mat-form-field {
                max-height: 40px;

                .mat-form-field-infix {
                    top: -3px;
                    padding: 0.2em 0;
                }

                .mat-select-value {
                    color: $blue-gray;
                }

                .mat-form-field-label {
                    top: 1em;
                }
            }
        }
        .gender {
            .mat-radio-group {
                justify-content: space-between;
                margin-left: 20px;
                max-width: 85%;
                .ng-star-inserted {
                    margin-right: 0;
                }
            }
        }
    }
}

.ym-terms-box {
    h2 {
        color: $white-blue;
        font-size: 22px;
        font-weight: bold;
    }
}

.order-information {
    margin-bottom: 60px;
    .upsells-information-content-container {
        justify-content: center;
    }
    .upsells-information-container {
        padding: 20px;
        font-size: 20px;
        h2 {
            color: $white-blue;
            font-size: 18px;
            font-weight: bold;
        }
        .upsells-info-description {
            font-size: 18px;
            color: $blue-gray;
            font-weight: bold;
        }
        .fields {
            margin-top: 20px;
        }
    }
}

.upsells-product-container {
    font-size: 14px;
}

.upsell-title {
    font-size: 26px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    @media #{$screen-lt-md} {
        font-size: 18px;
    }
}

.upsell-video-container {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    iframe {
        width: 500px;
        height: 350px;
        @media #{$screen-lt-sm} {
            width: 100%;
            height: auto;
        }
    }
}

.upsell-text-container {
    max-width: 600px;
    width: 100%;
    margin-top: 30px;
    ul {
        margin: 16px 0;
        padding-left: 40px;
        list-style: unset;
    }
}

.order-table {
    .table-header {
        align-items: center;
        text-align: center;
    }
    .row-item {
        text-align: center;
    }
    .table-body > div > div {
        text-align: center;
    }
}

.product-container {
    margin-top: 50px;
    app-upsells-product {
        .product-page {
            .product-page-container {
                justify-content: center;
            }
            .product-info {
                .title-rateing {
                    justify-content: center;
                    .rating-info {
                        display: none !important;
                    }
                }
                .product-description-container {
                    text-align: left !important;
                }
            }
            div {
                .product-order {
                    display: none;
                }
            }
        }
    }
}

.cancel-btn {
    margin-top: 15px;
}
.header-margin {
    &.upsells-container {
        margin-top: 40px;
    }
}
.upsell-page-information {
    width: 600px;
}

.payment-information {
    margin-bottom: 20px;

    >div{
        margin-bottom: 20px;
    }

    @media #{$screen-lt-md} {
        flex-direction: column !important;
    }

    .contact-information-container {
        padding: 20px;
        height: 100%;
        @media #{$screen-lt-md} {
            margin-top: 15px;
        }

        h2 {
            color: $white-blue;
            font-size: 22px;
            font-weight: bold;
        }

        .contact-info-description {
            color: $medium-gray;
        }

        .fields {
            margin-top: 20px;
        }

        .payment-method-name {
            color: #a5a5a5;
            .mat-radio-button {
                &.mat-accent:active .mat-radio-persistent-ripple,
                &.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
                .mat-ripple-element {
                    background-color: $white-blue !important;
                }
            }
        }

        .BTC-payment-button {
            background-color: #ffa800;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            padding: 12px;
            position: relative;
            cursor: pointer;
            @media #{$screen-lt-md} {
                margin-bottom: 15px;
            }

            &:hover {
                opacity: 0.8;
            }

            i {
                position: absolute;
                right: 13px;
                top: 13px;
                font-size: 18px;
                color: #00000038;
            }
        }

        .card-payment-button {
            background-color: #ffa800;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            padding: 12px;
            position: relative;
            cursor: pointer;
            @media #{$screen-lt-md} {
                margin-bottom: 15px;
            }

            &:hover {
                opacity: 0.8;
            }

            i {
                position: absolute;
                right: 13px;
                top: 13px;
                font-size: 18px;
                color: #00000038;
            }
        }

        .ETH-payment-button {
            background-color: #404040;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            padding: 10px;
            position: relative;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            i {
                position: absolute;
                right: 13px;
                top: 13px;
                font-size: 18px;
                color: #00000038;
            }
        }

        .payment-method-checked {
            background-color: #ffffff !important;
            border-radius: 29px;
            font-size: 15px !important;
            padding: 5px;
            top: 10px !important;
            left: 8px !important;
            right: inherit !important;
            color: $normal-green !important;;
        }
    }
}

.title-description {
    padding: 0 15px;
    @media #{$screen-lt-md} {
        margin-top: 15px;
    }
}

mat-form-field {
    @media #{$screen-lt-md} {
        margin-bottom: 15px;
    }
}

.order-product-info {
    .place-order {
        margin-bottom: 70px;
        justify-content: center;
        .place-order-button {
            background-color: $inkBlue;
            padding: 15px;
            text-align: center;
            color: #ffffff;
            border-radius: 8px;
            font-size: 17px;
            cursor: pointer;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

            &:hover {
                background-color: $night-blue;
            }

            &.disabled {
                cursor: no-drop;

                &:hover {
                    background-color: $inkBlue;
                }
            }
        }
    }
}

.product-order {
    .get-it-now-button {
        background-color: $inkBlue;
        padding: 15px;
        text-align: center;
        color: #ffffff;
        border-radius: 8px;
        font-size: 13px;
        cursor: pointer;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        &:hover {
            background-color: $night-blue;
        }

        &.disabled {
            cursor: no-drop;

            &:hover {
                background-color: $inkBlue;
            }
        }
    }
}


.certificates {
    img {
        width: 100%;
        filter: grayscale(100%);
    }

    .big_logo {
        text-align: center;

        img {
            width: 50%;
        }
    }
}

.quiz-button {
    &.feedback-button {
        a {
            color: $white;
            text-decoration: none;
        }
        background-color: $dark-green;
        font-size: 14px;

        @media #{$screen-lt-md} {
            font-size: 9px;
            padding: 5px !important;
        }
    }

    &.duplicate-button {
        background-color: $medium-blue;
    }
}

.feedback-header {
    &, h4 {
        font-size: 14px !important;
        line-height: 1.5 !important;
    }
}

.order-product-image {
    width: 100%;
    height: auto;
}

.credit-card-icon-box {
    width: 40px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: contain;
}

.price-summary {
    color: $light-blue;
    &:before {
        margin-top: 3px;
        margin-right: 4px;
    }
}

.country-form-field {
    .mat-select-value-text {
        display: block;
        width: 100%;
        text-align: left;
    }

    .mat-select-trigger {
        position: relative;
        display: block;
        width: 100%;
        text-align: right;

        .mat-icon {
            position: absolute;
            top: -4px;
            right: 6px;
            color: #a1a1a1;
        }
    }

    .mat-select-arrow {
        margin: 0 2px;
        border: 0;
    }

}

// Pay via bank modal

.pay-via-bank-panel {
    max-width: 30vw !important;
}

.pay-via-pank-container {
    .confirm-dialog-actions {
        padding: 20px 0 !important;
    }

    h1 {
        color: #42c5fa;
        font-size: 22px;
        font-weight: bold;
    }

    .pay-via-bank-body-text {
        color: #a5a5a5 !important;
    }
}

.pay-via-bank-info {
    margin-top: 10px;
    color: #a5a5a5;
}
// End Pay via bank modal

//Gender CSS start
.gender{
    margin-top: 0;
    color: $blue-gray;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .mat-radio-group{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;

        .ng-star-inserted{
            margin-right: 10px;
        }
    }
}

.bottom-padding{
    height: 60px;
}

.mat-tab-body-content{
    overflow: hidden !important;
}

.egret-indigo .mat-radio-outer-circle {
    border-color: $blue-gray;
}
//Gender CSS end

.payment-status-page {
    min-height: calc(100vh - 82px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url($paymentStatusPageBg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 90%;
        width: 700px;
        min-height: 50vh;
        margin: 50px 0;
        z-index: 1;
        background-color: white;
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);

        .text{
            text-align: center;
            color: $light-blue;
        }
    }
}
//Payment-Success-Failed page CSS end

.order-fields {
    width: 100%;

    .mat-radio-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media #{$screen-lt-md} {
            margin: 0 !important;
        }


        .mat-radio-button {
            flex: 1 1 48%;
            align-items: center;
            max-width: 48%;
            min-height: 100px;
            max-height: 120px;
            margin-right: 0!important;
            border-radius: 6px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 20px!important;
            transition: all .2s ease-in-out;

            .mat-radio-container {
                display: none;
            }

            &.mat-radio-checked,
            &:hover {
                box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.3);
            }

            &.mat-radio-checked {
                background: #2ba5c8;

                .wrap-txt {
                    color: $white;
                }
            }

        }

        .mat-radio-label-content {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            flex: 1;
            padding-left: 0!important;
            .wrap-txt {
                text-align: center;
                white-space: pre-line;
                margin-top: 10px;
                @media #{$screen-lt-md} {
                    font-size: 11px;
                }
            }
            .wrap-img {
                margin: 10px 0;

                img {
                    object-fit: cover;
                    width: 60px;
                    height: 60px;
                    border-radius: 6px;
                }
            }
        }
    }
}

.order-info-box {
    height: 100%;
    min-height: 190px;
}



