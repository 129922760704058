/* Syncing spinner */

.spinner-container {
  margin: 0 0 10px 0;
  padding: 3px 0;
  border-radius: 5px;
  background-color: #36987e;
  font-weight: 100;
  margin-right: 8px;
  &.alert-spinner {
    background-color: #ff520c;
  }
  .mat-spinner-wrapper {
    width: 100%;
    height: 100%;
  }
  .syncing-message {
    font-size: 14px;
    color: white;
    @media #{$screen-lte-767px} {
      font-size: 13px;
    }
  }
}

.mat-spinner {
  &.small-spinner {
    width: 20px !important;
    height: 20px !important;
    svg {
      width: 20px !important;
      height: 20px !important;
    }
    circle {
      stroke: white !important;
    }
  }
}

.dashboard-panel {
  position: relative;
  min-height: 122px;
  margin-bottom: 9px;
  padding: 17px 20px 14px 19px;
  background-color: #fff;
  border: 1px solid #cad3d9;
  @media #{$screen-lte-669px} {
    padding-left: 12px;
    padding-right: 12px;
  }
  .dashboard-history {
    position: relative;
    margin-top: 20px;
    padding-top: 25px;
    border-top: 1px solid #000;
    @media #{$screen-lte-1200px} {
      margin-top: 40px;
    }
    @media #{$screen-lte-480px} {
      margin-top: 20px;
    }
    h5 {
      @media #{$screen-lte-1200px} {
        font-size: 21px;
      }
      @media #{$screen-lte-992px} {
        margin-bottom: 30px;
      }
    }
    .space-between-inputs {
      justify-content: space-between;
    }
    .wallets-table-thead {
      margin-bottom: 8px;
      @media #{$screen-lte-1200px} {
        display: none;
      }
    }
    .wallets-table-tfoot {
      padding-top: 28px;
      overflow: hidden;
    }
  }
  .div-table {
    display: table;
    width: 100%;
    border: none;
  }
  .tr,
  .th {
    display: table-row;
  }
  .transactions-row {
    background-color: #fdf9e9;
    border-top: 2px solid #fff;
    &.status-verified {
      background-color: #e8f0ee;
      .tr {
        .tc {
          color: #36987e;
        }
      }
    }
    .first-line {
      cursor: pointer;
      &.collapsed {
        .tc6 {
          .icon-rectangle{
            /*background-image: url($transactions-toggle-down);*/
          }
        }
      }
      .div-table {
        @media #{$screen-lte-1200px} {
          display: block;
          .tr {
            position: relative;
            display: block;
            padding: 10px 0;
          }
          .tc {
            display: block;
            width: 100%;
            margin-bottom: 5px;
            padding: 0 21px;
            @media #{$screen-lte-669px} {
              padding: 0 12px;
            }
            &.tc1 {
              display: none;
            }
            &.tc6 {
              position: absolute;
              top: 14%;
              height: 6px;
            }
            div {
              @media #{$screen-lte-1200px} {
                display: block;
                float: left;
                width: 120px;
                padding-right: 12px;
                color: #000;
              }
              @media #{$screen-lte-669px} {
                width: 72px;
              }
              @media #{$screen-lte-383px} {
                width: 60px;
                font-size: 12px;
              }
            }
            span {
              display: block;
              margin-left: 120px;
              @media #{$screen-lte-669px} {
                margin-left: 72px;
              }
              @media #{$screen-lte-383px} {
                margin-left: 60px;
                font-size: 12px;
              }
            }
          }
        }
        .label {
          display: none;
        }
      }
    }
  }
  .transaction-details-table {
    width: 100%;
    padding: 21px 41px 26px 41px;
    color: #6f7a83;
    font-size: 13px;
    line-height: 1em;
    @media #{$screen-lte-1200px}  {
      padding: 21px 21px 26px 21px;
      font-size: 14px;
    }
    @media #{$screen-lte-669px}  {
      padding: 2px 12px 2px 12px;
      font-size: 13px;
    }
    li {
      margin-bottom: 7px;
      overflow: hidden;
      @media #{$screen-lte-669px} {
        margin-bottom: 24px;
      }
    }
    .transaction-details-left {
      float: left;
      width: 167px;
      padding-right: 5px;
      word-wrap: break-word;
      font-family: "HelveticaBold", sans-serif;
      @media #{$screen-lte-669px} {
        float: none;
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .transaction-details-right {
      margin-left: 167px;
      word-wrap: break-word;
      @media #{$screen-lte-669px} {
        margin-left: 0;
      }
    }
  }
  .th {
    .tc {
      border-bottom: 1px solid #cad3d9;
      padding-left: 0;
      font-family: "HelveticaBold", sans-serif;
    }
    .tc1 {
      padding-left: 5px;
    }
  }
  .tr {
    .tc {
      color: #6f7a83;
      font-family: "HelveticaBold", sans-serif;
    }
    .tc1 {
      padding-left: 7px;
    }
  }
  .tc {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 0;
    line-height: 28px;
  }
  .tc1 {
    width: 42px;
    padding-left: 0;
    position: relative;
  }
  .tc2 {
    width: 290px;
  }
  .tc3 {
    width: 168px;
  }
  .tc4 {
    width: 146px;
  }
  .tc5 {
    width: 140px;
  }
  .tc6 {
    width: 25px;
    position: relative;
    .icon-rectangle {
      top: 12px;
      @media #{$screen-lte-1200px} {
        top: 0;
      }
    }
  }
}

.dashboard-panel-top {
  position: relative;
  min-height: 81px;
  .dashboard-panel-left {
    width: 100%;
    min-height: 30px;
    .icon-favourite {
      margin-top: 8px;
    }
    .icon-favourite-outline {
      margin-top: 8px;
    }
  }
  .currency-panel {
    display: block;
    float: left;
    min-height: 81px;
    padding-top: 4px;
    span {
      &.bitcoin-label {
        padding: 0 12px 1px;
        margin-right: 8px;
      }
    }
  }
  .dashboard-panel-balance {
    width: 100%;
    padding-top: 3px;
    padding-left: 41px;
    @media #{$screen-lte-992px} {
      display: block;
      padding-left: 42px;
      margin-top: 10px;
    }
    p {
      margin-top: 7px;
      margin-bottom: 0;
      font-size: 13px;
      font-family: "HelveticaBold", sans-serif;
      color: #6f7a83;
      span {
        font-family: "HelveticaRegular", sans-serif;
        font-size: 15px;
        &.default-label {
          display: inline;
          height: 16px;
          margin-right: 8px;
          padding: 2px 5px 0;
          font-size: 13px;
          font-family: "HelveticaBold", sans-serif;
          text-transform: uppercase;
          color: #000;
          &.orange-label {
            background-color: #ffc600;
          }
          &.lightblue-label {
            background-color: #f7931a;
          }
        }
        &.dashboard-balance-title {
          display: inline-block;
          vertical-align: baseline;
          width: 142px;
          padding-right: 0.5em;
          &.wallet-address-container {
            width: 80%;
            word-wrap: break-word;
          }
          @media #{$screen-lte-480px} {
            display: block;
            margin-bottom: 5px;
          }
        }
      }
      &.text-black {
        color: #000;
      }
    }
  }
  .dashboard-panel-right {
    position: absolute;
    top: 0;
    right: 0;
    float: none;
    width: 172px;
    height: 100%;
    @media #{$screen-lte-669px} {
      position: static;
      width: 100%;
      margin-top: 40px;
      padding-left: 42px;
    }
    .btn-send,
    .btn-receive {
      outline: none;
      text-align: center;
      span {
        outline: none;
      }
    }
    .btn-receive {
      padding-left: 39px;
      @media #{$screen-lte-669px} {
        margin-right: 15px;
      }
    }
    .btn-send {
      padding-left: 43px;
    }
    /*.icon-arrow-down {
      background: url($wallet-arrow-down) no-repeat;
    }
    .icon-arrow-up {
      background: url($wallet-arrow-up) no-repeat;
    }*/
    .icon-arrow-down,
    .icon-arrow-up {
      position: absolute;
      top: 34px;
      right: 2px;
      width: 19px;
      height: 11px;
      outline: none;
      @media #{$screen-lte-349px} {
        top: 47px;
      }
    }
  }
}

.wallet-name {
  position: relative;
  width: 228px;
  height: 32px;
  margin-right: 10px;
  float: left;
  background-color: inherit;
  @media #{$screen-lte-1366px} {
    width: 150px;
  }
  @media #{$screen-lte-1200px} {
    width: 70%;
  }
  @media #{$screen-lte-480px} {
    float: none;
  }
  @media #{$screen-lte-349px} {
    width: 100%;
  }
  &.input-elipsis input {
    position: absolute;
    float: left;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.wallet-name-input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 24px;
  border-bottom: 1px solid #cad3d9;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  &::placeholder {
    font-family: "HelveticaBold", sans-serif;
    color: #000;
  }
  &:focus,
  &:hover {
    border-bottom-color: #36987e;
  }
  @media #{$screen-lte-480px} {
    width: 83%;
  }
  @media #{$screen-lte-349px} {
    width: 84%;
  }
}

.btn-currency {
  position: relative;
  display: inline-block;
  width: 134px;
  height: 36px;
  margin-bottom: 9px;
  border-radius: 6px;
  line-height: 36px;
  color: #fff;
  background-color: #36987e;
  font-family: "HelveticaBold", sans-serif;
  transition: all .2s ease-in-out;
  @media #{$screen-lte-480px} {
    width: 138px;
  }
  &:hover {
    background-color: #29725f;
    color: #fff;
    transition: all .24s ease;
  }
  &:focus {
    color: #fff;
    transition: all .24s ease;
  }
}

.icon-rectangle {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
  width: 11px;
  height: 6px;
  /*background-image: url($transactions-toggle-up);*/
  outline: none;
}

.collapsible-item {
  &.collapse {
    display: none;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    margin-left: 0;
    border-radius: 0;
    background-color: transparent;
    z-index: 1;
    &.in {
      display: block;
    }
  }
}

span,
mark {
  /*font-family: "HelveticaRegular", sans-serif;
  font-size: 15px;*/
  &.default-label {
    display: inline;
    height: 16px;
    margin-right: 8px;
    padding: 2px 5px 0;
    font-size: 13px;
    font-family: "HelveticaBold", sans-serif;
    background-color: #ffc600;
    text-transform: uppercase;
    color: #000;
  }
  &.hot-label {
    display: block;
    padding-top: 2px;
    background-color: #ff3800;
    color: #fff;
    font-size: 10px;
  }
  &.hot-label-inline{
    display: inline;
    padding-top: 2px;
    padding: 2px 5px 0 5px;
    background-color: #ff3800;
    color: #fff;
    font-size: 15px;
  }
  &.bitcoin-label {
    padding: 0 7px 1px 11px;
    background-color: #f7931a;
    &.dialog-label {
      position: relative;
      top: -3px;
      img {
        position: relative;
      }
    }
    img {
      width: 0.7em;
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
      vertical-align: middle;
    }
  }
}

.mat-form-field {
  span {
    &.default-label {
      position: absolute;
      right: 11px;
      top: 6px;
    }
    &.bitcoin-label {
      position: absolute;
      right: 20px;
      top: 4px;
      padding: 0 10px 1px 11px;
    }
  }
}

/* Dashboard icons */

.sort-down,
.sort-down-green,
.sort-up,
.sort-up-green {
  width: 12px;
  height: 12px;
  margin-top: 8px;
  background-repeat: no-repeat;
  outline: none;
}

.sort-down {
  /*background-image: url($transactions-sort-down);*/
}

.sort-down-green {
  /*background-image: url($transactions-sort-down-green);*/
}

.sort-up {
  /*background-image: url($transactions-sort-up);*/
}

.sort-up-green {
  /*background-image: url($transactions-sort-up-green);*/
}

.icon-favourite {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  /*background: url($primary-wallet-icon-selected) no-repeat;*/
}

.icon-favourite-outline {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  /*background: url($primary-wallet-icon) no-repeat;*/
}

.icon-backup {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-rescan {
  width: 22px;
  height: 22px;
  margin-top: 8px;
  margin-right: 8px;
  /*background: url($wallet-rescan-icon) no-repeat;*/
}

.wallet-backup {
  &:focus {
    outline: none;
  }
  img.icon-backup {
    &:focus {
      outline: none;
    }
  }
}

.wallet-delete,
.wallet-rescan,
.wallet-favourite,
.wallet-favourite-outline {
  &:focus {
    outline: none;
  }
  span:focus {
    outline: none;
  }
}

.icon-receive,
.icon-send {
  position: absolute;
  left: 13px;
}

.icon-receive {
  top: 11px;
  width: 26px;
  height: 15px;
  /*background: url($wallet-receive-btn-icon) no-repeat;*/
}

.icon-send {
  top: 12px;
  width: 25px;
  height: 12px;
  /*background: url($wallet-send-btn-icon) no-repeat;*/
}

.icon-sort {
  width: 18px;
  height: 13px;
  margin-top: 8px;
  /*background-image: url($transactions-sort-icon);*/
}
