/* Common directive styles */

.mat-option,
body .mat-select-trigger {
  font-size: .875rem !important;
}


.notification-list {
  min-width: 240px;
  .notific-item {
    a {
      outline: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .message {
      font-size: .875rem !important;
    }
    .time {
      font-size: .75rem !important;
    }
  }
}
