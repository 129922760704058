$material-media-sizes: (
    all: 'all',
    xs: 'screen and (max-width: 599.98px)',
    sm: 'screen and (min-width: 600px) and (max-width: 959.98px)',
    md: 'screen and (min-width: 960px) and (max-width: 1279.98px)',
    lg: 'screen and (min-width: 1280px) and (max-width: 1919.98px)',
    xl: 'screen and (min-width: 1920px) and (max-width: 5000px)',
    ltSm: 'screen and (max-width: 599.98px)',
    ltMd: 'screen and (max-width: 959.98px)',
    ltLg: 'screen and (max-width: 1279.98px)',
    ltXl: 'screen and (max-width: 1919.98px)',
    gtXs: 'screen and (min-width: 600px)',
    gtSm: 'screen and (min-width: 960px)',
    gtMd: 'screen and (min-width: 1280px)',
    gtLg: 'screen and (min-width: 1920px)'
);

$direction-properties: (
    row: row,
    rowRs: row-reverse,
    col: column,
    colRs: column-reverse
);

$wrapping-properties: (
    wrap: wrap,
    nowrap: nowrap,
    wrapRs: wrap-reverse
);

$justify-properties: (
    fxStr: flex-start,
    fxEnd: flex-end,
    ctr: center,
    spcBtw: space-between,
    spcArd: space-around,
    spcEvn: space-evenly
);

$align-properties: (
    fxStr: flex-start,
    fxEnd: flex-end,
    ctr: center,
    spcBtw: space-between,
    spcArd: space-around,
    stre: stretch
);

$directions: (
    all: all,
    t: top,
    b: bottom,
    l: left,
    r: right,
);

$offsetTerms: (
    m: margin,
    p: padding
);

/* Defines display property flex for all elements
 * which class names are starts or contains fx or flex parts
*/

[class*="fx"], [class*="flex"],
[class^="fx"], [class^="flex"] {
    display: flex !important;
    box-sizing: border-box !important;
}

/* SIZE: Defines flex container width based on media-size and percentage.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * width options      - from 1% to 100%.
 * (e.g. .fx-all-100 or .fx-ltMd-85)
*/

@for $i from 1 through 100 {
    @each $name, $media-size in $material-media-sizes {
        .fx-#{$name}-#{$i} {
            @if ($name == 'all') {
                flex-basis: percentage($i / 100);
                max-width: percentage($i / 100);
            } @else {
                @media #{$media-size} {
                    flex-basis: percentage($i / 100);
                    max-width: percentage($i / 100);
                }
            }
            flex-grow: 1;
            flex-shrink: 1;
        }
    }
}

/* OFFSET: Defines left margin for flex container in different media sizes.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * margin options     - from 1% to 100%.
 * (e.g. .offset-all-10 or .offset-ltMd-2)
*/

@for $i from 0 through 100 {
    @each $name, $media-size in $material-media-sizes {
        .offset-#{$name}-#{$i} {
            @if ($name == 'all') {
                margin-left: percentage($i / 100);
            } @else {
                @if ($i == 0) {
                    @media #{$media-size} {
                        margin-left: 0;
                    }
                } @else {
                    @media #{$media-size} {
                        margin-left: percentage($i / 100);
                    }
                }
            }
        }
    }
}

/* OFFSET: Defines margins and paddings for element in different media sizes.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * offset terms are 'margin' and/or 'padding'.
 * directions terms are 'all', 'top', 'bottom', 'left', 'right'.
 * (e.g. .m-t-all-10 or .p-r-ltMd-2 or or .m-all-gtSm-25)
 * Note, that this operators are working from the range [0px, 128px]
*/
@for $i from 0 through 128 {
    @each $offsetName, $offsetValue in $offsetTerms {
        @each $directionName, $directionValue in $directions {
            @each $name, $media-size in $material-media-sizes {
                .#{$offsetName}-#{$directionName}-#{$name}-#{$i} {
                    @if ($directionName == 'all') {
                        @if ($name == 'all') {
                            #{$offsetValue}: #{$i}px;
                        } @else {
                            @media #{$media-size} {
                                #{$offsetValue}: #{$i}px;
                            }
                        }
                    } @else {
                        @if ($name == 'all') {
                            #{$offsetValue}-#{$directionValue}: #{$i}px;
                        } @else {
                            @media #{$media-size} {
                                #{$offsetValue}-#{$directionValue}: #{$i}px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ORDER: Defines flex container ordering.
 * order options - from 1% to 100%.
 * (e.g. .order-all-3 or .order-ltMd-1)
*/

@for $i from 1 through 100 {
    @each $name, $media-size in $material-media-sizes {
        .order-#{$name}-#{$i} {
            @if ($name == 'all') {
                order: $i;
            } @else {
                @media #{$media-size} {
                    order: $i;
                }
            }
        }
    }
}

/* LAYOUT: Defines direction and wrapping inside flex container in different media sizes.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * direction options  - row, rowRs, col, colRs.
 * width options      - wrap, nowrap, wrapRs.
 * (e.g. .layout-row-wrap or .layout-col-nowrap)
*/

@each $media, $media-size in $material-media-sizes {
    @each $dir-name, $dir-value in $direction-properties {
        @each $wrap-name, $wrap-value in $wrapping-properties {
            .layout-#{$media}-#{$dir-name}-#{$wrap-name} {
                @if ($media == 'all') {
                    flex-direction: $dir-value;
                    flex-wrap: $wrap-value;
                } @else {
                    @media #{$media-size} {
                        flex-direction: $dir-value;
                        flex-wrap: $wrap-value;
                    }
                }
            }
        }
    }
}

/* ALIGNMENT: Defines alignment inside flex container along main and cross axises
 *            in different media sizes.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * main axis options(justify-content)   - fxStr, fxEnd, ctr, spcBtw, spcArd, spcEvn.
 * cross axis options(align-items)      - fxStr, fxEnd, ctr, spcBtw, spcArd, stre.
 * (e.g. .align-fxStr-ctr)
*/

@each $media, $media-size in $material-media-sizes {
    @each $justify-name, $justify-value in $justify-properties {
        @each $align-name, $align-value in $align-properties {
            .align-#{$media}-#{$justify-name}-#{$align-name} {
                @if ($media == 'all') {
                    justify-content: $justify-value;
                    align-items: $align-value;
                } @else {
                    @media #{$media-size} {
                        justify-content: $justify-value;
                        align-items: $align-value;
                    }
                }
            }
        }
    }
}

/* FILL: Defines flex container shrink and grow behaviour.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * grow options     - 0 and 1.
 * shrink options   - 0 and 1.
 * (e.g. .fill-gtMd-0-1)
*/
@each $media, $media-size in $material-media-sizes {
    @for $grow from 0 through 1 {
        @for $shrink from 0 through 1 {
            .fill-#{$media}-#{$grow}-#{$shrink} {
                @if ($grow == 'all') {
                    flex-grow: $grow;
                    flex-shrink: $shrink;
                } @else {
                    @media #{$media-size} {
                        flex-grow: $grow;
                        flex-shrink: $shrink;
                    }
                }
            }
        }
    }
}

/* HIDING: Hide container depends on media size.
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * (e.g. .hide-all or .hide-ltMd)
*/

@each $name, $media-size in $material-media-sizes {
    .hide-#{$name} {
        @if ($name == 'all') {
            display: none !important;
        } @else {
            @media #{$media-size} {
                display: none !important;
            }
        }
    }
}

/* SHOW: Show container depends on media size (defines { display: block; } property).
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * (e.g. .show-all or .show-ltMd)
*/

@each $name, $media-size in $material-media-sizes {
    .show-#{$name} {
        @if ($name == 'all') {
            display: block !important;
        } @else {
            @media #{$media-size} {
                display: block !important;
            }
        }
    }
}

/* SHOW_FLEX: Show container depends on media size (defines { display: flex; } property).
 * media-size options - all, xs, sm, md, lg, xl, ltSm, ltMd, ltLg, ltXl, gtXs, gtSm, gtMd, gtLg.
 * (e.g. .fx-show-all or .fx-show-ltMd)
*/

@each $name, $media-size in $material-media-sizes {
    .fx-show-#{$name} {
        @if ($name == 'all') {
            display: flex !important;
        } @else {
            @media #{$media-size} {
                display: flex !important;
            }
        }
    }
}
