.marketplace-search-field {
  height: 35px !important;
  border-radius: 8px 0 0 8px;
  .mat-form-field-wrapper {
    height: 36px !important;
    padding: 0 16px !important;
  }
  .mat-form-field-infix {
    padding: 0;
    border-top: 1.2em solid transparent !important;
  }
  .mat-form-field-label {
    &.mat-input-placeholder {
      top: 0.9em;
    }
  }
}
.top-raised-button-wrapper {
  display: block;
  position: absolute;
  top: -50px;
    &.integrate-wrapper {
        top: -68px;
        right: 0;
        @media #{$screen-lt-sm} {
            position: static;
        }
    }

    @media #{$screen-lt-lg} {
        top: -40px;
        &.integrate-wrapper {
            top: -60px;
        }
    }
  @media #{$screen-lt-md} {
    top: -55px;
      &.integrate-wrapper {
          top: -80px;
      }
  }
}

.plus-button-wrapper {
  display: block;
  position: fixed;
  top: 7.6rem;
  right: 2rem;
  z-index: 44;
}

.add-plus-button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $dark-green;
  text-align: center;
  font-size: 16px;
  span {
    vertical-align: middle;
    text-align: center;
    font-size: 17px;
    &:before {
      color: $white;
    }
  }
}
.search-container {
  padding: 15px;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
}
.enable-disable-icon {
  .icon-checked-2 {
    font-size: 16px;
    color: $dark-green;
  }
  .icon-close {
    font-size: 14px;
    color: $scared-red;
  }
}
.ink-blue-code {
  color: $inkBlue;
}
