app-ico-landing-layout,
app-ico-landing,
app-ico-whitelist {
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
}
.ico-main-container {
    margin-top: 70px;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
}
.whitelist-container {
    margin-top: 60px;
    max-height: 100%;
    height: 100%;
}
