/*---- Globals -----*/

html, body {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  top: 0 !important;
  overflow-y: visible !important;
  font-size: 16px;
  &.cdk-global-scrollblock {
    body {
      overflow-y: hidden;
    }
  }
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #fff;
  color: rgba(40,60,90,1);
  opacity: 1;
  font-size: .875rem;
  font-family: HelveticaRegular, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  /* Hopscotch tour popup layer index. */
  div {
    &.hopscotch-bubble {
      z-index: 49;
    }
  }
  input,
  textarea {
    &:required:valid,
    &:focus:required:invalid {
      color: #666;
    }
  }
}

* {
  box-sizing: border-box;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #666;
    opacity: 1;
  }
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

img {
  max-width: 100%;
  object-fit: cover;
}

main {
  position: relative;
}

div {
  box-sizing: border-box;
}

button {
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  color: #000;
  text-decoration: none;
  transition: color .25s ease;
  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0 0 16px;
}

h1, h2, h3, h4, h5 ,h6 {
  margin: 0 0 .5rem 0;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: .875rem;
}
::selection,
::-moz-selection {
  background-color: #ffc600;
}

/*---- Common -----*/

.bg-none {
  background: transparent !important;
}
.bg-white {
  background: #ffffff !important;
}
.green-link {
  color: #36987e;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 1rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
/* Angular Material popup's (dialogs) global scrollblock */
.cdk-global-scrollblock {
  position: static !important;
}

.cdk-overlay-container{
  height: 100em !important;
}

.cdk-global-overlay-wrapper {
  height: 100vh !important;
}

.mat-card,
.mat-dialog-container {
  display: inline-block;
  margin-left: 0;
  padding: 20px !important;
  margin-bottom: 0;
  color: black;
  overflow: hidden;
  border-radius: 0;
}

.mat-card {
  margin: .333rem;
  overflow: hidden;
  font-size: .875rem;
  &.p-0 {
    /* for merchant orders-statistics */
    .mat-card-title {
      .card-title-text {
        padding: 1rem 1.5rem;
      }
      .mat-divider {
        margin-right: 0;
        margin-left: 0;
        border-top-color: rgba(0, 0, 0, 0.04);
        @media #{$screen-lte-767px} {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .mat-card-content {
      padding:  0 1.5rem 1.5rem;
    }
  }
}

/*.mat-chip {
  margin-bottom: 8px !important;
  border-radius: 4px !important;
  &:not(.mat-basic-chip) {
    display: inline-flex;
    padding: 8px 12px 8px 12px;
    margin-bottom: 8px !important;
    border-radius: 4px !important;
    font-size: 13px;
    line-height: 16px;
  }
  .mat-chip-remove.mat-icon {
    font-size: 21px !important;
  }
}*/

.light-gray {
  background: rgba(0, 0, 0, .024);
}
.light-mat-gray {
  background: rgba(0, 0, 0, .08);
}
.mat-blue {
  background: #247ba0;
  color: #FEFEFE;
}
.mat-red {
  background-color: #f44336!important;
  color: #fff!important;
}
.mat-indigo {
  background-color: #3f51b5 !important;
  color: #fff!important;
}
.mat-brown {
  background-color: #785548 !important;
  color: #FEFEFE;
}
.mat-teal {
  background-color: #009688!important;
  color: #fff!important;
}
.mat-purple {
  background-color: #9c27b0!important;
  color: hsla(0,0%,100%,.87)!important;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.flex-dashboard {
  @media #{$screen-gt-sm} {
    display: flex;
  }
  @media #{$screen-lt-md} {
    display: block;
  }
}

.checkbox-small-label {
  .mat-checkbox-label {
    font-size: 1em;
  }
}

button {
  &.button-disable-styles {
    &:disabled,
    &[disabled] {
      background-color: #ffc600 !important;
      opacity: .4;
    }
  }
}
// table's common styles
.catalog-table {
  min-height: 615px;
}

.table-shadow-main {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.catalog-table-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  .hovered-th:hover {
    border-right: 1px solid rgba(0, 0, 0, .12);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
  .table-data-item {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    @media #{$screen-lte-767px} {
      line-height: 1.6rem;
    }
  }
  .table-data-small-item {
    color: rgba(0, 0, 0, 0.54);
    font-size: .7rem;
    font-weight: 900;
  }
}

.catalog-table-body {
  .table-row {
    &.display-block {
      display: block !important;
    }
  }
  .table-data-item {
    display: block;
    font-size: .85em;
    color: rgba(0, 0, 0, 0.54);
    @media #{$screen-lte-767px} {
      &.small-size-data-item {
        display: block;
        line-height: .7rem;
      }
    }
  }
}

.table-data-item {
  @media #{$screen-lte-767px} {
    font-size: .7rem !important;
  }
  @media #{$screen-lt-sm} {
    font-size: .5rem !important;
  }
}

.hovered-row:hover {
  background-color: #eee;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.table-row {
  min-height: 50px;
}

.deleted-user-background,
.deleted-user-background:hover {
  background-image: repeating-linear-gradient(-59deg, #ccc, #ccc 13px, #ddd 13px, #ddd 26px) !important;
}

.fitting-item {
  width: 100%;
}

.fitting-content {
  display: inline;
  word-wrap: break-word;
}

.full-width {
  width: 100% !important;
}

.break-word {
  word-break: break-all;
}

.space-between {
  justify-content: space-between;
  .bold-text {
    font-weight: bold;
  }
}

.icon {
  display: inline-block;
  vertical-align: top;
}

.user-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  &.admin-watch-mode {
    font-size: 22px;
    @media #{$screen-lt-sm} {
      font-size: 18px;
    }
  }
}

.font-bold-normal {
  font-size: 1em !important;
  font-weight: bold;
}

.font-normal {
  font-size: 1em !important;
  font-weight: normal !important;
}

.text-muted {
  color: rgba(0, 0, 0, .54) !important;
}

.capitalized {
  text-transform: capitalize;
}

.capitalized-status {
  .mat-select-value-text {
    text-transform: capitalize;
  }
}

.square-ul-type {
  list-style-type: square;
}

.pointed {
  cursor: pointer;
}

.word-break {
    word-break: break-all !important;
}

/* Dashboard Settings */
.dashboard-panel-heading {
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  margin-top: -2px;
  margin-bottom: 39px;
  h1 {
    font-size: 25px;
    font-family: "HelveticaBold", sans-serif;
    color: #000;
  }
}

.payment-information-text {
    color: #a5a5a5 !important;
    font-weight: normal !important;
    font-size: 16px !important;
}

.order-info-text {
	text-align: right;
	margin-bottom: 30px;
}
