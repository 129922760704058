.table-header{
    color: $light-blue;
    margin-bottom: 10px !important;
    font-size: 16px;
    @media #{$screen-lt-lg} {
        font-size: 12px;
    }
}
.table-body{
    width: 100%;
    .table-row {
        width: 100%;
        min-height: unset !important;
        background-color: #f7f7f7;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .no-result {
        background-color: #f7f7f7;
        border-radius: 5px;
        color: #cccccc;
        padding: 5px;
    }
    .uploader-progress{
        position: relative;
        b{
            display: block;
            z-index: 5;
            color: #ffffff;
            position: absolute;
            text-align: center;
            width: 100%;
        }
        mat-progress-bar {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            .mat-progress-bar-background {
                background-color: #37bced !important;
            }
        }
    }
    a{
        color: #37bced;
        text-decoration: underline;
    }
}

.uploaded-images-list-container{
    margin-top: 20px;
}

.image-upload-dialog-container{
    button:disabled {
        cursor: no-drop;
        opacity: 0.5;
    }
}

.uploader-panel {
    @media #{$screen-lt-xxs} {
        .dialog-actions-container {
            height: auto;
        }
        .uploader-action-container {
            margin-bottom: 10px;
        }
    }
}
