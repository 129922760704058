.reports-filters-tab-container {
  margin-bottom: 10px;
    .common-container {
        margin: 20px 0;
    }
}
.user-my-orders-inner-container {
    margin-top: -46px;
    @media #{$screen-lt-md} {
        margin-top: unset;
    }
}
.selection-tab-container {
  display: inline-block;
  position: relative;
  float: left;
  background-color: $white;
  cursor: pointer;
  &:hover,
  &.active-selection-tab {
    &:before,
    & + .selection-tab-container:before {
      width: 0;
    }
    background-color: $light-blue;
    .selection-tab {
      h6 {
        color: white !important;
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 24px;
    margin: 12px 0;
    background-color: $light-gray;
    @media #{$screen-lt-lg} {
      margin: 8px 0;
    }
  }
  &:first-of-type {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    &:before {
      content: none;
    }
  }
  &:last-of-type {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
}

.selection-tabs-container {
    app-selection-tabs > div,
    app-selection-tabs > div> .mat-form-field {
        width: 100%;
        max-width: 100%;
    }
}

.selection-tabs-container {
  &.filtering-tabs-container {
    .selection-tab-container {
      &:before {
        margin: 11px 0;
        @media #{$screen-lt-lg} {
          margin: 8px 0;
        }
      }
      &:hover {
        .selection-tab h6 {
            color: $white;
          }
      }
      .selection-tab {
        padding: 13px 20px;
        @media #{$screen-md} {
          padding: 14px 10px;
        }
        h6 {
          color: $light-gray;
          font-weight: 100 !important;
        }
      }
    }
  }
}

.mat-form-field {
  &.common-datepicker {
    width: 150px;
      @media #{$screen-lt-sm} {
          width: 212px !important;
          max-width: 100% !important; //new change 212px old value
      }
      @media #{$screen-lt-xxs} {
          width: 100% !important;
          max-width: 100% !important;
      }
      &.full-size-picker {
          &.search-field {
              width: 100% !important;
          }
          &.common-datepicker {
              width: 100% !important;
          }
      }
  }
}

.selection-tab {
  padding: 14px 30px;
  @media #{$screen-btw-1280px-1625px} {
    padding: 14px 18px;
  }
  @media #{$screen-lt-lg} {
    padding: 14px 8px;
  }
  h6 {
    padding: 0;
    margin-bottom: 0;
    color: $light-blue;
    font-size: 16px;
    line-height: 20px;
    @media #{$screen-lt-lg} {
      font-size: 11px;
      line-height: 12px;
    }
  }
}

.statistics-chart-main-container {
  position: relative;
  margin-top: 10px;
    padding: 30px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
    transition: all .3s ease-in-out;
    @media #{$screen-xs} {
        padding: 30px 10px;
    }
}
.statistics-selected-label-container {
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
  padding: 5px 15px;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
    @media #{$screen-sm} {
        left: 70px;
    }
  @media #{$screen-xs} {
    display: none;
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 27px;
    color: $light-blue;
    font-weight: bold;
    @media #{$screen-lt-lg} {
      font-size: 20px;
    }
    span{
      font-size: .75em;
    }
  }
}
.pie-chart-wrapper,
.bar-chart-wrapper {
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
}
.pie-chart-wrapper {
    @media #{$screen-lt-lg} {
        max-width: 600px;
    }
}
.statistics-chart-wrapper {
  padding-top: 40px;
  background-color: $main-back-gray;
  .ng-star-inserted {
    height: 400px !important;
    background-color: $main-back-gray;
  }
  canvas {
    height: 100% !important;
  }
}

.your-market-table {
  &.reports-sales-by-date-table {
    .table-header {
      padding-right: 10px;
      text-transform: capitalize;
    }
    .table-body {
      max-height: 240px;
      padding-right: 5px;
      overflow: hidden;
      &.full-height {
        max-height: fit-content;
        overflow-y: inherit;
      }
      &.affiliates{
        >div{
          >div{
            &:hover{
              cursor: pointer;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
    &.reports-table{
        .table-body {
            max-height: 100%;
        }
    }
}

.container-toggle-mat-data {
    margin: 20px 0;
}
