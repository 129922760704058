/* Common styles for all session pages */

.main-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 663px;
  overflow: hidden;
  background-color: #9ecff7;
}

.form-container {
  position: relative;
  padding-top: 147px;
  padding-bottom: 50px;
}

.formprofile {
  width: 30em;
  margin: 0 auto;
  padding: 1.9em 1.6em 3.7em;
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  @media #{$screen-lte-767px} {
    width: 20em;
    font-size: 14px;
  }
}

.extended-wrapper {
  margin: 2em auto 8em;
}

.maintenance-wrapper {
  margin-top: 11em;
}

.formprofile-img {
  width: 80%;
  margin: 0 auto;
}

.formprofile-title {
  margin-top: 0.2em;
  margin-bottom: 1.3em;
  font-size: 1.6em;
  text-align: center;
  text-transform: uppercase;
}

.formprofile-description {
  margin: 0 auto 2em;
  width: 90%;
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
}

.formprofile-form {
  margin-bottom: 2em;
}

// for Signup and Login pages

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=file],
input[type=number],
input[type=date],
input[type=week],
input[type=month],
input[type=search],
input[type=url],
input[type=checkbox],
input[type=radio],
input[type=image],
input[type=button],
input[type=reset],
input[type=submit],
.formprofile-input textarea {
  font-family: "HelveticaRegular", sans-serif;
  color: #464641;
}

input[type=checkbox],
input[type=radio],
input[type=image] {
  padding: 0;
}

re-captcha > div {
  margin: 0 auto 20px !important;
}

.formprofile-input input,
.formprofile-input textarea {
  font-size: 1.125em;
  background-color: transparent;
}
.formprofile-input {
  margin-bottom: 1em;
  text-align: left;
  input {
    &[type=text],
    &[type=password],
    &[type=email],
    &[type=tel],
    &[type=file],
    &[type=number],
    &[type=date],
    &[type=week],
    &[type=month],
    &[type=search],
    &[type=url] {
      display: inline-block;
      border: 1px solid #c9c9c9;
      width: 100%;
      padding: 0.67em 1em;
      text-align: left;
    }
    &[type=text]::-webkit-input-placeholder,
    &[type=password]::-webkit-input-placeholder,
    &[type=email]::-webkit-input-placeholder,
    &[type=tel]::-webkit-input-placeholder {
      transition-property: color;
      transition-duration: 240ms;
      transition-timing-function: cubic-bezier(0.51, 0.51, 0.23, 0.99);
    }
    &[type=text]:focus::-webkit-input-placeholder,
    &[type=password]:focus::-webkit-input-placeholder,
    &[type=email]:focus::-webkit-input-placeholder,
    &[type=tel]:focus::-webkit-input-placeholder {
      color: transparent;
    }
  }
  textarea {
    display: inline-block;
    border: 1px solid #c9c9c9;
    width: 100%;
    padding: 0.67em 1em;
    text-align: left;
    &::-webkit-input-placeholder {
      transition-property: color;
      transition-duration: 240ms;
      transition-timing-function: cubic-bezier(0.51, 0.51, 0.23, 0.99);
    }
    &.formprofile-textarea {
        &.mat-input-element {
            padding: 0.67em 1em;
        }
      &::-webkit-input-placeholder {
        color: #666;
      }
      &:focus::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
  .checkbox {
    position: relative;
  }
  .session-checkbox {
    display: inline-block;
    position: relative;
    width: 2.4em;
    height: 2.4em;
    background-color: #fff;
    border: 0.1em solid #37bced;
    border-radius: 5px;
    &::after {
      content: '';
      position: absolute;
      left: 1em;
      top: 1em;
      height: 0.7em;
      width: 1.4em;
      border-width: 0 0 0.3em 0.3em;
      border-style: solid;
      border-color: #35d8b9;
      opacity: 0;
      transition-property: transform, opacity;
      transition-duration: 240ms;
      transition-timing-function: cubic-bezier(0.51, 0.51, 0.23, 0.99);
      transform: translate(-50%, -72%) rotate(0deg) scale(2);
    }
    &.checked {
      border: 2px solid #9ec4fd;
      &::after {
        opacity: 1;
        transform: translate(-46%, -64%) rotate(-45deg) scale(1);
      }
    }
  }
  .checkbox-label {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 2.4em;
    margin-left: 1em;
    padding-top: .5em;
    // for Signup checkbox
    &.agreement-label {
      @media #{$screen-lte-767px} {
        top: -4px;
        padding-top: 0;
      }
    }
  }
}
re-captcha > div {
  margin: 0 auto 20px !important;
}

.form-error-msg{
    display: block;
    padding: 5px 0;
    color: #f44336;
}

.formprofile-button {
  border-radius: 6px;
  //background-color: #ffc600 !important;
  font-family: "HelveticaBold", sans-serif;
  text-transform: uppercase;
  text-align: center;
  transition: all 240ms cubic-bezier(0.51, 0.51, 0.23, 0.99);
  &:hover {
    color: #333;
    //background-color: #ffdd66 !important;
  }
  &.small-button {
    font-size: 1em;
    padding: .8em .8em;
    margin: 2em .4em .6em;
    @media #{$screen-lte-767px} {
      margin: .4em;
    }
  }
}

.formprofile-more {
  margin-bottom: 34px;
}

/* Signup page */

// password strenght
.strength-string {
  text-align: left;
  margin-bottom: 10px;
}

.strength-indicator-1 {
  .strength-1-active {
    background-color: #f44336;
  }
  span {
    &.strength-1-active {
      background-color: transparent;
      color: #f44336;
    }
  }
}
.strength-indicator-2 {
  .strength-1-active,
  .strength-2-active {
    background-color: #ffc600;
  }
  span {
    &.strength-2-active,
    &.strength-3-active {
      background-color: transparent;
      color: #ffc600;
    }
  }
}
.strength-indicator-3 {
  .strength-1-active,
  .strength-2-active,
  .strength-3-active {
    background-color: #ffc600;
    color: #ffc600;
  }
  span {
    &.strength-3-active {
      background-color: transparent;
      color: #ffc600;
    }
  }
}
.strength-indicator-4 {
  .strength-1-active,
  .strength-2-active,
  .strength-3-active,
  .strength-4-active {
    background-color: #36987e;
    color: #36987e;
  }
  span {
    &.strength-4-active {
      background-color: transparent;
      color: #36987e;
    }
  }
}
.strength-indicator-5 {
  .strength-1-active,
  .strength-2-active,
  .strength-3-active,
  .strength-4-active,
  .strength-5-active {
    background-color: #36987e;
    color: #36987e;
  }
  span {
    &.strength-5-active{
      background-color: transparent;
      color: #36987e;
    }
  }
}
.strength-default,
.strength-1,
.strength-2,
.strength-3,
.strength-4,
.strength-5 {
  height:3px;
  margin-right:2%;
  margin-bottom:15px;
  background-color: transparent;
}
.strength-default {
  background-color: #c9c9c9;
}

/* Signin page */

.login-notification {
  font-size: 15px;
  color: red;
}

/* Support page */
.message-catalog {
  .message-marking-btn {
    height: 40px;
    padding: 0;
    margin-top: 15px;
    @media #{$screen-lt-sm} {
      height: 34px;
      padding: 0;
    }
    span {
      @media #{$screen-lt-lg} {
        font-size: .9em;
      }
      @media #{$screen-lt-sm} {
        font-size: .8em;
      }
    }
  }
}
.payment-status-container {
  z-index: 1;
  }
.after-sending-container {
  max-width: 600px;
  min-height: 160px;
  margin: 0 auto;
  padding: 20px 20px;
  font-size: 18px;
  font-weight: normal;
  @media #{$screen-xs} {
    height: auto;
    font-size: 14px;
  }
  .back-button {
    a {
      display: block;
      width: 100px;
      height: 32px;
      padding: 3px 28px;
      border-radius: 4px;
      background-color: #ffc600;
      color: #000;
      white-space: nowrap;
      text-decoration: none;
      transition: all .4s ease;
      &:hover {
        background-color: #ffdd66;
        color: #fff;
      }
      @media #{$screen-xs} {
        padding: 5px 20px;
      }
    }
  }
}

/* After-signup page */

.after-signup-wrapper {
  position: relative;
  padding-top: 152px;
  padding-bottom: 50px;
  @media #{$screen-lte-383px} {
    padding-top: 182px;
  }
}

.after-signup-content-wrapper {
  margin-bottom: 21px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
}

.text-inner {
  padding: 5.6% 9%;
  @media #{$screen-lte-767px} {
    text-align: center;
  }
}

.text-breadcrumbs {
  line-height: 1.6;
  .text-breadcrumb-item {
    display: inline;
    font-size: 13px;
    &::after {
      display: inline;
      content: '/';
      margin: 0 1em;
      @media #{$screen-lte-767px} {
        margin: 0 .2em;
      }
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
  a {
    color: #36987e;
  }
  span {
    color: #6f7a83;
    @media #{$screen-lte-767px} {
      font-size: 12px;
    }
  }
}

.text-title {
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  color: #000;
  font-size: 40px;
  font-family: AileronSemiBold, sans-serif;
  @media #{$screen-lte-767px} {
    font-size: 26px;
  }
}

.text-description {
  color: #6f7a83;
  font-size: 20px;
  font-family: HelveticaMedium, sans-serif;
  @media #{$screen-lte-767px} {
    font-size: 16px;
  }
}

.greenbanner {
  text-align: center;
  /*background: #36987e url($after-signup-greenbanner);*/
  color: #FFF;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
}

.text-content {
  font-family: AileronRegular, sans-serif;
  color: #317d04;
  @media #{$screen-lte-767px} {
    font-size: 20px;
  }
}

.on-fb-wrapper {
  margin-top: 0;
  margin-bottom: 16px;
  .on-fb-header {
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-family: AileronBlack, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    color: rgb(0, 0, 0);
  }
  .on-fb-content {
    margin-left: 0;
    a {
      color: #3b579d;
      text-decoration: none;
      transition: color .25s ease;
      font-family: "AileronRegular", sans-serif;
      &:hover {
        color: #36987e;
      }
    }
  }
}

.fb-like-btn,
.fb-share-btn {
  margin: 0 4px;
}

/* Error and Not found pages */
.error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}
.error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.document{
  .product-uploaded-image-container{
    max-width: 100% !important;
  }
  .product-download-image-wrapper{
    place-content: stretch flex-end !important;
  }
}
