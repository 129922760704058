.affiliates{
  .green {
    color: $sallad-green;
  }
  .red{
    color: $scared-red;
  }
}

.affiliate-product{
  padding: 18px;
  .image{
    max-width: 300px;
    width: 250px;
    background-color: $white;
      &.affiliate {
          text-align: center;
          @media #{$screen-xs} {
              margin-bottom: 15px;
          }
          a {
              display: inline-block;
              text-align: center;
          }
          img{
              width: 80%;
          }
      }
    img{
      width: 100%;
      height: auto;
    }
  }
  .title{
    color: $light-blue;
    font-size: 19px;
    margin-bottom: 15px;
  }
  .mat-form-field-infix {
    padding: 0.1375em 0;
  }
}
.affiliate-product-container:not(:last-of-type) {
    .affiliate-product {
        border-bottom: 1px solid $medium-gray;
    }
}
app-partner-statistics {
    @media #{$screen-lt-sm} {
        .page-selection-tabs-container {
            mat-form-field {
                max-width: 100%;
            }
        }
        mat-form-field {
            &.search-field {
                width: 100% !important;
            }
            &.common-datepicker {
                width: 100% !important;
            }
        }
    }
}
