.completion-content {
    padding: 34px 15px 15px;
    @media #{$screen-lt-md} {
        padding: 15px;
    }
    .list {
        .icon-checked-2 {
            color: $sallad-green !important;
            margin-right: 10px;
        }
        .icon-plus-2 {
            color: $light-blue !important;
            margin-right: 10px;
        }
    }
}

.price-button-wrapper .price-button {
    display: inline-block;
    padding: 16px 24px;
    color: $white;
    background-color: $medium-blue;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1em;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.user-available-money-header-wrapper,
.user-recent-activity-header-wrapper {
    height: 50px;
    @media #{$screen-lt-lg} {
        height: 40px;
    }
    @media #{$screen-lt-md} {
        h4 {
            margin-bottom: 10px;
        }
        height: auto;
    }
}
.gross-sales-header-wrapper {
    height: 50px;
    @media #{$screen-lt-md} {
        h4 {
            margin-bottom: 10px;
        }
        height: auto;
    }
    &.box-sales {
        justify-content: flex-end;
    }
}
.completion-step-title {
    @media #{$screen-md} {
        font-size: 12px;
        font-weight: bold;
    }
    @media #{$screen-xs} {
        font-size: 12px;
        font-weight: bold;
    }
    a {
        &:hover {
            color: $light-blue;
        }
    }
}
.video-container {
    &.dashboard-video-container {
        @media #{$screen-sm} {
            min-height: 250px;
        }
        @media #{$screen-lt-sm} {
            min-height: 260px;
        }
    }
}
.video-tutorials {
    &.dashboard-video {
        @media #{$screen-sm} {
            flex: 1 1 46% !important;
            max-width: 46% !important;
            margin: 0 auto;
        }
        @media #{$screen-lt-sm} {
            flex: 1 1 96% !important;
            max-width: 96% !important;
        }
    }
}
.dashboard-video {
    padding: 15px;
}
.dashboard-video-tutorial-wrapper {
    width: 100%;
    height: auto;
    /*@media #{$screen-sm} {
        height: 250px;
    }*/
    /*@media #{$screen-lt-sm} {
        height: 200px;
    }*/
}
.title {
    font-size: 18px;
}
.dashboard-bottom-container {
    .inner-container {
        margin: 70px 0;
    }

    .dashboard-table {
        .table-header {
            text-transform: capitalize;
            font-weight: bold;
        }
        .table-body {
            text-align: left!important;
        }
    }

    .button-box {
        padding-right: 0;
        align-self: flex-end;
        .button-dark-green {
            background-color: $light-blue !important;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
        }
    }

}

.box-data-mat-form {
    .mat-form-field-infix {
        padding: 0.2em 0 !important;
    }
}
