/* Wallets view images */
$wallet-arrow-down: '../../src/img/dashboard/arrow.png';
$wallet-arrow-up: '../../src/img/dashboard/arrow_up.png';
$transactions-toggle-down: '../../src/img/dashboard/rectangle_down.png';
$transactions-toggle-up: '../../src/img/dashboard/rectangle_up.png';
$transactions-sort-down: '../../src/img/dashboard/sort_down.png';
$transactions-sort-down-green: '../../src/img/dashboard/sort_down-green.png';
$transactions-sort-up: '../../src/img/dashboard/sort_up.png';
$transactions-sort-up-green: '../../src/img/dashboard/sort_up-green.png';
$bitcoin-logo: '../../src/img/dashboard/bitcoin-logo.png';
$primary-wallet-icon-selected: '../../src/img/dashboard/icon_favourite.png';
$primary-wallet-icon: '../../src/img/dashboard/icon_favourite_outline.png';
$wallet-delete-icon: '../../src/img/dashboard/icon_delete.png';
$wallet-rescan-icon: '../../src/img/dashboard/icon_rescan.png';
$wallet-receive-btn-icon: '../../src/img/dashboard/icon_receive.png';
$wallet-send-btn-icon: '../../src/img/dashboard/icon_send.png';
$transactions-sort-icon: '../../src/img/dashboard/sort.png';
$transactions-info-icon: '../../src/img/dashboard/icon_info.png';

/* Dialog icons */
$dialog-info-button: '../../src/img/icon_info.png';
$dialog-close-button: '../../src/img/btn_close.png';

/* Navbar icons and images */
$navbar-mobile-trigger: '../../src/img/dashboard/arrow_white.png';
$navbar-mobile-trigger-active: '../../src/img/dashboard/arrow_up_white.png';
$navbar-wallets-icon: '../../src/img/dashboard/icon_wallets.png';
$navbar-add-wallet-icon: '../../src/img/dashboard/icon_add_wallet.png';
$navbar-restore-icon: '../../src/img/dashboard/icon_restore.png';
$navbar-settings-icon: '../../src/img/dashboard/icon_settings.png';
$navbar-help-icon: '../../src/img/dashboard/icon_help.png';

/* Header menu images */
$header-menu-yellow-user: '../../src/img/user_yellow.png';
$header-menu-logout: '../../src/img/log_out.png';
$header-menu-signin-user: '../../src/img/svg/user-profile.svg';
$header-menu-sign-up: '../../src/img/sign_up.png';

/* Payment images */
$payment-logo: '../../src/img/payment/logo-skypayment.png';
$payment-back-icon: '../../src/img/payment/arrow-back.png';
$payment-arrow-down: '../../src/img/payment/arrow-down.png';
$payment-worning-icon: '../../src/img/payment/icon-warning.png';
$payment-update-icon: '../../src/img/payment/icon-update.png';
$payment-BTC-icon: '../../src/img/payment/img_bitcoin.png';
$payment-X12-icon: '../../src/img/payment/img_x12.png';
$payment-ETH-icon: '../../src/img/payment/img_ETH.png';

/* Session images */
$after-signup-greenbanner: '../../src/img/bgmanymanyicons.png';

/* Dashboard images and icons */
$edit-icon: '../../src/img/dashboard/icon_edit.png';

/* Hopscotch-tour images */
$hopscotch-bubble-close: '../../src/img/cancel.png';
