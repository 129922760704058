.mat-checkbox-frame {
  border-color: #e1e1e1 !important;
  background-color: #e1e1e1 !important;
}

.market-main-theme {
    .mat-checkbox-indeterminate,
    .mat-checkbox-checked {
        &.mat-accent {
            .mat-checkbox-background {
                background-color: $dark-green!important;
            }
        }
    }
}

mat-calendar-header {
    .mat-calendar-controls {
        .mat-button-wrapper,
        .mat-calendar-previous-button,
        .mat-calendar-next-button {
            color: rgba(0, 0, 0, 0.54) !important;
        }
    }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: $light-blue;
}

.egret-indigo .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #1f9cc6 !important;
}

.cdk-overlay-container .mat-datepicker-popup{
    position: fixed;
}


.egret-indigo .mat-calendar-body-selected {
    background-color: $light-blue !important;
}
