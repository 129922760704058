app-video-link-input {
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
}

.videos-form-field-container {
    display: flex;
    flex: 1 1 400px;
    max-width: 400px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    mat-form-field {
        flex: 1 1 100%;
        height: auto;
        max-width: 100%;
        min-height: 46px;
        max-height: 290px;
        overflow-y: auto;
    }
}
.video-thumbnail-mat-chip-wrapper {
    width: 100% !important;
    input {
        width: 100%;
    }
}
.video-thumbnail-chip,
.message-thumbnail-chip {
    position: relative !important;
    span.mat-chip-close-icon.mat-chip-remove {
        position: absolute !important;
        transform: rotate(0deg);
        transition: all .2s;
        &:hover {
            opacity: 1 !important;
            transform: rotate(180deg);
        }
    }
}
.video-thumbnail-chip {
    padding: 4px 28px 4px 4px !important;
    transition: all .2s;
    img {
        width: 100px;
        height: auto;
    }
    span.mat-chip-close-icon.mat-chip-remove {
        right: 4px !important;
        top: 3px !important;
    }
}
.message-thumbnail-chip {
    padding-right: 28px !important;
    span.mat-chip-close-icon.mat-chip-remove {
        right: 8px !important;
        top: 10px !important;
    }
}
.send-videos-button-wrapper {
    display: flex;
    flex: 1 1 100%;
    padding-top: 20px;
    justify-content: flex-end;
    align-items: flex-start;
}
